import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { useNavigate, useOutlet, useParams } from "react-router-dom"
import { z } from "zod"
import { gql } from "~/__generated__"
import {
  ProductBriefPricingStructure,
  ProductBriefSalesType,
} from "~/__generated__/graphql"
import { SectionLabel } from "~/campaigns/section"
import * as paths from "~/common/paths"
import { useGoBack } from "~/common/use-go-back"
import { useSafeMutation } from "~/common/use-safe-mutation"
import { useValidationErrors } from "~/common/use-validation-errors"
import arrowLeft from "~/images/icons/arrow-left"
import { TablePageLayout } from "~/layouts/table-page-layout"
import { Button } from "~/ui/button"
import Text from "~/ui/typography"
import { useToast } from "~/ui/use-toast"
import { ProductForm } from "./product-form"

const mutation = gql(/* GraphQL */ `
  mutation createProductBrief($input: ProductBriefCreateInput!) {
    productBriefCreate(input: $input) {
      productBrief {
        id
        name
      }
    }
  }
`)

export const ProductNewScreen = () => {
  const params = useParams()
  const goBack = useGoBack(
    paths.companyProductsPath({ slug: params.slug ?? "" })
  )
  const outlet = useOutlet()
  const { toast } = useToast()
  const navigate = useNavigate()

  const formSchema = z.object({
    companyId: z.string(),
    name: z.string(),
    mainProblem: z.string(),
    preferredMessaging: z.string(),
    topCompetitors: z.array(z.string()),
    pricingStructure: z.nativeEnum(ProductBriefPricingStructure),
    avgContractValue: z.number().optional(),
    pricePerSeat: z.number().optional(),
    salesType: z.nativeEnum(ProductBriefSalesType),
    salesCycle: z.number().optional(),
    mqlToWinRate: z.number().optional(),
    categories: z.array(z.string()),
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      companyId: "",
      name: "",
      mainProblem: "",
      preferredMessaging: "",
      topCompetitors: ["", "", ""],
      pricingStructure: ProductBriefPricingStructure.BySeat,
      avgContractValue: undefined,
      pricePerSeat: undefined,
      salesType: ProductBriefSalesType.SalesTeam,
      salesCycle: undefined,
      mqlToWinRate: undefined,
      categories: [],
    },
  })

  const [exec, result] = useSafeMutation(mutation)
  useValidationErrors(form.setError, result)

  const pricingStructure = form.watch().pricingStructure
  const pricingFlatStructureOrByUsage =
    pricingStructure === ProductBriefPricingStructure.FlatPricing ||
    pricingStructure === ProductBriefPricingStructure.ByUsage
  const pricingBySeat = pricingStructure === ProductBriefPricingStructure.BySeat

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    let topCompetitors = values.topCompetitors.filter(
      (topCompetitor) => topCompetitor.trim() !== ""
    )

    const result = await exec({
      variables: {
        input: {
          productBriefInput: {
            companyId: values.companyId,
            name: values.name,
            mainProblem: values.mainProblem,
            preferredMessaging: values.preferredMessaging,
            topCompetitors: topCompetitors,
            pricingStructure: values.pricingStructure,
            salesType: values.salesType,
            mqlToWinRate: values.mqlToWinRate,
            categories: values.categories,
            salesCycle: values.salesCycle,
            ...(pricingFlatStructureOrByUsage && {
              avgContractValue: values.avgContractValue,
            }),
            ...(pricingBySeat && {
              pricePerSeat: values.pricePerSeat,
            }),
          },
        },
      },
    })

    if (result.errors) {
      toast({
        title: "Error",
        description: "There was an error saving the product brief",
      })
    } else {
      toast({
        title: "Product Brief created",
        description: "The product brief has been created",
      })
      navigate(paths.companyProductsPath({ slug: params.slug ?? "" }))
    }
  }

  return (
    <>
      <div className="px-[40px] border-b">
        <Button onClick={goBack} variant="ghost" className="-ms-4">
          <img {...arrowLeft} alt="" className="inline-block me-2" />
          <Text variant="mini-caps">Back</Text>
        </Button>
      </div>
      <div>
        <TablePageLayout padding={false} rightSideSlot={outlet}>
          <div className="flex flex-1 flex-col">
            <div className="grid grid-cols-[minmax(475px,1fr)_475px] p-[40px] gap-[40px] items-start overflow-auto">
              <div className="space-y-8">
                <SectionLabel>Create Product Brief</SectionLabel>

                <ProductForm form={form} onSubmit={onSubmit} />
              </div>
            </div>
          </div>
        </TablePageLayout>
      </div>
    </>
  )
}
