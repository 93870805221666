import { useCallback, useEffect } from "react"
import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/use-safe-mutation"
import { displayErrors } from "~/common/validations"

export const useMarkMessagesRead = ({
  hasUnreads,
  roomId,
}: {
  hasUnreads: boolean
  roomId: string
}) => {
  const [runMarkRead] = useSafeMutation(MARK_READ_MUTATION)

  const markRead = useCallback(async () => {
    const { errors } = await runMarkRead({
      variables: {
        input: {
          roomId,
        },
      },
    })

    if (errors) {
      displayErrors(errors)
    }
  }, [runMarkRead, roomId])

  useEffect(() => {
    const interval = setInterval(() => {
      if (document.hasFocus() && hasUnreads) {
        markRead()
      }
    }, 200)

    return () => clearInterval(interval)
  }, [hasUnreads, markRead])
}

const MARK_READ_MUTATION = gql(`
  mutation RoomMarkRead($input: RoomMarkReadInput!) {
    roomMarkRead(input: $input) {
      room {
        id
        hasUnreadPosts
      }
      currentUser {
        id
        unreadMessageCount
      }
    }
  }
`)
