import { Button } from "~/ui/button"
import Text from "~/ui/typography"
import arrowLeft from "~/images/icons/arrow-left"

export const GoBack = ({ onClick }: { onClick: () => void }) => {
  return (
    <>
      <div className="px-[40px] border-b">
        <Button onClick={onClick} variant="ghost" className="-ms-4">
          <img {...arrowLeft} alt="" className="inline-block me-2" />
          <Text variant="mini-caps">Back</Text>
        </Button>
      </div>
    </>
  )
}
