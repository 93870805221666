import {
  ApolloCache,
  NormalizedCacheObject,
  Reference,
  StoreObject,
  useMutation,
} from "@apollo/client"
import { gql } from "~/__generated__"
import { CompanyConnection } from "~/__generated__/graphql"
import { displayErrors } from "~/common/validations"
import { Button } from "~/ui/button"
import { Dialog, DialogContent } from "~/ui/dialog"
import { useToast } from "~/ui/use-toast"
import { UserAvatar } from "./user-avatar"

export const RemoveCompanyUserModal = ({
  isOpen,
  onClose,
  user,
  company,
}: {
  isOpen: boolean
  onClose: () => void
  user: any
  company: any
}) => {
  const [runMutation] = useMutation(REMOVE_USER_MUTATION, {
    update: (cache: ApolloCache<NormalizedCacheObject>, { data }) => {
      if (!data) return
      const removedUser = data.companyUserRemove.user
      const removedCompanyId = company.id

      cache.modify({
        id: "ROOT_QUERY",
        fields: {
          users: (existingUsers, { readField }) => {
            const updatedEdges = existingUsers.edges.filter(
              (edge: { node: Reference | StoreObject | undefined }) => {
                const userId = readField("id", edge.node)
                const userCompanies: Readonly<CompanyConnection> | undefined =
                  readField("companies", edge.node)

                return (
                  userId !== removedUser.id ||
                  userCompanies?.edges?.some(
                    (companyEdge) =>
                      readField("id", companyEdge.node) !== removedCompanyId
                  )
                )
              }
            )

            return {
              ...existingUsers,
              edges: updatedEdges,
            }
          },
        },
      })
    },
  })
  const { toast } = useToast()

  const removeUser = async () => {
    const { errors } = await runMutation({
      variables: { input: { id: user.id, companyId: company.id } },
    })

    if (errors) {
      displayErrors(errors)
    } else {
      toast({ title: "User has been removed from the company" })
      onClose()
    }
  }

  if (!user) return null

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(value) => {
        if (!value) onClose()
      }}
    >
      <DialogContent className="w-2/3 max-w-xl gap-0 text-center">
        <div className="text-2xl mb-4">Remove User</div>
        <div className="mb-6 text-sm">
          Are you sure you want to remove this user from the company?
        </div>

        <div className="border border-gray-200 rounded-lg bg-gray-50 p-4 flex items-center justify-between">
          <div className="flex items-center gap-4">
            <UserAvatar user={user} size="lg" />
            <div className="flex flex-col text-left">
              <div className="text-sm font-medium">
                {user.firstName} {user.lastName}
              </div>
              <div className="text-xs-plus text-gray-500">{user.email}</div>
            </div>
          </div>
          <div>
            <Button type="button" className="px-6" onClick={removeUser}>
              Remove
            </Button>
          </div>
        </div>

        <div className="flex mt-4">
          <Button
            type="button"
            onClick={() => onClose()}
            variant="linkMuted"
            size="xs"
          >
            Cancel
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

const REMOVE_USER_MUTATION = gql(`
  mutation RemoveUser($input: CompanyUserRemoveInput!) {
    companyUserRemove(input: $input) {
      user {
        id
        ...UsersTableRow
      }
    }
  }
`)
