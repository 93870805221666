import { gql } from "~/__generated__"
import { UserAvatar } from "./user-avatar"
import { UserCardFragment } from "~/__generated__/graphql"
import { Link } from "react-router-dom"
import { usersDetailPath } from "~/common/paths"
import Text from "~/ui/typography"
import { UserRole } from "./user-role"

gql(/* GraphQL */ `
  fragment UserCard on User {
    id
    firstName
    lastName
    role
    ...UserAvatar
  }
`)

export const UserCard: React.FC<{
  user: UserCardFragment
  linkTo?: string
}> = ({ user, linkTo }) => {
  return (
    <Link
      to={linkTo ?? usersDetailPath({ userId: user.id })}
      className="hover:bg-gray-f9 rounded px-4 py-2 grid grid-cols-[auto,1fr] gap-4 items-center"
    >
      <UserAvatar user={user} />
      <div>
        <Text as="div" variant="body-12-medium">
          {user.firstName} {user.lastName}
        </Text>
        <Text as="div" variant="subtext-10" className="text-gray-99">
          <UserRole role={user.role} />
        </Text>
      </div>
    </Link>
  )
}
