import { VariantProps, cva } from "class-variance-authority"
import * as React from "react"
import invariant from "tiny-invariant"

import { cn } from "~/common/cn"

const tableVariants = cva("w-full caption-bottom text-sm border-separate ", {
  variants: {
    variant: {
      separate: "border-spacing-0 border-spacing-y-2",
      rounded: "table-rounded",
      roundedTopBottom: "table-rounded-top-bottom",
    },
    layout: {
      auto: "table-auto",
      fixed: "table-fixed",
    },
  },
  defaultVariants: {
    variant: "separate",
    layout: "auto",
  },
})

const Table = React.forwardRef<
  HTMLTableElement,
  React.HTMLAttributes<HTMLTableElement> & VariantProps<typeof tableVariants>
>(({ className, variant, layout, ...props }, ref) => (
  <div className="relative w-full overflow-auto">
    <table
      ref={ref}
      className={cn(tableVariants({ variant, layout, className }))}
      {...props}
    />
  </div>
))
Table.displayName = "Table"

const TableHeader = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <thead
    ref={ref}
    className={cn("[&_tr]:border-b", "text-gray-99", className)}
    {...props}
  />
))
TableHeader.displayName = "TableHeader"

const TableBody = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tbody
    ref={ref}
    className={cn("[&_tr:last-child]:border-0", className)}
    {...props}
  />
))
TableBody.displayName = "TableBody"

const TableFooter = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tfoot
    ref={ref}
    className={cn(
      "border-t bg-muted/50 font-medium [&>tr]:last:border-b-0",
      className
    )}
    {...props}
  />
))
TableFooter.displayName = "TableFooter"

const TableRow = React.forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement>
>(({ className, onClick, ...props }, ref) => {
  const clickableProps = onClick
    ? {
        role: "button",
        tabIndex: 0,
        onKeyDown: (ev: any) => {
          if (ev.key === " ") {
            ev.preventDefault()
          }
        },
        onKeyUp: (ev: any) => {
          invariant(onClick)

          if (
            !(
              ev.target instanceof HTMLTableRowElement ||
              ev.target instanceof HTMLTableCellElement
            )
          ) {
            return
          }

          if (ev.key === "Enter" || ev.key === " ") {
            onClick(ev)
          }
        },
      }
    : {}

  return (
    <tr
      ref={ref}
      className={cn(
        "border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted pb-2",
        "text-sm",
        className
      )}
      {...clickableProps}
      {...props}
      onClick={(ev) => {
        if (onClick == null) return

        // only handles events on the row itself, not nested links, buttons, etc
        if (
          ev.target instanceof HTMLTableRowElement ||
          ev.target instanceof HTMLTableCellElement
        ) {
          onClick(ev)
        }
      }}
    />
  )
})
TableRow.displayName = "TableRow"

const tableHeadVariants = cva(
  "h-6 text-left align-middle font-medium  [&:has([role=checkbox])]:pr-0  font-medium text-left text-xs-plus truncate overflow-hidden",
  {
    variants: {
      variant: {
        default: "text-muted-foreground first:ps-6",
        collapse: "pb-4 uppercase text-black",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
)

const TableHead = React.forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement> &
    VariantProps<typeof tableHeadVariants>
>(({ className, variant, ...props }, ref) => (
  <th
    ref={ref}
    className={tableHeadVariants({ variant, className })}
    {...props}
  />
))
TableHead.displayName = "TableHead"

const tableCellVariants = cva(
  "align-middle [&:has([role=checkbox])]:pr-0 tracking-tight group-hover:bg-gray-50 first:border-l first:pl-6 last-of-type:border-r pe-8",
  {
    variants: {
      variant: {
        default:
          "border-y first:rounded-l-lg last-of-type:rounded-r-lg mb-4 truncate",
        collapse: "bg-white first:pl-4 truncate overflow-hidden",
        /** note: the table needs border-spacing-y-0 set on this to work right */
        top: "border-t first:rounded-tl-lg last-of-type:rounded-tr-lg bg-gray-f9 truncate",
        /** note: the table needs border-spacing-y-0 set on this to work right */
        bottom:
          "border-b first:rounded-bl-lg last-of-type:rounded-br-lg bg-gray-f9",
      },
      spacing: {
        "2": "py-2",
        "4": "py-4",
      },
    },
    defaultVariants: {
      variant: "default",
      spacing: "2",
    },
  }
)

const TableCell = React.forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement> &
    VariantProps<typeof tableCellVariants>
>(({ className, variant, spacing, ...props }, ref) => (
  <td
    ref={ref}
    className={cn(tableCellVariants({ variant, className, spacing }))}
    {...props}
  />
))
TableCell.displayName = "TableCell"

const TableCaption = React.forwardRef<
  HTMLTableCaptionElement,
  React.HTMLAttributes<HTMLTableCaptionElement>
>(({ className, ...props }, ref) => (
  <caption
    ref={ref}
    className={cn("mt-4 text-sm text-muted-foreground", className)}
    {...props}
  />
))
TableCaption.displayName = "TableCaption"

export {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
}
