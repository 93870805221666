import * as React from "react"

import { cn } from "~/common/cn"

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  postfix?: React.ReactNode
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, postfix, ...props }, ref) => {
    return (
      <>
        <input
          type={type}
          className={cn(
            "flex h-10 w-full rounded-md border border-input bg-background disabled:bg-gray-f9 px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
            className
          )}
          ref={ref}
          {...props}
        />
        {postfix && (
          <div className="flex items-center justify-center w-10 h-full absolute right-0 -top-[1px] text-placeholder">
            {postfix}
          </div>
        )}
      </>
    )
  }
)
Input.displayName = "Input"

export { Input }
