import { gql } from "~/__generated__"
import { CampaignDeliverablePublishDateFragment } from "~/__generated__/graphql"
import { formatDate } from "~/common/date-formatting"

gql(/* GraphQL */ `
  fragment CampaignDeliverablePublishDate on CampaignDeliverable {
    publishDateCanonical
  }
`)

export const CampaignDeliverablePublishDate: React.FC<
  CampaignDeliverablePublishDateFragment
> = (props) => {
  return props.publishDateCanonical != null ? (
    formatDate(props.publishDateCanonical)
  ) : (
    <span className="text-danger">Date Missing</span>
  )
}
