import { useQuery } from "@apollo/client"
import { Link } from "react-router-dom"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import { CampaignsTableRowFragment } from "~/__generated__/graphql"
import { campaignDeliverablePath } from "~/common/paths"
import { GraphqlError } from "~/ui/errors"
import { LoadingIndicatorCentered } from "~/ui/loading-indicator"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/ui/table"
import { CampaignDeliverablePublishDate } from "./campaign-deliverable-publish-date"
import { DeliverableStatus } from "./deliverable-status"
import { DeliverableType } from "./deliverable-type"

const campaignDeliverablesQuery = gql(/* GraphQL */ `
  query CampaignDeliverables($campaignId: ID!) {
    node(id: $campaignId) {
      ... on Campaign {
        id
        campaignDeliverables(first: 100) {
          edges {
            node {
              id
              deliverableName
              deliverableType
              placement
              status
              publishDateCanonical
              updatedAt
            }
          }
        }
      }
    }
  }
`)

export const CampaignDeliverables = ({
  campaign,
}: {
  campaign: CampaignsTableRowFragment
}) => {
  const result = useQuery(campaignDeliverablesQuery, {
    variables: { campaignId: campaign.id },
  })

  if (result.loading) {
    return <LoadingIndicatorCentered />
  }
  if (result.error) {
    return <GraphqlError error={result.error} />
  }

  invariant(result.data)
  invariant(result.data.node)
  invariant(result.data.node.__typename === "Campaign")

  const campaignDeliverables = result.data.node.campaignDeliverables.edges.map(
    (e) => e.node
  )

  return (
    <div>
      {campaignDeliverables.length === 0 && <div>No deliverables</div>}
      {campaignDeliverables.length > 0 && (
        <Table layout="fixed" variant="rounded" className="table-rounded">
          <TableHeader>
            <tr>
              <TableHead variant="collapse" className="w-[300px] pe-2">
                Campaign Deliverables
              </TableHead>
              <TableHead variant="collapse">Deliverable Type</TableHead>
              <TableHead variant="collapse">Placement</TableHead>
              <TableHead variant="collapse" className="text-right pe-8">
                Status
              </TableHead>
              <TableHead variant="collapse" className="text-right pe-4">
                Publish Date
              </TableHead>
            </tr>
          </TableHeader>
          <TableBody>
            {campaignDeliverables.map((deliverable) => (
              <TableRow key={deliverable.id}>
                <TableCell spacing="4" variant="collapse">
                  <Link
                    to={campaignDeliverablePath({
                      campaignId: campaign.id,
                      deliverableId: deliverable.id,
                    })}
                  >
                    {deliverable.deliverableName}
                  </Link>
                </TableCell>
                <TableCell spacing="4" variant="collapse">
                  <DeliverableType {...deliverable} />
                </TableCell>
                <TableCell spacing="4" variant="collapse">
                  {deliverable.placement}
                </TableCell>
                <TableCell className="pe-2" spacing="4" variant="collapse">
                  <DeliverableStatus
                    {...deliverable}
                    status={deliverable.status}
                  />
                </TableCell>
                <TableCell
                  spacing="4"
                  variant="collapse"
                  className="text-right pe-4 w-[240px]"
                >
                  <CampaignDeliverablePublishDate {...deliverable} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  )
}
