import { UseFormReturn } from "react-hook-form"
import { z } from "zod"
import { metricsFormSchema } from "~/campaigns/campaign-metrics-screen"
import { CampaignTotals } from "../campaign-totals"

export const CampaignSocialTotals: React.FC<{
  form: UseFormReturn<z.infer<typeof metricsFormSchema>>
}> = ({ form }) => {
  const { watch } = form
  const campaignDeliverables = watch("deliverables")
  const nonPendingDeliverables = campaignDeliverables.filter(
    (deliverable) => !deliverable.metricsPending
  )
  const totalImpressions = nonPendingDeliverables.reduce(
    (sum, deliverable) => sum + parseInt(deliverable.impressionsMetric || "0"),
    0
  )
  const totalClicks = nonPendingDeliverables.reduce(
    (sum, deliverable) => sum + parseInt(deliverable.totalClicksMetric || "0"),
    0
  )

  return (
    <CampaignTotals
      totals={[
        {
          value: totalImpressions,
          label: "Total Impressions",
        },
        {
          value: totalClicks,
          label: "Engagements",
        },
      ]}
    />
  )
}
