import { UseFormReturn } from "react-hook-form"
import { z } from "zod"
import { formSchema } from "~/campaigns/campaign-deliverable-edit-metrics-pane"
import { TextField } from "~/fields/text-field"

export const SocialSidebarEditMetrics: React.FC<{
  form: UseFormReturn<z.infer<typeof formSchema>>
}> = ({ form }) => {
  return (
    <>
      <TextField
        type="number"
        control={form.control}
        name="impressionsMetric"
        label="Impressions"
      />
      <TextField
        type="number"
        control={form.control}
        name="totalClicksMetric"
        label="Engagements"
      />
    </>
  )
}
