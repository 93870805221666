import { gql } from "~/__generated__"
import { CampaignDeliverableProductFragment } from "~/__generated__/graphql"
import { companyProductsDetailPath } from "~/common/paths"
import { Anchor } from "~/ui/link"

gql(/* GraphQL */ `
  fragment CampaignDeliverableProduct on CampaignDeliverable {
    productBrief {
      id
      name
      company {
        slug
      }
    }
  }
`)

export const CampaignDeliverableProduct: React.FC<
  CampaignDeliverableProductFragment
> = (props) => {
  return (
    <>
      {props.productBrief ? (
        <Anchor
          href={companyProductsDetailPath({
            slug: props.productBrief.company.slug,
            productId: props.productBrief.id,
          })}
          target="_blank"
          onClick={(e) => {
            e.stopPropagation()
          }}
          className="no-underline"
        >
          {props.productBrief?.name}
        </Anchor>
      ) : (
        <i className="text-foreground/20">Unassigned</i>
      )}
    </>
  )
}
