import { gql } from "~/__generated__"
import { messagesPath } from "~/common/paths"
import { useSafeMutation } from "~/common/use-safe-mutation"
import { displayErrors } from "~/common/validations"
import { Button } from "~/ui/button"
import { Dialog, DialogContent } from "~/ui/dialog"

export const LeaveRoomModal = ({
  isOpen,
  setIsOpen,
  roomId,
}: {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  roomId: string
}) => {
  const [runMutation, mutationResult] = useSafeMutation(ROOM_LEAVE_MUTATION)

  const leaveRoom = async () => {
    const { errors } = await runMutation({
      variables: { input: { roomId } },
    })

    if (errors) {
      displayErrors(errors)
    } else {
      setIsOpen(false)
      window.location.href = messagesPath.pattern
    }
  }

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(value) => {
        setIsOpen(value)
      }}
    >
      <DialogContent className="w-2/3 max-w-xl gap-0">
        <div className="mb-2 text-3xl">Leave Chat</div>
        <div className="mb-6 text-sm">Would you like to leave this chat?</div>

        <div className="flex justify-end">
          <Button
            type="button"
            className="px-10 mr-4"
            onClick={() => setIsOpen(false)}
            variant="outline"
          >
            Cancel
          </Button>
          <Button
            type="button"
            className="px-10"
            disabled={mutationResult.loading}
            isLoading={mutationResult.loading}
            onClick={leaveRoom}
          >
            Leave
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

const ROOM_LEAVE_MUTATION = gql(`
  mutation RoomLeave($input: RoomLeaveInput!) {
    roomLeave(input: $input) {
      success
    }
  }
`)
