import { useMutation } from "@apollo/client"
import { Blob, DirectUpload } from "@rails/activestorage"
import { useCallback, useMemo, useState } from "react"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import { useToast } from "~/ui/use-toast"

export const useDirectUpload = () => {
  const directUploadsPath = document
    .querySelector("meta[name='direct-uploads-path']")
    ?.getAttribute("content")

  if (!directUploadsPath) {
    throw new Error("Missing meta tag: direct-uploads-path")
  }

  const upload = useCallback(
    (file: File) => {
      const upload = new DirectUpload(file, directUploadsPath)

      return new Promise<Blob>((resolve, reject) => {
        upload.create((error, blob) => {
          if (error) {
            reject(error)
          } else {
            resolve(blob)
          }
        })
      })
    },
    [directUploadsPath]
  )

  return useMemo(() => {
    return {
      upload,
    }
  }, [upload])
}

export const uploadedImageFragment = gql(/* GraphQL */ `
  fragment UploadedImage on Image {
    id
    originalUrl
    imgixUrl
    width
    height
  }
`)

const createImageMutation = gql(/* GraphQL */ `
  mutation CreateImage($input: ImageCreateInput!) {
    imageCreate(input: $input) {
      image {
        ...UploadedImage
      }
    }
  }
`)

export const useUploadImage = () => {
  const { upload } = useDirectUpload()
  const [exec] = useMutation(createImageMutation)
  const { toast } = useToast()
  const [loading, setLoading] = useState(false)

  return useMemo(() => {
    return {
      upload: async (file: File) => {
        try {
          setLoading(true)
          const result = await upload(file)
          const mutationResp = await exec({
            variables: {
              input: {
                imageInput: {
                  signedId: result.signed_id,
                },
              },
            },
          })
          if (mutationResp.errors) {
            toast({
              title: "Error uploading image",
              description: mutationResp.errors[0].message,
              variant: "destructive",
            })
            throw new Error(
              `Error uploading image: ${mutationResp.errors[0].message}`
            )
          }

          invariant(mutationResp.data)
          return mutationResp.data.imageCreate.image
        } finally {
          setLoading(false)
        }
      },
      loading,
    }
  }, [exec, loading, toast, upload])
}

export const useUploadFile = () => {
  const { upload } = useDirectUpload()
  const [loading, setLoading] = useState(false)

  return useMemo(() => {
    return {
      upload: async (file: File) => {
        try {
          setLoading(true)
          const result = await upload(file)

          return result.signed_id
        } finally {
          setLoading(false)
        }
      },
      loading,
    }
  }, [loading, upload])
}
