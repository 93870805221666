import { Button } from "~/ui/button"

type NoResultsProps = {
  title: string
  description: string
  onClearSearch?: () => void | undefined
}

export const NoResults = ({
  title,
  description,
  onClearSearch,
}: NoResultsProps) => {
  return (
    <div className="flex flex-col gap-6 items-center justify-center">
      <h3 className="text-2xl mb-0 leading-4 tracking-wide">{title}</h3>

      <p>{description}</p>

      {onClearSearch && (
        <div>
          <Button className="font-normal text-sm" onClick={onClearSearch}>
            Clear Search
          </Button>
        </div>
      )}
    </div>
  )
}
