import { Post_CardFragment } from "~/__generated__/graphql"
import { IMAGE_MIME_TYPES } from "~/post-composer/useFileManager"

const isImageType = (contentType: string) =>
  IMAGE_MIME_TYPES.includes(contentType)

export const filterPostAttachments = (post: {
  attachments: Post_CardFragment["attachments"]
}) => {
  return [
    post.attachments.filter((a) => isImageType(a.contentType)),
    post.attachments.filter((a) => !isImageType(a.contentType)),
  ]
}

/** @deprecated */
export const usePostPinning = () => {
  return async (postId: string) => {
    throw new Error("TODO: remove this")
  }
}
