import { zodResolver } from "@hookform/resolvers/zod"
import { REGEXP_ONLY_DIGITS_AND_CHARS } from "input-otp"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { z } from "zod"
import { gql } from "~/__generated__"
import { getMetaVar, getMetaVarFlashMessage } from "~/common/get-meta-var"
import { serverTokenConfirmationPath, signInTokenPath } from "~/common/paths"
import { useSafeMutation } from "~/common/use-safe-mutation"
import { Button } from "~/ui/button"
import { Card, CardContent } from "~/ui/card"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/ui/form"
import { InputOTP, InputOTPGroup, InputOTPSlot } from "~/ui/input-otp"
import { ButtonLink } from "~/ui/link"
import { useToast } from "~/ui/use-toast"
import { AccessLegalLinks } from "./access-legal-links"
import { StyledFormMessage } from "~/forms/styled-form-message"

export const RESEND_MAGIC_LINK_MUTATION = gql(`
  mutation ResendMagicLink($input: ResendMagicLinkInput!) {
    resendMagicLink(input: $input) {
      sessionId
    }
  }
`)

const formSchema = z.object({
  code: z.string().length(6, { message: "Code must be 6 characters" }),
})

export const SignInTokenScreen = () => {
  const [resendMagicLink] = useSafeMutation(RESEND_MAGIC_LINK_MUTATION)

  const { sessionId } = useParams()
  invariant(sessionId)

  const navigate = useNavigate()
  const csrfToken = getMetaVar("csrf-token")
  const { toast } = useToast()

  const sendAgain = async () => {
    const { data } = await resendMagicLink({
      variables: {
        input: {
          sessionId,
        },
      },
    })

    if (data?.resendMagicLink.sessionId) {
      toast({ title: "Code sent", description: "please check your email" })
      navigate(signInTokenPath({ sessionId: data.resendMagicLink.sessionId }), {
        replace: true,
      })
    } else {
      toast({
        title: "Error",
        description: "Error sending code",
        variant: "destructive",
      })
    }
  }

  const [flashMessage] = useState(getMetaVarFlashMessage())

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      code: "",
    },
  })

  const code = form.watch("code")

  return (
    <div className="grid gap-8">
      <div className="text-center">
        <div>
          Click the link or enter the code
          <br />
          sent to your email to login.
        </div>
        <div className="font-bold">The codes expire after 10 minutes.</div>
      </div>
      <div className="">
        <Card>
          <CardContent className="pt-8">
            <Form {...form}>
              <form
                method="POST"
                action={serverTokenConfirmationPath({ id: sessionId })}
              >
                <input type="hidden" name="_method" value="patch" />
                <input
                  type="hidden"
                  name="authenticity_token"
                  value={csrfToken}
                />

                <div className="grid gap-4">
                  <FormField
                    control={form.control}
                    name="code"
                    render={({ field, fieldState }) => (
                      <FormItem className="grid gap-4">
                        <StyledFormMessage>
                          {flashMessage &&
                          flashMessage.message === "Token is invalid" ? (
                            <>
                              Code is incorrect or invalid.
                              <br />
                              Try again or request a new code.
                            </>
                          ) : null}
                        </StyledFormMessage>
                        <FormLabel className="sr-only">
                          One-Time Password
                        </FormLabel>
                        <FormControl>
                          <InputOTP
                            data-1p-ignore="true" // this comes through email, so prevent autofill from 1pass
                            maxLength={6}
                            {...field}
                            name="passwordless[token]"
                            pattern={REGEXP_ONLY_DIGITS_AND_CHARS}
                          >
                            <InputOTPGroup>
                              <InputOTPSlot index={0} />
                              <InputOTPSlot index={1} />
                              <InputOTPSlot index={2} />
                              <InputOTPSlot index={3} />
                              <InputOTPSlot index={4} />
                              <InputOTPSlot index={5} />
                            </InputOTPGroup>
                          </InputOTP>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <div className="flex justify-center ">
                    <Button
                      className=""
                      variant="default"
                      disabled={code.length === 0}
                      type="submit"
                    >
                      Confirm Code
                    </Button>
                  </div>

                  <AccessLegalLinks />
                </div>
              </form>
            </Form>
          </CardContent>
        </Card>
      </div>
      <div className="text-center">
        Didn't receive the code?{" "}
        <ButtonLink onClick={sendAgain} className="cursor-pointer">
          Send Again
        </ButtonLink>
      </div>
    </div>
  )
}
