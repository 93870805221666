import { CampaignDeliverableMetricsFragment } from "~/__generated__/graphql"
import { Subsection } from "~/campaigns/subsection"
import { SubsectionBody } from "~/campaigns/subsection-body"
import { SubsectionTitle } from "~/campaigns/subsection-title"
import { formatDate } from "~/common/date-formatting"

export const LeadGenerationSidebarMetrics: React.FC<{
  campaignDeliverable: CampaignDeliverableMetricsFragment
}> = ({ campaignDeliverable }) => {
  const metrics = {
    leadsSuppliedMetric: campaignDeliverable.leadsSuppliedMetric ?? 0,
    leadsToDateMetric: campaignDeliverable.leadsToDateMetric ?? 0,
    dateOfCompletion: campaignDeliverable.dateOfCompletion ?? "",
    notes: campaignDeliverable.metricNotes ?? "",
  }

  const leadsRemainingMetric =
    metrics.leadsSuppliedMetric - metrics.leadsToDateMetric

  return (
    <>
      <Subsection>
        <SubsectionTitle>Leads Supplied</SubsectionTitle>
        <SubsectionBody>
          {metrics.leadsSuppliedMetric.toLocaleString()}
        </SubsectionBody>
      </Subsection>
      <Subsection>
        <SubsectionTitle>Leads to Date</SubsectionTitle>
        <SubsectionBody>
          {metrics.leadsToDateMetric.toLocaleString()}
        </SubsectionBody>
      </Subsection>
      <Subsection>
        <SubsectionTitle>Leads Remaining</SubsectionTitle>
        <SubsectionBody>{leadsRemainingMetric.toLocaleString()}</SubsectionBody>
      </Subsection>
      <Subsection>
        <SubsectionTitle>Date of Completion</SubsectionTitle>
        <SubsectionBody>
          {metrics.dateOfCompletion
            ? formatDate(metrics.dateOfCompletion, "MMMM d, yyyy")
            : "-"}
        </SubsectionBody>
      </Subsection>
      <Subsection>
        <SubsectionTitle>Notes</SubsectionTitle>
        <SubsectionBody>{metrics.notes ? metrics.notes : "–"}</SubsectionBody>
      </Subsection>
    </>
  )
}
