// product-edit-screen.tsx

import { useQuery } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { useNavigate, useOutlet, useParams } from "react-router-dom"
import { Params } from "static-path"
import invariant from "tiny-invariant"
import { z } from "zod"
import { gql } from "~/__generated__"
import {
  ProductBriefPricingStructure,
  ProductBriefSalesType,
} from "~/__generated__/graphql"
import { Section, SectionLabel } from "~/campaigns/section"
import { gqlMatchOptional } from "~/common/gql-match"
import { NotFoundScreen } from "~/common/not-found-screen"
import * as paths from "~/common/paths"
import { useGoBack } from "~/common/use-go-back"
import { useSafeMutation } from "~/common/use-safe-mutation"
import { useValidationErrors } from "~/common/use-validation-errors"
import arrowLeft from "~/images/icons/arrow-left"
import { TablePageLayout } from "~/layouts/table-page-layout"
import { Button } from "~/ui/button"
import Text from "~/ui/typography"
import { useToast } from "~/ui/use-toast"
import { ContentReferences } from "./content-references"
import { formSchema, ProductForm } from "./product-form"

const query = gql(/* GraphQL */ `
  query ProductBrief($id: ID!) {
    node(id: $id) {
      __typename
      ... on ProductBrief {
        id
        name
        mainProblem
        preferredMessaging
        topCompetitors
        pricingStructure
        avgContractValue
        pricePerSeat
        salesType
        salesCycle
        mqlToWinRate
        company {
          id
          name
          categories
        }
        categories
        contentReferences {
          edges {
            node {
              id
              name
              publishDate
              link
              filename
              contentType
              hasAsset
              isDestroyed
              fileExtension
              byteSize
              contentReferenceLink
            }
          }
        }
      }
    }
  }
`)

const mutation = gql(/* GraphQL */ `
  mutation updateProductBrief($input: ProductBriefUpdateInput!) {
    productBriefUpdate(input: $input) {
      productBrief {
        id
        name
      }
    }
  }
`)

export const ProductEditScreen = () => {
  const params = useParams() as Params<
    typeof paths.companyProductsEditPath.pattern
  >
  const goBack = useGoBack(
    paths.companyProductsDetailPath({
      slug: params.slug,
      productId: params.productId,
    })
  )
  const outlet = useOutlet()
  const { toast } = useToast()
  const navigate = useNavigate()
  const productId = params.productId

  invariant(productId, "Expected productId to be defined")

  const {
    data: currentData,
    previousData,
    loading: queryLoading,
  } = useQuery(query, {
    variables: { id: productId },
  })

  const data = currentData || previousData

  const product = gqlMatchOptional(data?.node, "ProductBrief")

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    values: {
      companyId: product?.company.id ?? "",
      name: product?.name ?? "",
      mainProblem: product?.mainProblem ?? "",
      preferredMessaging: product?.preferredMessaging ?? "",
      topCompetitors: product?.topCompetitors ?? [],
      pricingStructure:
        product?.pricingStructure ?? ProductBriefPricingStructure.BySeat,
      avgContractValue: product?.avgContractValue || undefined,
      pricePerSeat: product?.pricePerSeat || undefined,
      salesType: product?.salesType ?? ProductBriefSalesType.SalesTeam,
      salesCycle: product?.salesCycle || undefined,
      mqlToWinRate: product?.mqlToWinRate || undefined,
      categories: product?.categories ?? [],
    },
  })

  const [exec, result] = useSafeMutation(mutation)
  useValidationErrors(form.setError, result)

  const pricingStructure = form.watch().pricingStructure
  const pricingFlatStructureOrByUsage =
    pricingStructure === ProductBriefPricingStructure.FlatPricing ||
    pricingStructure === ProductBriefPricingStructure.ByUsage
  const pricingBySeat = pricingStructure === ProductBriefPricingStructure.BySeat

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    let topCompetitors = values.topCompetitors.filter(
      (topCompetitor) => topCompetitor.trim() !== ""
    )

    const result = await exec({
      variables: {
        input: {
          id: productId,
          productBriefInput: {
            companyId: values.companyId,
            name: values.name,
            mainProblem: values.mainProblem,
            preferredMessaging: values.preferredMessaging,
            topCompetitors: topCompetitors,
            pricingStructure: values.pricingStructure,
            salesType: values.salesType,
            mqlToWinRate: values.mqlToWinRate,
            salesCycle: values.salesCycle,
            categories: values.categories,
            ...(pricingFlatStructureOrByUsage && {
              avgContractValue: values.avgContractValue,
            }),
            ...(pricingBySeat && {
              pricePerSeat: values.pricePerSeat,
            }),
          },
        },
      },
    })

    if (result.errors) {
      toast({
        title: "Error",
        description: "There was an error updating the product brief",
      })
    } else {
      toast({
        title: "Product Brief updated",
        description: "The product brief has been updated",
      })
      navigate(paths.companyProductsPath({ slug: params.slug ?? "" }))
    }
  }

  if (queryLoading) {
    return <div>Loading...</div>
  }

  if (!product && !queryLoading) {
    return <NotFoundScreen title="Can't find product" />
  }

  return (
    <>
      <div className="px-[40px] border-b">
        <Button onClick={goBack} variant="ghost" className="-ms-4">
          <img {...arrowLeft} alt="" className="inline-block me-2" />
          <Text variant="mini-caps">Back</Text>
        </Button>
      </div>
      <div>
        <TablePageLayout padding={false} rightSideSlot={outlet}>
          <div className="flex flex-1 flex-col">
            <div className="grid grid-cols-[minmax(475px,1fr)_475px] p-[40px] gap-[40px] items-start overflow-auto">
              <div className="space-y-8">
                <SectionLabel>Edit Product Brief</SectionLabel>

                <ProductForm form={form} onSubmit={onSubmit} />
              </div>

              <div className="grid">
                <Section variant="bordered" className="mt-16">
                  <ContentReferences
                    contentReferences={product?.contentReferences}
                    isEditing
                  />
                </Section>
              </div>
            </div>
          </div>
        </TablePageLayout>
      </div>
    </>
  )
}
