export function formatPercentage(amount: number) {
  if (isNaN(amount) || !isFinite(amount)) {
    return "-"
  }

  if (
    Number.isInteger(amount) ||
    Math.abs(amount - Math.round(amount)) < 0.0001 // We don't want 28.000001 to show up as 28.00
  ) {
    return `${Math.round(amount)}%`
  }
  return `${amount.toFixed(2)}%`
}
