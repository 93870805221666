import { useEffect } from "react"
import * as Sentry from "@sentry/react"
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom"
import { getMetaVarMaybe } from "~/common/get-meta-var"

let environment = getMetaVarMaybe("SENTRY_ENVIRONMENT") ?? undefined
let dsn = getMetaVarMaybe("SENTRY_DSN")

if (dsn) {
  Sentry.init({
    dsn,
    environment,
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    tracesSampleRate: 0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: [/^\/graphql\//],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
} else {
  // eslint-disable-next-line no-console
  console.warn("No Sentry DSN found, skipping Sentry initialization")
}
