import { TableHead } from "~/ui/table"

export const NewsletterColumns = () => (
  <>
    <TableHead className="w-[150px]">Email Recipients</TableHead>
    <TableHead className="w-[150px]">Opens</TableHead>
    <TableHead className="w-24">Open Rate</TableHead>
    <TableHead className="w-[150px]">Clicks</TableHead>
    <TableHead className="w-24">CTR</TableHead>
  </>
)
