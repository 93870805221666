import { LinkButton } from "~/ui/link-button"
import Text from "~/ui/typography"

export const UnauthorizedScreen = () => {
  return (
    <div className="flex items-center justify-center h-full p-4">
      <div className="space-y-2 lg:space-y-4 max-w-[530px] text-center">
        <div className="text-6xl lg:text-[230px] lg:leading-[230px] font-bold text-gray-300">
          401
        </div>
        <h2 className="text-xl md:text-3xl font-medium">
          Error 401 - Unauthorized access
        </h2>
        <div className="space-y-8">
          <p>
            <Text variant="body-14" className="text-gray-500">
              Looks like you don't have permission to view this page. Click the
              link below to go get you back on track and find what you're
              looking for.
            </Text>
          </p>
          <LinkButton to="/">Back to Home</LinkButton>
        </div>
      </div>
    </div>
  )
}
