import { gql } from "~/__generated__"
import { useMutation } from "@apollo/client"
import { Button } from "~/ui/button"
import exportIcon from "~/images/icons/export"
import Text from "~/ui/typography"

const EXPORT_CAMPAIGN_DELIVERABLES = gql(/* GraphQL */ `
  mutation ExportCampaignDeliverables(
    $campaignId: ID!
    $productBriefIds: [ID!]
    $creatorBrandIds: [ID!]
  ) {
    exportCampaignDeliverables(
      input: {
        campaignId: $campaignId
        productBriefIds: $productBriefIds
        creatorBrandIds: $creatorBrandIds
      }
    ) {
      url
    }
  }
`)

interface ExportButtonProps {
  campaignId: string
  productBriefIds: string[]
  creatorBrandIds: string[]
  onError?: (error: string) => void
}

export const ExportButton: React.FC<ExportButtonProps> = ({
  campaignId,
  productBriefIds,
  creatorBrandIds,
  onError,
}) => {
  const [exportDeliverables, { loading }] = useMutation(
    EXPORT_CAMPAIGN_DELIVERABLES
  )

  const handleExport = async () => {
    try {
      const { data } = await exportDeliverables({
        variables: { campaignId, productBriefIds, creatorBrandIds },
      })

      if (data?.exportCampaignDeliverables?.url) {
        window.location.href = data.exportCampaignDeliverables.url
      }
    } catch (error) {
      onError?.("Export failed: " + error)
    }
  }

  return (
    <Button
      onClick={handleExport}
      disabled={loading}
      variant="cardControl"
      size="sm"
      className="flex items-center gap-2 h-8"
    >
      <img {...exportIcon} alt="" className="inline-block align-baseline" />
      <Text variant="body-12">{loading ? "Exporting..." : "Export"}</Text>
    </Button>
  )
}
