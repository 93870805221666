import { useQuery } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Params } from "static-path"
import { z } from "zod"
import { gql } from "~/__generated__"
import { ClientVisibility, CompanyRole } from "~/__generated__/graphql"
import { gqlMatchOptional } from "~/common/gql-match"
import * as paths from "~/common/paths"
import { companyUsersPath } from "~/common/paths"
import { useSafeMutation } from "~/common/use-safe-mutation"
import { useValidationErrors } from "~/common/use-validation-errors"
import { useCompany } from "~/companies/company-context"
import { CheckboxField } from "~/fields/checkbox-field"
import { SelectField } from "~/fields/select-field"
import { TextField } from "~/fields/text-field"
import { StyledFormMessage } from "~/forms/styled-form-message"
import { Button } from "~/ui/button"
import { Form } from "~/ui/form"

const query = gql(`
  query User($userId: ID!) {
    node(id: $userId) {
      __typename
      ... on User {
        id
        firstName
        lastName
        title
        email
        role
        favoriteAdOfAllTime
        briefSummaryOfExperience
        calendlyUrl
        clientVisibility

        companies {
          edges {
            role
            reportingContact
            creativeContact
            node {
              id
            }
          }
        }

        canUpdate {
          value
        }
      }
    }
  }
`)

type EditCompanyUserParams = Params<typeof paths.companyUsersEditPath.pattern>

export const EditCompanyUserPane = () => {
  const params = useParams() as EditCompanyUserParams
  const navigate = useNavigate()
  const { company } = useCompany()

  const formSchema = z.object({
    firstName: z.string(),
    lastName: z.string(),
    title: z.string(),
    email: z
      .string()
      .trim()
      .min(1, { message: "Enter an email" })
      .email({ message: "Email is invalid" }),
    companyRole: z.nativeEnum(CompanyRole),
    clientVisibility: z.nativeEnum(ClientVisibility),
    reportingContact: z.boolean().optional(),
    creativeContact: z.boolean().optional(),
  })

  const {
    data: currentData,
    previousData,
    loading: userLoading,
  } = useQuery(query, {
    variables: {
      userId: params.userId,
    },
  })

  const data = currentData || previousData
  const user = gqlMatchOptional(data?.node, "User")

  const companyRelationship = user?.companies.edges.find(
    (edge) => edge.node.id === company.id
  )

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    values: {
      firstName: user?.firstName ?? "",
      lastName: user?.lastName ?? "",
      title: user?.title ?? "",
      email: user?.email ?? "",
      companyRole: companyRelationship?.role ?? CompanyRole.Client,
      clientVisibility: ClientVisibility.Visible,
      reportingContact: companyRelationship?.reportingContact,
      creativeContact: companyRelationship?.creativeContact,
    },
  })

  const [runMutation, mutationResult] = useSafeMutation(USER_UPDATE_MUTATION)
  useValidationErrors(form.setError, mutationResult)

  if (!user) return null

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const { errors } = await runMutation({
      variables: {
        input: {
          id: user.id,
          userInput: {
            firstName: values.firstName,
            lastName: values.lastName,
            title: values.title,
            email: values.email,
          },
          creativeContact: values.creativeContact,
          reportingContact: values.reportingContact,
          companyId: company.id,
          companyRole: values.companyRole,
        },
      },
    })

    if (!errors) {
      navigate(companyUsersPath({ slug: company.slug }))
    }
  }

  const roleOptions = [
    { label: "Client", value: CompanyRole.Client },
    { label: "Admin", value: CompanyRole.Admin },
  ]

  return (
    <div className="border-l w-64 p-4">
      <h2 className="font-medium text-lg mb-4">Edit User</h2>

      {!userLoading && (
        <Form {...form}>
          {Object.keys(form.formState.errors).length > 0 && (
            <div className="my-3">
              <StyledFormMessage>
                <div>Missing required* fields</div>
              </StyledFormMessage>
            </div>
          )}
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="space-y-4">
              <TextField
                control={form.control}
                name="firstName"
                label="First name*"
                required
              />
              <TextField
                control={form.control}
                name="lastName"
                label="Last name*"
                required
              />
              <TextField
                control={form.control}
                name="email"
                label="Email*"
                required
              />
              <TextField
                control={form.control}
                name="title"
                label="Title*"
                required
              />
              <SelectField
                control={form.control}
                name="companyRole"
                label="Role*"
                options={roleOptions}
                text={(field) =>
                  roleOptions.find((option) => option.value === field.value)
                    ?.label || "Select a role"
                }
              />
              <CheckboxField
                control={form.control}
                name="reportingContact"
                label="Reporting Contact"
              />
              <CheckboxField
                control={form.control}
                name="creativeContact"
                label="Creative Contact"
              />
            </div>

            <div className="bg-gray-50 rounded text-center py-4 px-3 text-sm mt-6">
              This user will not be added into Salesforce
            </div>

            <div className="flex justify-between items-center mt-6">
              <Button type="submit" className="font-light">
                Save &amp; Update
              </Button>
              <Link
                to={companyUsersPath({ slug: company.slug })}
                className="text-xs-plus text-gray-400"
              >
                Cancel
              </Link>
            </div>
          </form>
        </Form>
      )}
    </div>
  )
}

const USER_UPDATE_MUTATION = gql(`
  mutation companyUserUpdate($input: CompanyUserUpdateInput!) {
    companyUserUpdate(input: $input) {
      user {
        id
        firstName
        lastName
        title
        email
        role
      }
    }
  }
`)
