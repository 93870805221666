import { useQuery } from "@apollo/client"
import { useParams } from "react-router-dom"
import { Params } from "static-path"
import { gql } from "~/__generated__"
import { gqlMatchOptional } from "~/common/gql-match"
import { NotFoundScreen } from "~/common/not-found-screen"
import * as paths from "~/common/paths"
import { GraphqlError } from "~/ui/errors"
import { LoadingIndicatorCentered } from "~/ui/loading-indicator"
import { ProductDetail } from "./product-detail"

gql(/* GraphQL */ `
  fragment ProductDetailPaneFragment on ProductBrief {
    id
    name
    createdAt
    avgContractValue
    mainProblem
    mqlToWinRate
    preferredMessaging
    pricePerSeat
    pricingStructure
    salesCycle
    salesType
    topCompetitors
    categories
    canUpdate {
      value
    }
    company {
      id
      name
    }
    contentReferences(first: 100) {
      edges {
        node {
          id
          name
          publishDate
          link
          filename
          contentType
          hasAsset
          isDestroyed
          fileExtension
          byteSize
          contentReferenceLink
        }
      }
    }
    publishedDeliverables(first: 20) {
      edges {
        node {
          id
          deliverableName
          status
          publishDateCanonical

          campaign {
            id
            campaignName
          }
        }
      }
    }
  }
`)

const query = gql(/* GraphQL */ `
  query ProductDetailPaneQuery($id: ID!) {
    node(id: $id) {
      __typename
      ... on ProductBrief {
        ...ProductDetailPaneFragment
      }
    }
  }
`)

export const ProductDetailScreen = () => {
  const params = useParams() as Params<
    typeof paths.companyProductsDetailPath.pattern
  >

  const result = useQuery(query, { variables: { id: params.productId } })
  const product = gqlMatchOptional(result.data?.node, "ProductBrief")

  if (result.error) {
    return <GraphqlError error={result.error} />
  }

  if (!product && !result.loading) {
    return <NotFoundScreen title="Product not found" />
  }

  if (result.loading) {
    return <LoadingIndicatorCentered />
  }

  if (!product) {
    return null
  }

  return <ProductDetail product={product} />
}
