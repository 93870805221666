import { Outlet, useMatches } from "react-router-dom"
import { useViewerMaybe } from "~/auth/viewer-context"
import { companyDashboardPath, profilePath, signOutPath } from "~/common/paths"
import AccountProfileIcon from "~/images/icons/account-profile.svg?react"
import dropdownArrowDownIcon from "~/images/icons/dropdown-arrow-down"
import HelpIcon from "~/images/icons/help.svg?react"
import SignOutIcon from "~/images/icons/sign-out.svg?react"

import { useState } from "react"
import { CompanyRole, Role } from "~/__generated__/graphql"
import { cn } from "~/common/cn"
import { useCompanyMaybe } from "~/companies/company-context"
import { LinkButton } from "~/ui/link-button"
import {
  StyledDropdown,
  StyledDropdownInner,
  StyledDropdownItemWrapper,
  StyledDropdownLink,
} from "~/ui/styled-dropdown"
import { UserAvatar } from "~/users/user-avatar"
import { Notifications } from "./notifications"

const PageTitle = () => {
  const matches = useMatches()
  const handle = matches[matches.length - 1].handle as
    | {
        title: string
        icon: React.ComponentType<React.SVGProps<SVGSVGElement>>
      }
    | undefined

  const title = handle?.title
  const Icon = handle?.icon

  return (
    <div className="flex items-center gap-2">
      {Icon && <Icon className="w-6 h-6" />}
      <h1 className="text-2xl">{title}</h1>
    </div>
  )
}

const AvatarDropdown = () => {
  const [open, setOpen] = useState(false)

  const { viewer } = useViewerMaybe()

  if (!viewer) {
    return null
  }

  return (
    <StyledDropdown
      open={open}
      onOpenChange={setOpen}
      trigger={
        <div
          className={cn(
            "border hover:border-foreground flex items-center justify-center rounded-full w-[38px] h-[38px]",
            {
              "border-foreground": open,
            }
          )}
        >
          <UserAvatar user={viewer} />
        </div>
      }
    >
      <StyledDropdownLink
        linkProps={{ to: profilePath({}) }}
        title="Account Profile"
        icon={<AccountProfileIcon />}
      />
      <StyledDropdownItemWrapper asChild>
        <a href="mailto:partnerships@workweek.com">
          <StyledDropdownInner title="Need help?" icon={<HelpIcon />} />
        </a>
      </StyledDropdownItemWrapper>
      <StyledDropdownItemWrapper asChild>
        <a href={signOutPath.pattern}>
          <StyledDropdownInner title="Sign Out" icon={<SignOutIcon />} />
        </a>
      </StyledDropdownItemWrapper>
    </StyledDropdown>
  )
}

export const TopBarLayout = () => {
  const { viewer } = useViewerMaybe()
  const activeCompany = useCompanyMaybe()
  const [open, setOpen] = useState(false)

  const companies = viewer?.companies?.edges || []

  const isWorkweekMember =
    viewer?.role === Role.WorkweekTeam ||
    viewer?.role === Role.WorkweekAdmin ||
    viewer?.role === Role.Creative

  const isClientOrCompanyAdmin =
    viewer?.role === Role.Client ||
    activeCompany.companyRole === CompanyRole.Admin

  return (
    <>
      <div className="h-[77px] border-b flex items-center justify-between px-10">
        <PageTitle />

        {viewer && (
          <div className="flex gap-10 items-center">
            <Notifications />

            <div className="flex items-center gap-4">
              <div className="flex flex-col items-end">
                <div className="text-xs-plus font-medium leading-snug">
                  {viewer.firstName} {viewer.lastName}
                </div>

                <div className="flex gap-2">
                  {isWorkweekMember && (
                    <div className="text-xs leading-snug">Workweek</div>
                  )}
                  {activeCompany.company && companies.length > 1 && (
                    <StyledDropdown
                      onOpenChange={(open) => {
                        setOpen(open)
                      }}
                      open={open}
                      trigger={
                        <div className="text-xs leading-snug flex items-center gap-1">
                          {activeCompany.company?.name ?? "(no company)"}

                          <img
                            aria-hidden="true"
                            alt="Dropdown arrow"
                            {...dropdownArrowDownIcon}
                            className={cn({
                              "rotate-180": open,
                              "rotate-0": !open,
                            })}
                          />
                        </div>
                      }
                    >
                      {companies.map((company) => (
                        <StyledDropdownItemWrapper
                          key={company.node.id}
                          asChild
                        >
                          <LinkButton
                            variant="ghost"
                            size="sm"
                            className="justify-start space-x-1 text-xs-plus h-7"
                            to={companyDashboardPath({
                              slug: company.node.slug,
                            })}
                          >
                            <div className="pl-4">{company.node.name}</div>
                            {company.node.id === activeCompany.company?.id && (
                              <div
                                className={cn(
                                  "w-2 h-2 bg-foreground rounded-full group-hover:bg-white mr-2"
                                )}
                              ></div>
                            )}
                          </LinkButton>
                        </StyledDropdownItemWrapper>
                      ))}
                    </StyledDropdown>
                  )}
                </div>
                {isClientOrCompanyAdmin && companies.length === 1 && (
                  <div className="text-xs leading-snug">
                    {companies[0].node.name}
                  </div>
                )}
              </div>

              <AvatarDropdown />
            </div>
          </div>
        )}
      </div>
      <Outlet />
    </>
  )
}
