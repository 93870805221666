import * as React from "react"

import { Check, ChevronsUpDown } from "lucide-react"
import { cn } from "~/common/cn"
import badgeX from "~/images/icons/badge-x"
import { Badge } from "./badge"
import { Button } from "./button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "./command"
import { Popover, PopoverContent, PopoverTrigger } from "./popover"

export type OptionType = {
  label: string
  value: string
}

export interface MultiSelectProps {
  options: OptionType[]
  getLabel?: (id: string) => string
  selected: string[]
  onValuesChange: (ids: Array<string>) => void
  className?: string
  inputValue?: string
  onInputChange?: (value: string) => void
  shouldFilter?: boolean
  id?: string
  name?: string
  onFetchMore?: () => void
  placeholder?: string
  disabled?: boolean
  dividers?: Array<number>
}

function MultiSelect({
  options,
  getLabel,
  selected,
  onValuesChange,
  className,
  shouldFilter,
  placeholder,
  disabled,
  dividers,
  ...props
}: MultiSelectProps) {
  const [open, setOpen] = React.useState(false)

  const handleUnselect = (item: string) => {
    onValuesChange(selected.filter((i) => i !== item))
  }

  return (
    <Popover open={open} onOpenChange={setOpen} {...props}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-full justify-between h-full text-foreground px-2 rounded-lg"
          onClick={() => setOpen(!open)}
          disabled={disabled}
        >
          {selected.length === 0 && (
            <div className="text-black/50">{placeholder}</div>
          )}
          <div className="flex gap-2 flex-wrap">
            {selected.length === 0 && (
              <Badge className="mr-1 mb-1 opacity-0">&nbsp;</Badge>
            )}
            {selected.map((id) => (
              <Badge
                variant="outline"
                key={id}
                onClick={() => handleUnselect(id)}
              >
                {getLabel
                  ? getLabel(id)
                  : options.find((o) => o.value === id)?.label}
                <div
                  className="ml-1 ring-offset-background rounded-full outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleUnselect(id)
                    }
                  }}
                  onMouseDown={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    handleUnselect(id)
                  }}
                >
                  <img {...badgeX} alt="X" />
                </div>
              </Badge>
            ))}
          </div>
          <ChevronsUpDown className="h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-64 mx-4 p-0" align="start">
        <Command className={className} shouldFilter={shouldFilter}>
          <CommandInput
            placeholder="Search ..."
            value={props.inputValue}
            onValueChange={props.onInputChange}
          />
          <CommandEmpty>No item found.</CommandEmpty>
          <CommandGroup className="max-h-64 overflow-auto">
            <CommandList>
              {options.map((option, index) => (
                <CommandItem
                  key={option.value}
                  onSelect={() => {
                    onValuesChange(
                      selected.includes(option.value)
                        ? selected.filter((item) => item !== option.value)
                        : [...selected, option.value]
                    )
                    setOpen(true)
                  }}
                  value={option.label}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      selected.includes(option.value)
                        ? "opacity-100"
                        : "opacity-0"
                    )}
                  />
                  {option.label}
                </CommandItem>
              ))}
            </CommandList>
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  )
}

export { MultiSelect }
