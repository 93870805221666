import { Button } from "../ui/button"
import { Dialog, DialogContent } from "../ui/dialog"
import { z } from "zod"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { Form } from "react-router-dom"
import { Textarea } from "../ui/textarea"
import Text from "~/ui/typography"

export const RejectModal = ({
  isOpen,
  onClose,
  onReject,
}: {
  isOpen: boolean
  onClose: () => void
  onReject: (message: string) => void
}) => {
  const formSchema = z.object({
    rejectionFeedback: z.string(),
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      rejectionFeedback: "",
    },
  })

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(value) => {
        if (!value) onClose()
      }}
    >
      <DialogContent className="w-2/3 max-w-xl gap-0 space-y-4">
        <Text variant="body-18-medium" className="mt-2">
          Feedback
        </Text>

        <div>
          <div>
            <Form {...form}>
              <form>
                <Textarea
                  {...form.register("rejectionFeedback")}
                  className="max-w-xl"
                />

                <div className="flex mt-4">
                  <Button
                    type="button"
                    disabled={form.formState.isSubmitting}
                    className="px-6"
                    onClick={() => onReject(form.watch("rejectionFeedback"))}
                  >
                    Send Feedback
                  </Button>
                </div>
              </form>
            </Form>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
