import { useApolloClient, useMutation } from "@apollo/client"
import { gql } from "~/__generated__"
import { formatDate } from "~/common/date-formatting"
import { displayErrors } from "~/common/validations"
import { Button } from "~/ui/button"
import { Dialog, DialogContent } from "~/ui/dialog"
import Text from "~/ui/typography"
import { useToast } from "~/ui/use-toast"
import {
  BEST_IN_CLASS_QUERY,
  WORKWEEK_CONTENT_QUERY,
} from "./content-library-screen"

export const DeleteContentReferenceModal = ({
  isOpen,
  onClose,
  contentReference,
}: {
  isOpen: boolean
  onClose: () => void
  contentReference: any
}) => {
  const apolloClient = useApolloClient()
  const [runMutation] = useMutation(DELETE_CONTENT_REFERENCE_MUTATION)
  const { toast } = useToast()

  const deleteContentReference = async () => {
    const { errors } = await runMutation({
      variables: { input: { id: contentReference.id } },
    })

    if (errors) {
      displayErrors(errors)
    } else {
      await apolloClient.refetchQueries({
        include: [WORKWEEK_CONTENT_QUERY, BEST_IN_CLASS_QUERY],
      })
      toast({ title: "Content deleted" })
      onClose()
    }
  }

  if (!contentReference) return null

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(value) => {
        if (!value) onClose()
      }}
    >
      <DialogContent className="w-2/3 max-w-xl gap-0 text-center">
        <div className="text-2xl mb-4">Delete Content</div>
        <div className="mb-6 text-sm">
          Are you sure you want to delete this content?
        </div>

        <div className="border border-gray-200 rounded-lg bg-gray-50 p-4 flex items-center justify-between">
          <div className="flex flex-col justify-start items-start">
            <Text variant="body-14-medium">{contentReference.name}</Text>
            <Text variant="body-12" className="text-gray-400">
              {formatDate(contentReference.publishDate, "MMM d, yyyy")}
            </Text>
          </div>
          <div>
            <Button
              type="button"
              className="px-6"
              onClick={deleteContentReference}
            >
              Delete
            </Button>
          </div>
        </div>

        <div className="flex mt-4">
          <Button
            type="button"
            onClick={() => onClose()}
            variant="linkMuted"
            size="xs"
          >
            Cancel
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

const DELETE_CONTENT_REFERENCE_MUTATION = gql(`
  mutation DeleteContentReference($input: ContentReferenceDestroyInput!) {
    contentReferenceDestroy(input: $input) {
      contentReference {
        id
        isDestroyed
      }
    }
  }
`)
