import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useViewer } from "~/auth/viewer-context"
import { rootPath, signOutPath } from "~/common/paths"

export const NoCompaniesScreen = () => {
  const { viewer } = useViewer()

  const isCompaniesEmpty = viewer.companies.edges.length === 0
  const navigate = useNavigate()

  useEffect(() => {
    if (!isCompaniesEmpty) {
      navigate(rootPath({}))
    }
  }, [isCompaniesEmpty, navigate])

  if (!isCompaniesEmpty) {
    return null
  }

  return (
    <div className="p-4 grid gap-2">
      <p>Your account doesn't have access to any companies.</p>
      <p>Account: {viewer.email}</p>

      <a href={signOutPath({})} className="underline">
        Sign out
      </a>
    </div>
  )
}
