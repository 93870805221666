import { UseFormReturn } from "react-hook-form"
import { z } from "zod"
import { formSchema } from "~/campaigns/campaign-deliverable-edit-metrics-pane"
import { TextField } from "~/fields/text-field"
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/ui/form"
import { Input } from "~/ui/input"
import Text from "~/ui/typography"

export const NewsletterAdsSidebarEditMetrics: React.FC<{
  form: UseFormReturn<z.infer<typeof formSchema>>
}> = ({ form }) => {
  const recipientsMetric = form.watch("recipientsMetric")
  const opensMetric = form.watch("opensMetric")
  const totalClicksMetric = form.watch("totalClicksMetric")

  const openRate = (Number(opensMetric) / Number(recipientsMetric)) * 100
  const clickThroughRate =
    (Number(totalClicksMetric) / Number(opensMetric)) * 100

  return (
    <>
      <TextField
        type="number"
        control={form.control}
        name="recipientsMetric"
        label="Email Recipients"
        onChange={(e) => {
          form.setValue("recipientsMetric", e.target.value)
        }}
        required
      />
      <TextField
        type="number"
        control={form.control}
        name="opensMetric"
        label="Opens"
        onChange={(e) => {
          form.setValue("opensMetric", e.target.value)
        }}
        required
      />
      <div className="space-y-2">
        <Text variant="body-14">
          Open Rate <span className="text-gray-400">(calculated)</span>
        </Text>
        <Input disabled value={`${openRate.toFixed(2)}%`} />
      </div>
      <div className="grid gap-2">
        <div className="flex gap-2">
          <div>
            <FormField
              control={form.control}
              name="totalClicksMetric"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Clicks</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      type="number"
                      value={form.watch("totalClicksMetric")}
                      onChange={(e) => {
                        form.setValue("totalClicksMetric", e.target.value)
                      }}
                    />
                  </FormControl>
                  <FormMessage className="text-xs" />
                </FormItem>
              )}
            />
          </div>
        </div>
      </div>
      <div className="space-y-2">
        <Text variant="body-14">
          Click Through Rate <span className="text-gray-400">(calculated)</span>
        </Text>
        <Input disabled value={`${clickThroughRate.toFixed(2)}%`} />
      </div>
    </>
  )
}
