import externalLink from "../images/icons/external-link"

export const ExternalLink: React.FC<{
  href: string
  children: React.ReactNode
}> = ({ href, children }) => {
  return (
    <div>
      <a href={href} target="_blank" rel="noreferrer">
        {children}{" "}
        <img {...externalLink} alt="" className="inline-block align-baseline" />
      </a>
    </div>
  )
}
