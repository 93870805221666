import { useEffect, useState } from "react"
import { Wizard, WizardProps } from "./Wizard"
import { LoadingIndicator } from "./LoadingIndicator"

interface GalleryProps extends Omit<WizardProps, "children"> {
  images: string[]
}

export const Gallery = ({ images, ...props }: GalleryProps) => {
  return (
    <Wizard
      {...props}
      dialogContentProps={{ variant: "gallery" }}
      withKeyboardControl
      withArrowNavigation
    >
      {images.map((image) => (
        <GalleryStep image={image} key={image} />
      ))}
    </Wizard>
  )
}

interface GalleryStepProps {
  image: string
}

const GalleryStep = ({ image }: GalleryStepProps) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false)

  useEffect(() => {
    // preload the image
    const img = new Image()
    img.src = image
    img.onload = () => {
      setIsImageLoaded(true)
    }

    return () => {
      img.onload = null
      img.src = ""
    }
  }, [image])

  return isImageLoaded ? (
    <img
      src={image}
      alt=""
      className="object-scale-down w-full h-full max-h-[90vh] max-w-[90vw]"
    />
  ) : (
    <div className="w-72 h-72 flex justify-center items-center">
      <LoadingIndicator />
    </div>
  )
}
