import { CampaignDeliverableStatus } from "~/__generated__/graphql"
import { cn } from "~/common/cn"
import greenCheck from "~/images/icons/green-check"
import { CampaignDeliverableStatusLabel } from "./campaign-deliverable-status"

export const DeliverableStatus = (props: {
  status: CampaignDeliverableStatus
  className?: string
}): React.ReactNode => {
  return (
    <div className={cn("flex gap-2 items-center justify-end", props.className)}>
      <div>
        <CampaignDeliverableStatusLabel {...props} />
      </div>
      {(props.status === CampaignDeliverableStatus.Published ||
        props.status === CampaignDeliverableStatus.AdScaffolded ||
        props.status === CampaignDeliverableStatus.Approved) && (
        <div className="w-[14px] flex-shrink-0">
          <img {...greenCheck} alt="" />
        </div>
      )}
    </div>
  )
}
