import { UseFormReturn } from "react-hook-form"
import { z } from "zod"
import { metricsFormSchema } from "~/campaigns/campaign-metrics-screen"
import { CampaignTotals } from "../campaign-totals"

export const CampaignLeadGenerationTotals: React.FC<{
  form: UseFormReturn<z.infer<typeof metricsFormSchema>>
}> = ({ form }) => {
  const { watch } = form
  const campaignDeliverables = watch("deliverables")
  const nonPendingCampaignDeliverables = campaignDeliverables.filter(
    (deliverable) => !deliverable.metricsPending
  )
  const leadsSupplied = nonPendingCampaignDeliverables.reduce(
    (sum, deliverable) =>
      sum + parseInt(deliverable.leadsSuppliedMetric || "0"),
    0
  )
  const leadsToDate = nonPendingCampaignDeliverables.reduce(
    (sum, deliverable) => sum + parseInt(deliverable.leadsToDateMetric || "0"),
    0
  )
  const totalLeadsRemaining = leadsSupplied - leadsToDate

  return (
    <CampaignTotals
      totals={[
        { value: leadsSupplied, label: "Total Leads Supplied" },
        { value: leadsToDate, label: "Total Leads to Date" },
        { value: totalLeadsRemaining, label: "Total Leads Remaining" },
      ]}
    />
  )
}
