import { useQuery } from "@apollo/client"
import React, { useState } from "react"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import { ExportReportStatus } from "~/__generated__/graphql"
import { gqlMatchOptional } from "~/common/gql-match"
import { useSafeMutation } from "~/common/use-safe-mutation"
import _export from "~/images/icons/export"
import { Button, buttonVariants } from "~/ui/button"
import { Card, CardContent, CardHeader } from "~/ui/card"
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "~/ui/dialog"
import { GraphqlError } from "~/ui/errors"
import { Heading } from "~/ui/heading"
import { LoadingIndicatorCentered } from "~/ui/loading-indicator"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/ui/table"
import { toast } from "~/ui/use-toast"
const CAMPAIGN_CACHED_ENRICHED_STATS_QUERY = gql(/** GraphQL */ `
  query CampaignCachedEnrichedStats($campaignId: ID!) {
    campaign: node(id: $campaignId) {
      id
      __typename
      ... on Campaign {
        cachedEnrichedClicksStats {
          id
          creatorBrand
          newsletter
          companyName
          title
          seniority
          companySize
          realClicks
          uniqueRealClicks
        }
        cachedEnrichedOpensStats {
          id
          blastId
          companySize
          createdAt
          listName
          name
          realOpens
          seniority
          title
          uniqueRealOpens
        }
      }
    }
  }
`)

const CAMPAIGN_ENRICHED_CLICK_EXPORT_CREATE_MUTATION = gql(/** GraphQL */ `
  mutation CampaignEnrichedClickExportCreate($campaignId: ID!) {
    campaignEnrichedClickExportCreate(input: { campaignId: $campaignId }) {
      campaignEnrichedClickExport {
        id
      }
    }
  }
`)

const CAMPAIGN_ENRICHED_OPEN_EXPORT_CREATE_MUTATION = gql(/** GraphQL */ `
  mutation CampaignEnrichedOpenExportCreate($campaignId: ID!) {
    campaignEnrichedOpenExportCreate(input: { campaignId: $campaignId }) {
      campaignEnrichedOpenExport {
        id
      }
    }
  }
`)

const CAMPAIGN_ENRICHED_EXPORT_QUERY = gql(/** GraphQL */ `
  query CampaignEnrichedExport($id: ID!) {
    node(id: $id) {
       __typename
      ... on CampaignEnrichedClickExport {
        id
        status
        csvDownloadPath
      }
      ... on CampaignEnrichedOpenExport {
        id
        status
        csvDownloadPath
      }
    }
  }
`)

interface CampaignCachedEnrichedStatsProps {
  campaignId: string
}

export const CampaignCachedEnrichedStats: React.FC<
  CampaignCachedEnrichedStatsProps
> = ({ campaignId }) => {
  const [exportId, setExportId] = useState<string | null>(null)
  const [exportStatus, setExportStatus] = useState<ExportReportStatus | null>(
    null
  )
  const [csvDownloadPath, setCsvDownloadPath] = useState<string | null>(null)
  const [exportType, setExportType] = useState<"click" | "open" | null>(null)

  const { data, loading, error } = useQuery(
    CAMPAIGN_CACHED_ENRICHED_STATS_QUERY,
    {
      variables: { campaignId },
    }
  )

  const [exportClickReport, { loading: exportingClick }] = useSafeMutation(
    CAMPAIGN_ENRICHED_CLICK_EXPORT_CREATE_MUTATION
  )

  const [exportOpenReport, { loading: exportingOpen }] = useSafeMutation(
    CAMPAIGN_ENRICHED_OPEN_EXPORT_CREATE_MUTATION
  )

  const { error: exportError } = useQuery(CAMPAIGN_ENRICHED_EXPORT_QUERY, {
    variables: { id: exportId! },
    skip: !exportId,
    pollInterval:
      exportStatus === ExportReportStatus.Completed ||
      exportStatus === ExportReportStatus.Failed
        ? undefined
        : 1000,
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const campaignEnrichedExport = gqlMatchOptional(
        data?.node,
        exportType === "click"
          ? "CampaignEnrichedClickExport"
          : "CampaignEnrichedOpenExport"
      )
      invariant(
        campaignEnrichedExport,
        `expected campaign enriched ${exportType} export`
      )
      setExportStatus(campaignEnrichedExport.status)

      if (campaignEnrichedExport.status === ExportReportStatus.Completed) {
        invariant(
          campaignEnrichedExport.csvDownloadPath,
          "expected download path"
        )
        setCsvDownloadPath(campaignEnrichedExport.csvDownloadPath)
      }
    },
  })

  const handleExportReport = async (type: "click" | "open") => {
    const exportMutation =
      type === "click" ? exportClickReport : exportOpenReport
    const { data, errors } = await exportMutation({ variables: { campaignId } })
    if (errors) {
      toast({
        title: "Error",
        description: `Failed to export ${type} report. Please try again.`,
        variant: "destructive",
      })
    } else {
      invariant(data, "expected data")
      let exportId: string | undefined
      if (type === "click" && "campaignEnrichedClickExportCreate" in data) {
        exportId =
          data.campaignEnrichedClickExportCreate?.campaignEnrichedClickExport
            ?.id
      } else if (
        type === "open" &&
        "campaignEnrichedOpenExportCreate" in data
      ) {
        exportId =
          data.campaignEnrichedOpenExportCreate?.campaignEnrichedOpenExport?.id
      }

      invariant(exportId, `expected ${type} export id`)
      setExportId(exportId)
      setExportStatus(ExportReportStatus.Queued)
      setExportType(type)
    }
  }

  if (loading) return <LoadingIndicatorCentered />
  if (error) return <GraphqlError error={error} />

  const campaign = gqlMatchOptional(data?.campaign, "Campaign")
  const clicksStats = campaign?.cachedEnrichedClicksStats ?? []
  const opensStats = campaign?.cachedEnrichedOpensStats ?? []

  if (clicksStats.length === 0 && opensStats.length === 0) {
    return null
  }

  return (
    <div className="grid gap-6 border-t border-gray-d0 py-6 -mx-10 px-10">
      <Heading title="Enriched Reporting" />
      {clicksStats.length > 0 && (
        <Card className="bg-gray-f9">
          <CardHeader className="pb-2 flex">
            <div className="flex justify-between items-center mb-2">
              <Heading title="Clicks" className="" />
              <Button
                onClick={() => handleExportReport("click")}
                variant="cardControl"
                className="flex gap-2"
                size="sm"
                disabled={
                  exportingClick ||
                  exportStatus === ExportReportStatus.Processing
                }
              >
                <img {..._export} alt="" />
                {exportingClick ? "Initiating..." : "Export Full Report"}
              </Button>
            </div>
            <hr />
          </CardHeader>
          <CardContent>
            <Table layout="fixed">
              <TableHeader>
                <TableRow>
                  <TableHead className="w-[12rem]">Creator Brand</TableHead>
                  <TableHead>Newsletter</TableHead>
                  <TableHead className="w-[12rem]">Company Name</TableHead>
                  <TableHead className="w-[12rem]">Title</TableHead>
                  <TableHead>Seniority</TableHead>
                  <TableHead>Company Size</TableHead>
                  <TableHead>Clicks</TableHead>
                  <TableHead>Unique Clicks</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {clicksStats.map((stat) => (
                  <TableRow key={stat.id} className="bg-white">
                    <TableCell>{stat.creatorBrand}</TableCell>
                    <TableCell>{stat.newsletter}</TableCell>
                    <TableCell>{stat.companyName}</TableCell>
                    <TableCell>{stat.title}</TableCell>
                    <TableCell>{stat.seniority}</TableCell>
                    <TableCell>{stat.companySize}</TableCell>
                    <TableCell>{stat.realClicks}</TableCell>
                    <TableCell>{stat.uniqueRealClicks}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      )}
      {opensStats.length > 0 && (
        <Card className="bg-gray-f9">
          <CardHeader className="pb-2 flex">
            <div className="flex justify-between items-center mb-2">
              <Heading title="Opens" className="" />
              <Button
                onClick={() => handleExportReport("open")}
                variant="cardControl"
                className="flex gap-2"
                size="sm"
                disabled={
                  exportingOpen ||
                  exportStatus === ExportReportStatus.Processing
                }
              >
                <img {..._export} alt="" />
                {exportingOpen ? "Initiating..." : "Export Full Report"}
              </Button>
            </div>
            <hr />
          </CardHeader>
          <CardContent>
            <Table layout="fixed">
              <TableHeader>
                <TableRow>
                  <TableHead className="w-[12rem]">Creator Brand</TableHead>
                  <TableHead>Newsletter</TableHead>
                  <TableHead className="w-[12rem]">Title</TableHead>
                  <TableHead>Seniority</TableHead>
                  <TableHead>Company Size</TableHead>
                  <TableHead>Opens</TableHead>
                  <TableHead>Unique Opens</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {opensStats.map((stat) => (
                  <TableRow key={stat.id} className="bg-white">
                    {/* creator brand */}
                    <TableCell>{stat.listName}</TableCell>
                    {/* newsletter */}
                    <TableCell>{stat.name}</TableCell>
                    <TableCell>{stat.title}</TableCell>
                    <TableCell>{stat.seniority}</TableCell>
                    <TableCell>{stat.companySize}</TableCell>
                    <TableCell>{stat.realOpens}</TableCell>
                    <TableCell>{stat.uniqueRealOpens}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      )}
      <Dialog
        open={exportStatus != null}
        onOpenChange={(open) => {
          if (!open) {
            setExportStatus(null)
            setExportId(null)
            setCsvDownloadPath(null)
          }
        }}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Export Status</DialogTitle>
          </DialogHeader>
          <div className="py-4">
            {exportError ? (
              <div className="overflow-auto max-w-full max-h-[500px]">
                <GraphqlError error={exportError} />
              </div>
            ) : null}
            {exportStatus === ExportReportStatus.Queued && (
              <p>Export is queued…</p>
            )}
            {exportStatus === ExportReportStatus.Processing && (
              <p>Export is being generated…</p>
            )}
            {exportStatus === ExportReportStatus.Completed && (
              <div>
                <div className="mb-2">Export is ready! </div>
                <a
                  href={csvDownloadPath || "#"}
                  className={buttonVariants({ variant: "default" })}
                  download
                >
                  Download CSV
                </a>
              </div>
            )}
            {exportStatus === ExportReportStatus.Failed && (
              <p>Export failed. Please try again.</p>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}
