import React from "react"
import Imgix from "react-imgix"
import { useLocation, useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import { Role } from "~/__generated__/graphql"
import { useViewer } from "~/auth/viewer-context"
import { cn } from "~/common/cn"
import { formatDate } from "~/common/date-formatting"
import {
  companyProductsDetailUploadPath,
  companyProductsEditPath,
  companyProductsEditUploadPath,
} from "~/common/paths"
import { numberToHumanSize } from "~/common/size-formatting"
import { useSafeMutation } from "~/common/use-safe-mutation"
import upload from "~/images/icons/upload"
import xIcon from "~/images/icons/x"
import pdf from "~/images/pdf"
import { Button } from "~/ui/button"
import { LinkButton } from "~/ui/link-button"
import Text from "~/ui/typography"
import { useToast } from "~/ui/use-toast"

interface ContentReferencesProps {
  contentReferences?: {
    edges?: Array<{
      node: any
    }>
  }
  isEditing?: boolean
  disableUpload?: boolean
}

const destroyQuery = gql(/* GraphQL */ `
  mutation destroyContentReference($input: ContentReferenceDestroyInput!) {
    contentReferenceDestroy(input: $input) {
      contentReference {
        id
        isDestroyed
      }
    }
  }
`)

export const ContentReferences: React.FC<ContentReferencesProps> = ({
  contentReferences,
  isEditing = false,
  disableUpload = false,
}) => {
  const [execDestroy] = useSafeMutation(destroyQuery)
  const { toast } = useToast()
  const params = useParams()
  const { pathname } = useLocation()
  const { viewer } = useViewer()

  const handleDestroy = async (id: string) => {
    const result = await execDestroy({
      variables: {
        input: {
          id,
        },
      },
    })

    if (!result.errors) {
      toast({
        title: "Content reference deleted",
        description: "The content reference has been deleted",
        variant: "default",
      })
    }
  }

  invariant(params.productId, "Expected productId to be defined")
  invariant(params.slug, "Expected slug to be defined")

  const isEditPage =
    pathname ===
    companyProductsEditPath({
      slug: params.slug,
      productId: params.productId,
    })

  const canUpload =
    !disableUpload &&
    (viewer.role === Role.Client ||
      viewer.role === Role.WorkweekAdmin ||
      viewer.role === Role.WorkweekTeam)

  return (
    <>
      <div className="flex items-center justify-between">
        <Text variant="body-18-medium">Content References</Text>
        {canUpload && (
          <LinkButton
            variant="cardControl"
            size="cardControl"
            className="flex items-center gap-2 py-1 px-2"
            to={
              isEditPage
                ? companyProductsEditUploadPath({
                    slug: params.slug,
                    productId: params.productId,
                  })
                : companyProductsDetailUploadPath({
                    slug: params.slug,
                    productId: params.productId,
                  })
            }
          >
            Upload Content
            <img {...upload} alt="" className="inline-block align-baseline" />
          </LinkButton>
        )}
      </div>

      <div className="mt-8 grid gap-2">
        {contentReferences?.edges
          ?.filter((edge) => !edge.node.isDestroyed)
          .map((edge) => (
            <div
              key={edge.node.id}
              className={cn({
                "border rounded-lg p-4 bg-gray-f9 flex items-center justify-between":
                  isEditing,
              })}
            >
              {isEditing ? (
                <ContentReferenceElement
                  name={edge.node.name}
                  fileExtension={edge.node.fileExtension}
                  byteSize={edge.node.byteSize}
                  imgUrl={
                    edge?.node?.contentType?.startsWith("image/")
                      ? (edge.node.contentReferenceLink as string)
                      : undefined
                  }
                  publishDate={edge.node.publishDate}
                />
              ) : (
                <a
                  href={edge.node.contentReferenceLink as string}
                  target="_blank"
                  rel="noreferrer"
                  className="border hover:border-gray-400 rounded-lg p-4 bg-gray-f9 flex items-center justify-between"
                >
                  <ContentReferenceElement
                    name={edge.node.name}
                    fileExtension={edge.node.fileExtension}
                    byteSize={edge.node.byteSize}
                    imgUrl={
                      edge?.node?.contentType?.startsWith("image/")
                        ? (edge.node.contentReferenceLink as string)
                        : undefined
                    }
                    publishDate={edge.node.publishDate}
                  />
                </a>
              )}
              <div className="flex items-center">
                {isEditing && (
                  <Button
                    className="rounded-full p-1 border w-4 h-4"
                    variant="ghost"
                    onClick={() => handleDestroy(edge.node.id)}
                  >
                    <img
                      {...xIcon}
                      alt="Remove"
                      className="inline-block align-baseline"
                    />
                  </Button>
                )}
              </div>
            </div>
          ))}
      </div>
    </>
  )
}

export const ContentReferenceElement: React.FC<{
  name: string
  fileExtension?: string | undefined
  byteSize?: number | undefined
  imgUrl?: string
  publishDate?: string
}> = ({ name, fileExtension, byteSize, imgUrl, publishDate }) => {
  const isPdf = fileExtension?.endsWith("pdf")
  const showImage = imgUrl || isPdf
  return (
    <div className="flex items-center gap-4 overflow-hidden">
      {showImage && (
        <div className="w-10 flex items-center justify-center">
          {imgUrl && (
            <Imgix
              src={imgUrl}
              width={40}
              height={40}
              className="rounded-full"
              imgixParams={{
                fit: "crop",
              }}
            />
          )}
          {isPdf && (
            <img {...pdf} alt="" className="inline-block align-baseline" />
          )}
        </div>
      )}
      <div className="flex flex-col overflow-hidden">
        <Text as="div" variant="body-14-medium" className="truncate">
          {name}
        </Text>
        {fileExtension && (
          <Text as="div" variant="body-12" className="text-gray-500">
            {fileExtension.toUpperCase()} document
            {byteSize && (
              <span className="ml-1">- {numberToHumanSize(byteSize)}</span>
            )}
            {publishDate && (
              <span className="ml-1">- {formatDate(publishDate)}</span>
            )}
          </Text>
        )}
      </div>
    </div>
  )
}
