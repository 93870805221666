import { useEffect, useState } from "react"
import { cn } from "~/common/cn"
import LoaderIcon from "~/images/icons/loader.svg?react"

type LoadingIndicatorProps = {
  className?: string
  size?: "small"
}

export const LoadingIndicator = (props: LoadingIndicatorProps) => (
  <div role="status">
    <div aria-hidden="true" className={cn("animate-spin", props.className)}>
      <LoaderIcon />
    </div>
    <span className="sr-only">Loading...</span>
  </div>
)

export const LoadingIndicatorCentered = () => (
  <div className="flex flex-1 justify-center items-center">
    <FadeIn>
      <LoadingIndicator />
    </FadeIn>
  </div>
)

export const FadeIn = ({ children }: { children: React.ReactNode }) => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsVisible(true)
    }, 500)
    return () => clearTimeout(timeoutId)
  }, [])

  return (
    <div
      className={`transition-opacity duration-1000  ${
        isVisible ? "opacity-100" : "opacity-0"
      }`}
    >
      {children}
    </div>
  )
}
