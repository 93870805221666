export const PendingLabel = ({
  isPending,
  isEditing,
}: {
  isPending: boolean
  isEditing: boolean
}) => {
  return isPending && !isEditing ? (
    <div className="italic  text-gray-500">Pending</div>
  ) : null
}
