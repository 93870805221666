import { useEffect, useRef, useState } from "react"
import { Link, LinkProps } from "react-router-dom"
import { cn } from "~/common/cn"
import DropdownArrow from "~/images/dropdown-arrow.svg?react"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuContentProps,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "~/ui/dropdown-menu"

interface StyledDropdownInnerProps
  extends React.ComponentPropsWithoutRef<"div"> {
  icon?: React.ReactNode
  title: string
}

export const StyledDropdownInner = ({
  icon,
  title,
  className,
  ...props
}: StyledDropdownInnerProps) => {
  return (
    <div
      className={cn(
        "flex items-center gap-[10px] w-full text-dark-gray rounded-lg pr-4 text-xs-plus py-1",
        className,
        icon ? "pl-3" : "pl-4"
      )}
      {...props}
    >
      {icon && (
        <div
          style={{ width: 16, height: 16 }}
          className="flex justify-center items-center text-primary group-hover:text-white"
        >
          {icon}
        </div>
      )}
      {title}
    </div>
  )
}

export const StyledDropdownItemWrapper = ({
  asChild = false,
  children,
}: {
  asChild?: boolean
  children: React.ReactNode
}) => {
  return (
    <DropdownMenuItem
      className={cn(
        "group !p-0 !cursor-pointer hover:!bg-black !rounded hover:!text-white !font-normal"
      )}
      asChild={asChild}
    >
      {children}
    </DropdownMenuItem>
  )
}

export const StyledDropdownItem = ({ ...props }: StyledDropdownInnerProps) => {
  return (
    <StyledDropdownItemWrapper>
      <StyledDropdownInner {...props} />
    </StyledDropdownItemWrapper>
  )
}

interface StyledDropdownLinkProps extends StyledDropdownInnerProps {
  linkProps: LinkProps
}

export const StyledDropdownLink = ({
  linkProps,
  ...props
}: StyledDropdownLinkProps) => {
  return (
    <StyledDropdownItemWrapper asChild>
      <Link className="w-full" {...linkProps}>
        <StyledDropdownInner {...props} />
      </Link>
    </StyledDropdownItemWrapper>
  )
}

interface StyledDropdownProps
  extends React.ComponentPropsWithoutRef<typeof DropdownMenu> {
  trigger: React.ReactElement
  contentProps?: DropdownMenuContentProps
  children: React.ReactNode
  className?: string
}

export const StyledDropdown = ({
  trigger,
  contentProps,
  children,
  className = "",
  ...props
}: StyledDropdownProps) => {
  const triggerRef = useRef<HTMLButtonElement>(null)
  const [arrowOffset, setArrowOffset] = useState<number>(0)

  useEffect(() => {
    setArrowOffset(Math.floor((triggerRef.current?.offsetWidth || 0) / 2) + 4)
  }, [triggerRef, triggerRef.current?.offsetWidth])

  return (
    <DropdownMenu {...props}>
      <DropdownMenuTrigger ref={triggerRef} className="shrink-0">
        {trigger}
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="end"
        avoidCollisions={false}
        sideOffset={10}
        alignOffset={-10}
        onCloseAutoFocus={(e) => e.preventDefault()}
        className={cn("!animate-none p-2 flex flex-col", className)}
        {...contentProps}
      >
        {children}
        <DropdownArrow
          style={{ right: arrowOffset }}
          className="absolute top-[-7px]"
        />
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
