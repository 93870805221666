import type { TooltipContentProps } from "@radix-ui/react-tooltip"
import { forwardRef } from "react"
import { cn } from "~/common/cn"
import { Toggle } from "~/ui/toggle"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/ui/tooltip"

interface ToolbarButtonProps
  extends React.ComponentPropsWithoutRef<typeof Toggle> {
  isActive?: boolean
  tooltip?: string
  tooltipOptions?: TooltipContentProps
  buttonSize?: "sm" | "default"
}

const ToolbarButton = forwardRef<HTMLButtonElement, ToolbarButtonProps>(
  function ToolbarButton(
    {
      isActive,
      children,
      tooltip,
      className,
      tooltipOptions,
      buttonSize,
      ...props
    },
    ref
  ) {
    return (
      <TooltipProvider delayDuration={0}>
        <Tooltip>
          <TooltipTrigger asChild>
            <Toggle
              size={buttonSize === "sm" ? "xs" : "sm"}
              {...props}
              ref={ref}
              className={cn(
                "rounded disabled:pointer-events-auto disabled:cursor-not-allowed disabled:text-muted-foreground disabled:hover:bg-transparent data-[state=open]:bg-primary/10 data-[state=open]:text-primary",
                {
                  "bg-primary/10 text-primary hover:bg-primary/10 hover:text-primary":
                    isActive,
                },
                className
              )}
            >
              {children}
            </Toggle>
          </TooltipTrigger>
          {tooltip && (
            <TooltipContent {...tooltipOptions}>
              <div className="flex flex-col items-center text-center">
                {tooltip}
              </div>
            </TooltipContent>
          )}
        </Tooltip>
      </TooltipProvider>
    )
  }
)

ToolbarButton.displayName = "ToolbarButton"

export { ToolbarButton }
