import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/ui/form"
import { Control, FieldPath, FieldValues } from "react-hook-form"
import { InputHTMLAttributes } from "react"
import { cn } from "~/common/cn"
import { Checkbox } from "../ui/checkbox"

export const CheckboxField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  description,
  label,
  labelClassName,
  inputClassName,
  checked,
  onChange,
  ...inputProps
}: {
  label: string
  labelClassName?: string
  inputClassName?: string
  description?: string
  checked?: (value: boolean) => boolean | undefined
  onChange?: (value: boolean) => boolean
  control: Control<TFieldValues> | undefined
  name: TName
} & InputHTMLAttributes<HTMLInputElement>) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className="flex items-center gap-2">
          <FormControl>
            <Checkbox
              className="peer h-4 w-4"
              checked={field.value}
              onCheckedChange={(checked) => {
                field.onChange(checked)
              }}
            />
          </FormControl>
          <FormLabel className={cn(labelClassName, "font-normal !mt-0")}>
            {label}
          </FormLabel>
          <FormMessage className="font-normal text-xs" />
        </FormItem>
      )}
    />
  )
}
