import { useLazyQuery } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import { useCallback, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { useNavigate, useParams } from "react-router-dom"
import AsyncSelect from "react-select/async"
import invariant from "tiny-invariant"
import { useDebouncedCallback } from "use-debounce"
import { z } from "zod"
import { gql } from "~/__generated__"
import { Role, UsersQueryScopeEnum, UserStatus } from "~/__generated__/graphql"
import { useViewer } from "~/auth/viewer-context"
import { companyRoomPath, roomPath } from "~/common/paths"
import { useSafeMutation } from "~/common/use-safe-mutation"
import { displayErrors } from "~/common/validations"
import { MENTION_USER_QUERY_DOCUMENT } from "~/post-composer/useMentionDataSource"
import { Button } from "~/ui/button"
import { Dialog, DialogContent } from "~/ui/dialog"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/ui/form"
import { UserPill } from "~/ui/UserPill"

export const MAX_GROUP_MEMBERS = 20
const MAX_INVITABLE = MAX_GROUP_MEMBERS - 1

type UserOption = {
  value: string
  label: string
  data: {
    id: string
    name: string
    firstName: string
    lastName: string
    photoUrl?: string
    role: string
    companies: string[]
  }
}

const formSchema = z.object({
  name: z.string(),
  selectedUsers: z
    .array(
      z.object({
        id: z.string(),
        name: z.string(),
        firstName: z.string(),
        lastName: z.string(),
        photoUrl: z.string().optional(),
        role: z.string(),
      })
    )
    .min(1, { message: "Please select at least 1 person" })
    .max(MAX_INVITABLE, {
      message: `You may only invite a max of ${MAX_INVITABLE} people`,
    }),
})

export type FormValues = z.infer<typeof formSchema>

export const StartRoomModal = ({
  isOpen,
  setIsOpen,
  onSuccess,
}: {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  onSuccess: () => void
}) => {
  const { viewer } = useViewer()
  const isClient = viewer?.role === Role.Client
  const params = useParams()
  const [scopeCompanies, setScopeCompanies] = useState<string[] | null>(null)
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      selectedUsers: [],
    },
  })
  const navigate = useNavigate()
  const selectedUsers = form.watch("selectedUsers")

  useEffect(() => {
    if (selectedUsers.filter((u) => u.role === "CLIENT").length === 0) {
      setScopeCompanies([])
    }
  }, [selectedUsers])

  const [getCollaboratorSearch] = useLazyQuery(MENTION_USER_QUERY_DOCUMENT, {
    variables: {
      excludeSelf: true,
      statuses: [UserStatus.Active],
      roles: [Role.Client, Role.WorkweekAdmin, Role.WorkweekTeam],
      companies: scopeCompanies,
      queryScope: UsersQueryScopeEnum.Messaging,
    },
  })

  const [runMutation, mutationResult] = useSafeMutation(ROOM_CREATE_MUTATION)

  const onSubmit = async (values: FormValues) => {
    const { data, errors } = await runMutation({
      variables: {
        input: {
          name: values.name,
          userIds: values.selectedUsers.map((u) => u.id),
        },
      },
    })
    if (errors) {
      displayErrors(errors)
    } else {
      invariant(data)
      toast.success("Group chat created")
      setIsOpen(false)
      if (isClient && params.slug) {
        navigate(
          companyRoomPath({
            slug: params.slug,
            roomId: data.roomCreate.room.id,
          })
        )
      } else {
        navigate(roomPath({ roomId: data.roomCreate.room.id }))
      }
      onSuccess()
    }
  }

  useEffect(() => {
    if (isOpen === false) form.reset()
  }, [isOpen, form])

  const _loadOptions = useCallback(
    (query: string, callback: (options: UserOption[]) => void) => {
      getCollaboratorSearch({
        variables: { query },
      }).then(({ data }) => {
        if (!data) {
          callback([])
        } else {
          callback(
            data.users.nodes.map((u) => ({
              value: u.id,
              label: u.name || "",
              data: {
                id: u.id,
                name: u.name || "",
                firstName: u.firstName || "",
                lastName: u.lastName || "",
                photoUrl: u.photoUrl || undefined,
                role: u.role,
                companies: u.companies.edges.map((e) => e.node.id),
              },
            }))
          )
        }
      })
    },
    [getCollaboratorSearch]
  )
  const loadOptions = useDebouncedCallback(_loadOptions, 400)

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(value) => {
        setIsOpen(value)
      }}
    >
      <DialogContent className="w-2/3 max-w-xl gap-0">
        <div className="mb-6 text-center font-medium text-xl">
          Create a Group
        </div>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="flex flex-col mb-4 gap-6">
              <FormField
                control={form.control}
                name="selectedUsers"
                render={() => (
                  <FormItem>
                    <FormLabel required className="block">
                      Search members
                    </FormLabel>
                    <FormControl>
                      <AsyncSelect
                        classNamePrefix="select"
                        className="flex-1"
                        placeholder="Who are you messaging..."
                        loadOptions={loadOptions}
                        value={null}
                        onChange={(selection) => {
                          if (selection?.value) {
                            if (selection.data.role === "CLIENT") {
                              setScopeCompanies(selection.data.companies)
                            }
                            form.setValue("selectedUsers", [
                              selection.data,
                              ...form.getValues()["selectedUsers"],
                            ])
                          }
                        }}
                        styles={{
                          control: (baseStyles, _state) => ({
                            ...baseStyles,
                            outline: "none !important",
                            boxShadow: "none",
                            paddingTop: "0.25rem",
                            paddingBottom: "0.25rem",
                            paddingLeft: "0.5rem",
                            paddingRight: "0.5rem",
                            fontSize: 16,
                            borderRadius: 8,
                          }),
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className="flex flex-wrap gap-3">
                {form.watch("selectedUsers").map((user) => (
                  <UserPill
                    user={user}
                    key={user.id}
                    canRemove
                    onRemove={() => {
                      form.setValue(
                        "selectedUsers",
                        form
                          .getValues("selectedUsers")
                          .filter((item) => item.id !== user.id)
                      )
                    }}
                  />
                ))}
              </div>

              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="block">
                      Group name (optional)
                    </FormLabel>
                    <FormControl>
                      <input
                        type="text"
                        className="bg-white w-full border-mercury rounded-lg py-2 px-4"
                        placeholder="Thursday Night HR Team"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="h-[1px] bg-gray-300 my-8" />

            <div className="flex justify-end gap-4">
              <Button
                type="button"
                variant="outline"
                className="px-10"
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="px-10"
                isLoading={mutationResult.loading}
                disabled={mutationResult.loading}
              >
                Create Group
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

const ROOM_CREATE_MUTATION = gql(`
  mutation RoomCreateModal($input: RoomCreateInput!) {
    roomCreate(input: $input) {
      room {
        id
        users {
          id
        }
      }
    }
  }
`)
