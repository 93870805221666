import { useQuery } from "@apollo/client"
import { CollapsibleContent } from "@radix-ui/react-collapsible"
import { useLocalStorage } from "@rehooks/local-storage"
import { PropsWithChildren } from "react"
import { Link, useNavigate, useOutlet, useParams } from "react-router-dom"
import { Params } from "static-path"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import {
  CampaignDeliverableCardFragment,
  CampaignDeliverableStatus,
  Role,
} from "~/__generated__/graphql"
import { useViewer } from "~/auth/viewer-context"
import { formatCurrency } from "~/common/currency-formatting"
import { formatDate } from "~/common/date-formatting"
import { gqlMatchOptional } from "~/common/gql-match"
import { NotFoundScreen } from "~/common/not-found-screen"
import * as paths from "~/common/paths"
import { useDocumentTitle } from "~/common/use-document-title"
import { useGoBack } from "~/common/use-go-back"
import { CompanyLogo } from "~/companies/company-logo"
import arrowDown from "~/images/icons/arrow-down"
import barChart2 from "~/images/icons/bar-chart-2"
import videoPlay from "~/images/icons/video-play"
import { TablePageLayout } from "~/layouts/table-page-layout"
import { Button, buttonVariants } from "~/ui/button"
import { Collapsible, CollapsibleTrigger } from "~/ui/collapsible"
import { Empty } from "~/ui/empty"
import { GraphqlError } from "~/ui/errors"
import { ButtonLink } from "~/ui/link"
import { LinkButton } from "~/ui/link-button"
import { LoadingIndicatorCentered } from "~/ui/loading-indicator"
import { Percent, calculatePercent } from "~/ui/percent"
import { Progress } from "~/ui/progress"
import Text from "~/ui/typography"
import { CampaignDeliverableProduct } from "./campaign-deliverable-product"
import { CampaignDeliverablePublishDate } from "./campaign-deliverable-publish-date"
import { CampaignDeliverableStatusLabel } from "./campaign-deliverable-status"
import { GoBack } from "./go-back"
import { Section, SectionLabel } from "./section"
import { SectionCounter } from "./section-counter"
import { Subsection } from "./subsection"
import { SubsectionBody } from "./subsection-body"
import { SubsectionTitle } from "./subsection-title"
import { CampaignUserSection, CampaignUserSectionProps } from "./user-section"

gql(/* GraphQL */ `
  fragment CampaignDetailPaneUser on User {
    id
    fullName
    role
    ...UserAvatar
  }
`)

gql(/* GraphQL */ `
  fragment CampaignDetailPaneFragment on Campaign {
    id
    campaignName
    campaignDeliverablesCount
    campaignDeliverablesCompletedCount
    adsGoals
    closeDate
    amount
    salesforceGoals
    kpis
    whyTheseSpecificCreators
    customElementsSpecialConsiderations
    mediaPlanLink

    campaignDeliverables(first: 100) {
      edges {
        node {
          id
          status
        }
      }
    }

    company {
      id
      name
      slug
      ...CompanyLogo
    }
    accountManager {
      ...CampaignDetailPaneUser
    }
    client {
      ...CampaignDetailPaneUser
    }
    salesRep {
      ...CampaignDetailPaneUser
    }
    creatives(first: 100) {
      edges {
        node {
          ...CampaignDetailPaneUser
        }
      }
    }
    creators(first: 100) {
      edges {
        node {
          ...CampaignDetailPaneUser
        }
      }
    }

    canUpdate {
      value
    }
  }
`)

const query = gql(/* GraphQL */ `
  query CampaignDetailPaneQuery($id: ID!) {
    node(id: $id) {
      __typename
      ... on Campaign {
        ...CampaignDetailPaneFragment
      }
    }
  }
`)

export const CampaignDetailScreen = () => {
  const params = useParams() as Params<typeof paths.campaignDetailPath.pattern>
  const navigate = useNavigate()
  const { viewer } = useViewer()

  const result = useQuery(query, { variables: { id: params.campaignId } })

  const campaign = gqlMatchOptional(result.data?.node, "Campaign")
  useDocumentTitle(campaign?.campaignName ?? null)

  const goBack = useGoBack(paths.campaignsPath({}))

  const outlet = useOutlet()

  const linkTo: CampaignUserSectionProps["linkTo"] = (user) =>
    paths.campaignDetailUserPath({
      campaignId: params.campaignId,
      userId: user.id,
    })

  const [uiState, setUiState] = useLocalStorage(
    `campaignDetailPane::${params.campaignId}`,
    {
      goalsOpen: true,
      internalNotesOpen: true,
    }
  )

  const canSeeInternalNotes = viewer?.role !== Role.Client

  return (
    <>
      <GoBack onClick={goBack} />

      <TablePageLayout padding={false} rightSideSlot={outlet}>
        <div className="flex flex-col flex-1">
          {result.error ? <GraphqlError error={result.error} /> : null}
          {campaign != null && (
            <div className="flex flex-1 flex-col">
              <div className="grid grid-cols-[minmax(475px,1fr)_475px] p-[40px] gap-[40px] items-start overflow-auto">
                <div>
                  <div className="flex flex-col bg-gray-f9 rounded-lg mb-8">
                    <div className="flex justify-between">
                      <div className="p-8">
                        <CompanyLogo company={campaign.company} size="32" />
                      </div>

                      <div className="p-4 self-start flex gap-2">
                        {/* <Button variant="cardControl" size="cardControl"> <img {...dashboardAlt} alt="" className="me-1" /> Reporting dashboard </Button> */}
                        {campaign.campaignDeliverables.edges.some(
                          (deliverable) =>
                            deliverable.node.status ===
                            CampaignDeliverableStatus.Published
                        ) && (
                          <LinkButton
                            variant="cardControl"
                            size="cardControl"
                            to={paths.campaignDetailMetricsPath({
                              campaignId: params.campaignId,
                            })}
                          >
                            <img {...barChart2} alt="" className="me-1" />{" "}
                            Metrics
                          </LinkButton>
                        )}
                      </div>
                    </div>
                    <div className="px-8 pb-8">
                      <Text as="div" variant="title-24">
                        {campaign.campaignName}
                      </Text>
                      <div className="flex items-center gap-8">
                        <div className="flex-shrink-0">
                          <Text variant="body-14-medium">
                            <Percent
                              completed={
                                campaign.campaignDeliverablesCompletedCount
                              }
                              total={campaign.campaignDeliverablesCount}
                              zero="0%"
                            />
                          </Text>{" "}
                          <Text variant="body-14">Complete</Text>
                        </div>
                        <Progress
                          value={calculatePercent(
                            campaign.campaignDeliverablesCompletedCount,
                            campaign.campaignDeliverablesCount
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <Section className="mb-6">
                    <div className="flex gap-3">
                      <SectionLabel>Campaign Deliverables</SectionLabel>
                      <SectionCounter
                        value={campaign.campaignDeliverablesCount}
                      />
                    </div>
                  </Section>
                  <CampaignDeliverables campaignId={campaign.id} />
                </div>
                <div className="grid">
                  <SideSection>
                    <Collapsible
                      open={uiState.goalsOpen}
                      onOpenChange={(open) =>
                        setUiState({ ...uiState, goalsOpen: open })
                      }
                    >
                      <div className="flex justify-between">
                        <div className="flex justify-between items-center gap-2">
                          <CollapsibleTrigger asChild>
                            <ButtonLink
                              variant="unstyled"
                              className="flex items-center gap-1"
                            >
                              <SideSectionLabel>
                                Campaign Goals
                              </SideSectionLabel>
                              {!uiState.goalsOpen && (
                                <img
                                  {...arrowDown}
                                  alt=""
                                  className="w-3 h-3 -rotate-90"
                                />
                              )}
                              {uiState.goalsOpen && (
                                <img
                                  {...arrowDown}
                                  alt=""
                                  className="w-3 h-3"
                                />
                              )}
                            </ButtonLink>
                          </CollapsibleTrigger>
                        </div>

                        {campaign.canUpdate.value ? (
                          <ButtonLink
                            onClick={() =>
                              navigate(
                                paths.campaignDetailGoalsPath({
                                  campaignId: params.campaignId,
                                })
                              )
                            }
                            variant="close-pane"
                          >
                            Edit Goals
                          </ButtonLink>
                        ) : null}
                      </div>

                      <CollapsibleContent>
                        <div className="mt-4">
                          {campaign.adsGoals.length > 0 ? (
                            <Text
                              as="ol"
                              variant="body-14-medium"
                              className="list-decimal pl-6 "
                            >
                              {campaign.adsGoals.map((goal, index) => (
                                <li key={index} className="whitespace-pre-wrap">
                                  {goal}
                                </li>
                              ))}
                            </Text>
                          ) : (
                            <div className="text-center pt-6 pb-4">
                              <div className="text-2xl mb-2">No Goals</div>
                              <Text
                                as="div"
                                variant="body-14"
                                className="max-w-[154px] mx-auto"
                              >
                                <ButtonLink
                                  onClick={() =>
                                    navigate(
                                      paths.campaignDetailGoalsPath({
                                        campaignId: params.campaignId,
                                      })
                                    )
                                  }
                                >
                                  Add Campaign Goals
                                </ButtonLink>{" "}
                                to see them here
                              </Text>
                            </div>
                          )}
                        </div>
                      </CollapsibleContent>
                    </Collapsible>
                  </SideSection>

                  {canSeeInternalNotes && (
                    <SideSection>
                      <Collapsible
                        open={uiState.internalNotesOpen}
                        onOpenChange={(open) =>
                          setUiState({ ...uiState, internalNotesOpen: open })
                        }
                      >
                        <div className="flex justify-between">
                          <CollapsibleTrigger asChild>
                            <ButtonLink
                              variant="unstyled"
                              className="flex items-center gap-1"
                            >
                              <SideSectionLabel>
                                Internal Notes
                              </SideSectionLabel>
                              {!uiState.internalNotesOpen && (
                                <img
                                  {...arrowDown}
                                  alt=""
                                  className="w-3 h-3 -rotate-90"
                                />
                              )}
                              {uiState.internalNotesOpen && (
                                <img
                                  {...arrowDown}
                                  alt=""
                                  className="w-3 h-3"
                                />
                              )}
                            </ButtonLink>
                          </CollapsibleTrigger>
                        </div>
                        <CollapsibleContent>
                          <div className="grid gap-4 pt-4">
                            <Subsection>
                              <SubsectionTitle>Close Date</SubsectionTitle>
                              <SubsectionBody>
                                {campaign.closeDate ? (
                                  formatDate(campaign.closeDate)
                                ) : (
                                  <Empty />
                                )}
                              </SubsectionBody>
                            </Subsection>
                            <Subsection>
                              <SubsectionTitle>Amount</SubsectionTitle>
                              <SubsectionBody>
                                {campaign.amount ? (
                                  formatCurrency(campaign.amount)
                                ) : (
                                  <Empty />
                                )}
                              </SubsectionBody>
                            </Subsection>
                            <Subsection>
                              <SubsectionTitle>Campaign Goals</SubsectionTitle>
                              <SubsectionBody>
                                {campaign.salesforceGoals ? (
                                  <Text wrapping="wrap">
                                    {campaign.salesforceGoals}
                                  </Text>
                                ) : (
                                  <Empty />
                                )}
                              </SubsectionBody>
                            </Subsection>
                            <Subsection>
                              <SubsectionTitle>
                                Why these specific creators?
                              </SubsectionTitle>
                              <SubsectionBody>
                                {campaign.whyTheseSpecificCreators ? (
                                  <Text wrapping="wrap">
                                    {campaign.whyTheseSpecificCreators}
                                  </Text>
                                ) : (
                                  <Empty />
                                )}
                              </SubsectionBody>
                            </Subsection>
                            <Subsection>
                              <SubsectionTitle>Custom Elements</SubsectionTitle>
                              <SubsectionBody>
                                {campaign.customElementsSpecialConsiderations ? (
                                  <Text wrapping="wrap">
                                    {
                                      campaign.customElementsSpecialConsiderations
                                    }
                                  </Text>
                                ) : (
                                  <Empty />
                                )}
                              </SubsectionBody>
                            </Subsection>
                            <div>
                              {campaign.mediaPlanLink ? (
                                <a
                                  href={campaign.mediaPlanLink}
                                  target="_blank"
                                  className={buttonVariants({
                                    className: "flex",
                                  })}
                                  rel="noreferrer"
                                >
                                  <img {...videoPlay} alt="" className="me-2" />
                                  Media Plan
                                </a>
                              ) : null}
                            </div>
                          </div>
                        </CollapsibleContent>
                      </Collapsible>
                    </SideSection>
                  )}
                  <Section className="grid gap-4">
                    <CampaignUserSection
                      title="Sales Representatives"
                      users={campaign.salesRep ? [campaign.salesRep] : []}
                      linkTo={linkTo}
                    />
                    <CampaignUserSection
                      title="Account Manager"
                      users={
                        campaign.accountManager ? [campaign.accountManager] : []
                      }
                      linkTo={linkTo}
                    />
                    <CampaignUserSection
                      title="Client"
                      users={campaign.client ? [campaign.client] : []}
                      linkTo={linkTo}
                    />
                    <CampaignUserSection
                      title="Creatives"
                      users={campaign.creatives.edges.map((edge) => edge.node)}
                      linkTo={linkTo}
                    />
                    <CampaignUserSection
                      title="Creators"
                      users={campaign.creators.edges.map((edge) => edge.node)}
                      linkTo={linkTo}
                    />
                  </Section>
                </div>
              </div>
            </div>
          )}
          {!campaign && !result.loading && (
            <NotFoundScreen title="Campaign not found" />
          )}
        </div>
      </TablePageLayout>
    </>
  )
}

const campaignDeliverablesQuery = gql(/* GraphQL */ `
  query CampaignDeliverablesQuery($campaignId: ID!, $after: String) {
    node(id: $campaignId) {
      __typename
      ... on Campaign {
        id
        campaignDeliverables(first: 100, after: $after) {
          pageInfo {
            ...Pagination
          }
          edges {
            node {
              id
              ...CampaignDeliverableCard
            }
          }
        }
      }
    }
  }
`)

const CampaignDeliverables: React.FC<{ campaignId: string }> = ({
  campaignId,
}) => {
  const result = useQuery(campaignDeliverablesQuery, {
    variables: { campaignId, after: null },
  })

  if (result.error) {
    return <GraphqlError error={result.error} />
  }
  if (result.loading) {
    return <LoadingIndicatorCentered />
  }

  const campaign = gqlMatchOptional(
    result.data?.node ?? result.previousData?.node,
    "Campaign"
  )
  invariant(campaign, "Expected campaign")

  const campaignDeliverables = campaign.campaignDeliverables.edges.map(
    (edge) => edge.node
  )

  if (campaignDeliverables.length === 0) {
    return <Empty message="No deliverables" />
  }

  return (
    <ul>
      {campaignDeliverables.map((deliverable) => (
        <li key={deliverable.id} className="mb-2">
          <CampaignDeliverableCard
            campaignId={campaign.id}
            campaignDeliverable={deliverable}
          />
        </li>
      ))}
      {campaign.campaignDeliverables.pageInfo.hasNextPage ? (
        <Button
          variant="outline"
          onClick={() => {
            result.fetchMore({
              variables: {
                after: campaign.campaignDeliverables.pageInfo.endCursor,
              },
            })
          }}
        >
          Load more
        </Button>
      ) : null}
    </ul>
  )
}

gql(/* GraphQL */ `
  fragment CampaignDeliverableCard on CampaignDeliverable {
    id
    deliverableName
    publishDateCanonical
    status
    productBrief {
      id
      name
      company {
        slug
      }
    }
  }
`)

const CampaignDeliverableCard: React.FC<{
  campaignId: string
  campaignDeliverable: CampaignDeliverableCardFragment
}> = ({ campaignId, campaignDeliverable }) => {
  return (
    <div className="border border-gray-d0 p-6 grid grid-cols-[1fr,auto] gap-4 rounded-lg shadow">
      <div>
        <Link
          to={paths.campaignDeliverablePath({
            campaignId: campaignId,
            deliverableId: campaignDeliverable.id,
          })}
        >
          <Text as="div" variant="body-14-medium" className="mb-4">
            {campaignDeliverable.deliverableName}
          </Text>
          <Text as="div" variant="body-12">
            <span className="text-gray-99">Product:</span>{" "}
            <CampaignDeliverableProduct {...campaignDeliverable} />
          </Text>
          <Text as="div" variant="body-12">
            <span className="text-gray-99">Publish Date:</span>{" "}
            <CampaignDeliverablePublishDate {...campaignDeliverable} />
          </Text>
        </Link>
      </div>

      <div>
        <div className="border border-gray-d0 rounded px-2 py-1">
          <Text variant="body-14">
            <CampaignDeliverableStatusLabel {...campaignDeliverable} />
          </Text>
        </div>
      </div>
    </div>
  )
}

const SideSection: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="border-b pb-[40px] mb-[40px] flex flex-col">{children}</div>
  )
}

const SideSectionLabel: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Text as="div" variant="mini-caps">
      {children}
    </Text>
  )
}
