import { TextareaHTMLAttributes } from "react"
import { Control, FieldPath, FieldValues } from "react-hook-form"
import { cn } from "~/common/cn"
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/ui/form"

export const TextareaField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  description,
  label,
  labelClassName,
  inputClassName,
  ...inputProps
}: {
  label: string
  labelClassName?: string
  inputClassName?: string
  description?: string
  control: Control<TFieldValues> | undefined
  name: TName
} & TextareaHTMLAttributes<HTMLTextAreaElement>) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel className={cn(labelClassName, "font-normal")}>
            {label}
          </FormLabel>
          <FormControl>
            <textarea
              className={cn(
                "flex min-h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
                inputClassName
              )}
              {...inputProps}
              {...field}
            >
              {inputProps.value}
            </textarea>
          </FormControl>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
