import { useApolloClient } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import { Params } from "static-path"
import { z } from "zod"
import { gql } from "~/__generated__"
import { cn } from "~/common/cn"
import { toCalendarDate } from "~/common/date-formatting"
import {
  companyContentLibraryPath,
  companyProductsDetailUploadPath,
} from "~/common/paths"
import { useSafeMutation } from "~/common/use-safe-mutation"
import { useValidationErrors } from "~/common/use-validation-errors"
import { TextField } from "~/fields/text-field"
import closeCircle from "~/images/icons/close-circle"
import { ContentReferenceElement } from "~/products/content-references"
import { Button, buttonVariants } from "~/ui/button"
import { Card, CardContent } from "~/ui/card"
import { DatePicker } from "~/ui/date-picker"
import { Form, FormControl, FormField, FormItem, FormLabel } from "~/ui/form"
import { Input } from "~/ui/input"
import { Link } from "~/ui/link"
import { LoadingIndicatorCentered } from "~/ui/loading-indicator"
import { Pane, PaneBody } from "~/ui/pane"
import Text from "~/ui/typography"
import { useToast } from "~/ui/use-toast"
import { useUploadFile } from "~/uploads/use-upload"
import { WORKWEEK_CONTENT_QUERY } from "./content-library-screen"

const formSchema = z.object({
  productBriefId: z.string().optional(),
  productBriefIds: z.array(z.string()),
  name: z.string(),
  publishDate: z.date(),
  link: z.string(),
  signedId: z.string().optional(),
})

const mutation = gql(/* GraphQL */ `
  mutation CreateWorkweekContent($input: ContentReferenceCreateInput!) {
    contentReferenceCreate(input: $input) {
      contentReference {
        id
        name
        publishDate
        link
        filename
        contentType
        hasAsset
        isDestroyed
      }
    }
  }
`)

export const ContentLibraryWorkweekUploadPane: React.FC = () => {
  const params = useParams() as Params<
    typeof companyProductsDetailUploadPath.pattern
  >
  const { upload, loading } = useUploadFile()
  const [linkIsVisible, setLinkIsVisible] = useState(true)
  const [fileObject, setFileObject] = useState<{
    name: string
    size: number
    contentType: string
  } | null>(null)

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      productBriefId: undefined,
      productBriefIds: [],
      name: "",
      publishDate: undefined,
      link: "",
      signedId: undefined,
    },
  })

  const [exec, result] = useSafeMutation(mutation)

  useValidationErrors(form.setError, result)
  const { toast } = useToast()
  const navigate = useNavigate()
  const apolloClient = useApolloClient()

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const result = await exec({
      variables: {
        input: {
          workweekMarketing: true,
          contentReferenceInput: {
            name: values.name,
            publishDate: values.publishDate
              ? toCalendarDate(values.publishDate).toISOString()
              : new Date().toISOString(),
            link: values.link,
            signedId: values.signedId,
          },
        },
      },
    })

    if (!result.errors) {
      // Refresh the WORKWEEK_CONTENT_QUERY
      await apolloClient.refetchQueries({
        include: [WORKWEEK_CONTENT_QUERY],
      })

      toast({
        title: "Content uploaded",
        description: "The content has been uploaded",
      })
      navigate(
        companyContentLibraryPath({
          slug: params.slug,
        })
      )
    }
  }

  return (
    <Pane>
      <PaneBody className="pt-4 flex flex-col border-b">
        <Link
          to={companyContentLibraryPath({
            slug: params.slug,
          })}
          variant="close-pane"
          className="mb-2"
        >
          Close
        </Link>

        <h2 className="font-medium text-lg">Upload Workweek Content</h2>
      </PaneBody>
      <PaneBody className="pt-4 flex flex-col">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="grid gap-4">
              <TextField label="Name*" control={form.control} name="name" />

              <FormField
                control={form.control}
                name="publishDate"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="block">Publish Date*</FormLabel>
                    <FormControl>
                      <DatePicker
                        date={field.value}
                        {...field}
                        className="justify-start flex items-center h-10 w-full rounded-md border border-input bg-background disabled:bg-gray-f9 px-3 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              <div className="grid gap-4">
                <div className="grid gap-2">
                  <label className="flex items-center gap-2 peer">
                    <input
                      type="radio"
                      name="fileType"
                      value="link"
                      checked={linkIsVisible}
                      onChange={(e) =>
                        setLinkIsVisible(e.target.value === "link")
                      }
                    />
                    <Text variant="body-14">Link Input</Text>
                  </label>
                  {linkIsVisible && (
                    <div>
                      <FormField
                        control={form.control}
                        name="link"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Link*</FormLabel>
                            <FormControl>
                              <Input {...field} type="url" />
                            </FormControl>
                          </FormItem>
                        )}
                      />
                    </div>
                  )}
                </div>
                <label className="flex items-center gap-2 peer">
                  <input
                    type="radio"
                    name="fileType"
                    value="file"
                    checked={!linkIsVisible}
                    onChange={(e) =>
                      setLinkIsVisible(e.target.value === "link")
                    }
                  />
                  <Text variant="body-14">File Input</Text>
                </label>
                {!linkIsVisible && (
                  <div>
                    <FormField
                      control={form.control}
                      name={"signedId"}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel className="sr-only">File</FormLabel>
                          <FormControl>
                            <Card className="bg-gray-f9 border-gray-d0">
                              <CardContent className="grid p-3 gap-2">
                                {loading ? (
                                  <div className="py-4">
                                    <LoadingIndicatorCentered />
                                  </div>
                                ) : field.value != null ? (
                                  fileObject ? (
                                    <ContentReferenceElement
                                      name={fileObject.name}
                                      fileExtension={fileObject.contentType
                                        .split("/")[1]
                                        .toUpperCase()}
                                      byteSize={fileObject.size}
                                    />
                                  ) : null
                                ) : null}
                                <div className="flex justify-between">
                                  <label
                                    className={cn(
                                      "relative",
                                      buttonVariants({ variant: "default" })
                                    )}
                                  >
                                    Choose file
                                    <input
                                      className="absolute top-0 right-0 min-w-full min-h-full text-right opacity-0 outline-none bg-white cursor-inherit block"
                                      type="file"
                                      onChange={async (event) => {
                                        const file = event.target.files?.[0]
                                        if (!file) return

                                        setFileObject({
                                          name: file.name,
                                          size: file.size,
                                          contentType: file.type,
                                        })

                                        const signedId = await upload(file)
                                        field.onChange(signedId)
                                      }}
                                    />
                                  </label>
                                  {fileObject && (
                                    <Button
                                      type="button"
                                      variant="ghost"
                                      size="icon"
                                      onClick={() => {
                                        field.onChange(undefined)
                                        setFileObject(null)
                                      }}
                                    >
                                      <img {...closeCircle} alt="Remove" />
                                    </Button>
                                  )}
                                </div>
                              </CardContent>
                            </Card>
                          </FormControl>
                        </FormItem>
                      )}
                    ></FormField>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-4">
              <Button type="submit" className="w-full">
                Add Content
              </Button>
            </div>
          </form>
        </Form>
      </PaneBody>
    </Pane>
  )
}
