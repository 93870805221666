import { useState } from "react"
import { Post_CardFragment } from "~/__generated__/graphql"
import { cn } from "~/common/cn"
import { imgixResize } from "~/common/imgix"
import { Button } from "~/ui/button"
import { Gallery } from "~/ui/Gallery"

const AttachmentButton = ({
  attachment,
  index,
  onClick,
}: {
  attachment: Post_CardFragment["attachments"][0]
  index: number
  onClick: (index: number) => void
}) => {
  return (
    <Button
      variant="ghost"
      size="inline"
      key={attachment.id}
      onClick={() => onClick(index)}
      className="card-clickable"
    >
      <img
        src={imgixResize(attachment.editorUrl, {
          width: 400,
          height: 400,
          fit: "max",
        })}
        alt={attachment.filename}
        className="object-cover w-full h-auto rounded-2xl border border-mercury"
        key={attachment.id}
      />
    </Button>
  )
}

export const ImageAttachments = ({
  attachments,
}: {
  attachments: Post_CardFragment["attachments"]
}) => {
  const [currentImageAttachment, setCurrentImageAttachment] = useState(0)
  const [isGalleryOpen, setIsGalleryOpen] = useState(false)

  const onThumbnailClick = (index: number) => {
    setCurrentImageAttachment(index)
    setIsGalleryOpen(true)
  }

  const firstColumn = attachments.filter((_, index) => index % 3 === 0)
  const secondColumn = attachments.filter((_, index) => index % 3 === 1)
  const thirdColumn = attachments.filter((_, index) => index % 3 === 2)

  if (attachments.length === 0) return null

  return (
    <>
      <Gallery
        open={isGalleryOpen}
        onOpenChange={(open) => setIsGalleryOpen(open)}
        step={currentImageAttachment}
        images={attachments.map((attachment) => attachment.editorUrl)}
      />
      <div
        className={cn(
          "grid grid-flow-dense grid-cols-2 md:grid-cols-3 gap-4",
          { "md:grid-cols-3": attachments.length > 3 },
          { "md:grid-cols-2": attachments.length === 2 },
          { "md:grid-cols-1": attachments.length === 1 }
        )}
      >
        <div className="flex flex-col gap-4">
          {firstColumn.map((attachment, index) => (
            <AttachmentButton
              key={index}
              attachment={attachment}
              index={3 * index}
              onClick={onThumbnailClick}
            />
          ))}
        </div>
        {secondColumn.length > 0 && (
          <div className="flex flex-col gap-4">
            {secondColumn.map((attachment, index) => (
              <AttachmentButton
                key={index}
                attachment={attachment}
                index={3 * index + 1}
                onClick={onThumbnailClick}
              />
            ))}
          </div>
        )}
        {thirdColumn.length > 0 && (
          <div className="flex flex-col gap-4">
            {thirdColumn.map((attachment, index) => (
              <AttachmentButton
                key={index}
                attachment={attachment}
                index={3 * index + 2}
                onClick={onThumbnailClick}
              />
            ))}
          </div>
        )}
      </div>
    </>
  )
}
