import { useQuery } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import { useFieldArray, useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import type { Params } from "static-path"
import invariant from "tiny-invariant"
import { z } from "zod"
import { gql } from "~/__generated__"
import {
  CreatorBrandEditPane_CreatorBrandFragment,
  Role,
  UserStatus,
} from "~/__generated__/graphql"
import { gqlMatchOptional } from "~/common/gql-match"
import { creatorBrandsEditPath, creatorBrandsPath } from "~/common/paths"
import { useDocumentTitle } from "~/common/use-document-title"
import { useSafeMutation } from "~/common/use-safe-mutation"
import { useValidationErrors } from "~/common/use-validation-errors"
import { HexColorField } from "~/fields/hex-color-field"
import { ImageField } from "~/fields/image-field"
import { UserField } from "~/fields/user-field"
import { MinimalTiptapEditor } from "~/tiptap"
import { Button } from "~/ui/button"
import { GraphqlError } from "~/ui/errors"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/ui/form"
import { Input } from "~/ui/input"
import { LinkButton } from "~/ui/link-button"
import { LoadingIndicatorCentered } from "~/ui/loading-indicator"
import { Pane, PaneBody, PaneHeader } from "~/ui/pane"
import Text from "~/ui/typography"
import { useToast } from "~/ui/use-toast"

gql(/* GraphQL */ `
  fragment CreatorBrandEditPane_users on UserConnection {
    edges {
      node {
        id
        fullName
      }
    }
  }
`)

gql(/* GraphQL */ `
  fragment CreatorBrandEditPane_creatorBrand on CreatorBrand {
    id
    name
    status
    ctaColor
    ctaTextColor
    creatorIds
    creativeIds
    image {
      id
    }
    bylineFields(first: 20) {
      edges {
        node {
          id
          content
        }
      }
    }
  }
`)

const query = gql(/* GraphQL */ `
  query CreatorBrandEditPaneQuery($creatorBrandId: ID!) {
    node(id: $creatorBrandId) {
      __typename
      ... on CreatorBrand {
        id
        ...CreatorBrandEditPane_creatorBrand
      }
    }
  }
`)

export const CreatorBrandEditPane = () => {
  const params = useParams<Params<typeof creatorBrandsEditPath.pattern>>()
  invariant(params.creatorBrandId)

  const result = useQuery(query, {
    variables: { creatorBrandId: params.creatorBrandId },
  })
  const creatorBrand = gqlMatchOptional(result.data?.node, "CreatorBrand")

  useDocumentTitle(creatorBrand ? `Edit ${creatorBrand.name}` : null)

  return (
    <Pane>
      <PaneHeader>Edit Creator </PaneHeader>
      <PaneBody>
        {result.loading ? (
          <LoadingIndicatorCentered />
        ) : result.error ? (
          <GraphqlError error={result.error} />
        ) : creatorBrand ? (
          <CreatorBrandForm creatorBrand={creatorBrand} />
        ) : (
          <Text variant="body-12">Can't find creator brand</Text>
        )}
      </PaneBody>
    </Pane>
  )
}

const formSchema = z.object({
  ctaColor: z.string(),
  ctaTextColor: z.string(),
  creatorIds: z.array(z.string()),
  creativeIds: z.array(z.string()),
  imageId: z.string().nullable(),
  bylineFields: z
    .array(
      z.object({
        id: z.string().optional(),
        content: z.string().optional(),
        _destroy: z.string().optional(),
      })
    )
    .optional(),
})

const mutation = gql(/* GraphQL */ `
  mutation CreatorBrandEditPaneUpdateMutation(
    $input: CreatorBrandUpdateInput!
  ) {
    creatorBrandUpdate(input: $input) {
      creatorBrand {
        id
        ...CreatorBrandEditPane_creatorBrand
      }
    }
  }
`)

const CreatorBrandForm: React.FC<{
  creatorBrand: CreatorBrandEditPane_CreatorBrandFragment
}> = ({ creatorBrand }) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    values: {
      ctaColor: creatorBrand.ctaColor ?? "",
      ctaTextColor: creatorBrand.ctaTextColor ?? "",
      creativeIds: creatorBrand.creativeIds,
      creatorIds: creatorBrand.creatorIds,
      imageId: creatorBrand.image?.id ?? null,
      bylineFields:
        creatorBrand.bylineFields.edges.map((edge) => ({
          id: edge.node.id,
          content: edge.node.content || undefined, // Convert null to undefined
          _destroy: undefined, // Add this field to match the expected type
        })) ?? [],
    },
  })

  const [exec, result] = useSafeMutation(mutation)
  useValidationErrors(form.setError, result)
  const navigate = useNavigate()
  const { toast } = useToast()

  const performUpdate = async (values: z.infer<typeof formSchema>) => {
    const { bylineFields, ...rest } = values
    const result = await exec({
      variables: {
        input: {
          id: creatorBrand.id,
          creatorBrandInput: {
            ...rest,
            bylineFields:
              bylineFields &&
              bylineFields.map((field) => {
                if (field.id && field.id.startsWith("new")) {
                  // For new fields, don't send the id
                  return {
                    content: field.content || "",
                    _destroy: field._destroy,
                  }
                } else {
                  // For existing fields, include all properties
                  return {
                    id: field.id,
                    content: field.content || "",
                    _destroy: field._destroy,
                  }
                }
              }),
          },
        },
      },
    })
    if (!result.errors) {
      toast({
        title: "Creator brand updated",
        variant: "default",
      })
      navigate(creatorBrandsPath({}))
    }
  }
  const {
    fields: bylineFields,
    remove: removeBylineField,
    append: appendBylineField,
  } = useFieldArray({
    name: "bylineFields",
    control: form.control,
  })

  const handleRemoveBylineField = (index: number) => {
    const field = form.getValues(`bylineFields.${index}`)
    if (field.id && !field.id.startsWith("new")) {
      // Mark existing fields for deletion instead of removing them from the form
      form.setValue(`bylineFields.${index}._destroy`, "true")
    } else {
      // Remove new fields from the form
      removeBylineField(index)
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(performUpdate)}>
        <div className="space-y-4">
          <FormItem>
            <FormLabel>Name</FormLabel>
            <FormControl>
              <Input value={creatorBrand.name} disabled />
            </FormControl>
          </FormItem>
          <ImageField
            label="Upload Logo"
            control={form.control}
            name="imageId"
          />

          <HexColorField
            control={form.control}
            name="ctaColor"
            label="CTA Background Color (#Hex Value)"
          />
          <HexColorField
            control={form.control}
            name="ctaTextColor"
            label="CTA Text Color (#Hex Value)"
          />
          <UserField
            control={form.control}
            name="creativeIds"
            label="Creatives"
            required={true}
            filters={{
              roles: [Role.WorkweekAdmin, Role.WorkweekTeam, Role.Creative],
              statuses: [UserStatus.Active],
            }}
          />
          <UserField
            control={form.control}
            name="creatorIds"
            label="Creators"
            required={true}
            filters={{
              roles: [Role.WorkweekAdmin, Role.WorkweekTeam, Role.Creative],
              statuses: [UserStatus.Active],
            }}
          />
          <FormField
            control={form.control}
            name="bylineFields"
            render={(field) => (
              <div className="space-y-2">
                <FormLabel className="font-normal">Bylines*</FormLabel>
                <FormMessage className="font-normal text-xs" />
                <div className="space-y-2">
                  {bylineFields.map((formField, index) => {
                    const destroyValue = form.watch(
                      `bylineFields.${index}._destroy`
                    )
                    if (destroyValue) return null // Hide the field if _destroy has a value

                    return (
                      <div key={formField.id}>
                        <input
                          type="hidden"
                          value={formField.id}
                          {...form.register(`bylineFields.${index}.id`)}
                        />
                        <input
                          type="hidden"
                          {...form.register(`bylineFields.${index}._destroy`)}
                        />
                        <div className="flex items-center space-x-2">
                          <MinimalTiptapEditor
                            value={
                              formField.content
                                ? JSON.parse(formField.content)
                                : undefined
                            }
                            onValueChange={(value) => {
                              form.setValue(
                                `bylineFields.${index}.content`,
                                JSON.stringify(value)
                              )
                            }}
                            size="sm"
                            config={{
                              bold: true,
                              italic: true,
                              underline: true,
                              strike: false,
                              link: false,
                              orderedList: false,
                              bulletList: false,
                              textSize: true,
                            }}
                          />
                        </div>
                        <Button
                          type="button"
                          variant="link"
                          size="xs"
                          onClick={() => handleRemoveBylineField(index)}
                          className="p-0 ml-0 text-gray-500"
                        >
                          <Text variant="body-12">Remove</Text>
                        </Button>
                      </div>
                    )
                  })}
                </div>
              </div>
            )}
          />
          <Button
            type="button"
            variant="link"
            className="p-0"
            onClick={() =>
              appendBylineField({
                id: `new-${Date.now()}`,
                content: "",
                _destroy: undefined,
              })
            }
          >
            Add another
          </Button>
        </div>

        <div className="flex justify-between items-center mt-6">
          <Button
            type="submit"
            disabled={result.loading}
            className="font-light"
          >
            Save
          </Button>
          <LinkButton to={creatorBrandsPath({})} variant="ghost">
            Cancel
          </LinkButton>
        </div>
      </form>
    </Form>
  )
}
