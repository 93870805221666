import { useLazyQuery } from "@apollo/client"
import { DataFunc, SuggestionDataItem } from "react-mentions"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"

export type CustomDataItem = SuggestionDataItem & {
  id: string
  photoUrl?: string | null
  firstName?: string | null
  lastName?: string | null
  name?: string | null
  admin?: boolean
}

export const useMentionDataSource = ({
  mentionsDisabled,
}: { mentionsDisabled?: boolean } = {}) => {
  const [getMentionUsers] = useLazyQuery(MENTION_USER_QUERY_DOCUMENT, {
    variables: {
      excludeSelf: true,
    },
  })

  const fetchMentionUsers: DataFunc = async (query, callback) => {
    if (!query || mentionsDisabled) return

    const { data, error } = await getMentionUsers({
      variables: { query },
    })

    if (error) {
      callback([])
    } else {
      invariant(data)
      const dataItems: CustomDataItem[] = data.users.nodes
        .filter((u) => u.name && u.name.length > 0)
        .map((u) => ({
          display: u.name || " ",
          id: u.id,
          photoUrl: u.photoUrl,
          firstName: u.firstName,
          lastName: u.lastName,
        }))
      callback(dataItems)
    }
  }

  return { fetchMentionUsers }
}

export const MENTION_USER_QUERY_DOCUMENT = gql(`
  query MentionUsers($query: String, $excludeSelf: Boolean, $statuses: [UserStatus!], $roles: [Role!], $companies: [String!], $queryScope: UsersQueryScopeEnum) {
    users(filters: { search: $query, excludeSelf: $excludeSelf, statuses: $statuses, roles: $roles, companies: $companies, queryScope: $queryScope }, first: 20) {
      nodes {
        id
        name
        firstName
        lastName
        photoUrl
        admin
        role
        companies {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`)
