import { CampaignDeliverableMetricsFragment } from "~/__generated__/graphql"
import { Subsection } from "~/campaigns/subsection"
import { SubsectionBody } from "~/campaigns/subsection-body"
import { SubsectionTitle } from "~/campaigns/subsection-title"

export const NewsletterAdsSidebarMetrics: React.FC<{
  campaignDeliverable: CampaignDeliverableMetricsFragment
}> = ({ campaignDeliverable }) => {
  const metrics = {
    emailRecipients: campaignDeliverable.recipientsMetric ?? 0,
    opens: campaignDeliverable.opensMetric ?? 0,
    clicks: campaignDeliverable.totalClicksMetric ?? 0,
    notes: campaignDeliverable.metricNotes ?? "",
  }

  const openRate = (metrics.opens / metrics.emailRecipients) * 100
  const clickThroughRate = (metrics.clicks / metrics.opens) * 100

  return (
    <div>
      <Subsection>
        <SubsectionTitle>Email Recipients</SubsectionTitle>
        <SubsectionBody>
          {metrics.emailRecipients.toLocaleString()}
        </SubsectionBody>
      </Subsection>
      <Subsection>
        <SubsectionTitle>Opens</SubsectionTitle>
        <SubsectionBody>{metrics.opens.toLocaleString()}</SubsectionBody>
      </Subsection>
      <Subsection>
        <SubsectionTitle>Open Rate</SubsectionTitle>
        <SubsectionBody>{openRate.toFixed(2)}%</SubsectionBody>
      </Subsection>
      <Subsection>
        <SubsectionTitle>Clicks</SubsectionTitle>
        <SubsectionBody>{metrics.clicks.toLocaleString()}</SubsectionBody>
      </Subsection>
      <Subsection>
        <SubsectionTitle>Click Through Rate (CTR)</SubsectionTitle>
        <SubsectionBody>{clickThroughRate.toFixed(2)}%</SubsectionBody>
      </Subsection>
      <Subsection>
        <SubsectionTitle>Notes</SubsectionTitle>
        <SubsectionBody>{metrics.notes ? metrics.notes : "–"}</SubsectionBody>
      </Subsection>
    </div>
  )
}
