import { matchPath } from "react-router-dom"
import { EventPageTypeEnum } from "~/__generated__/graphql"
import * as paths from "./paths"

const routeTypeMapping = [
  { path: paths.dashboardPath.pattern, type: EventPageTypeEnum.Dashboard },
  { path: paths.messagesPath.pattern, type: EventPageTypeEnum.Messages },
  {
    path: paths.dmPath.pattern,
    type: EventPageTypeEnum.DirectMessage,
  },
  { path: paths.roomPath.pattern, type: EventPageTypeEnum.GroupMessage },
  { path: paths.campaignsPath.pattern, type: EventPageTypeEnum.Campaigns },
  {
    path: paths.campaignDetailPath.pattern,
    type: EventPageTypeEnum.CampaignDetail,
  },
  {
    path: paths.campaignDetailGoalsPath.pattern,
    type: EventPageTypeEnum.CampaignGoals,
  },
  {
    path: paths.campaignDetailMetricsPath.pattern,
    type: EventPageTypeEnum.CampaignMetrics,
  },
  {
    path: paths.campaignDeliverableFeedbackPath.pattern,
    type: EventPageTypeEnum.CampaignDeliverableFeedback,
  },
  {
    path: paths.campaignDeliverableHistoryPath.pattern,
    type: EventPageTypeEnum.CampaignDeliverableStatusHistory,
  },
  {
    path: paths.campaignDeliverablePath.pattern,
    type: EventPageTypeEnum.CampaignDeliverable,
  },
  { path: paths.companyProductsPath.pattern, type: EventPageTypeEnum.Products },
  {
    path: paths.companyProductsDetailPath.pattern,
    type: EventPageTypeEnum.ProductDetail,
  },
  {
    path: paths.companyProductsEditPath.pattern,
    type: EventPageTypeEnum.ProductEdit,
  },
  {
    path: paths.companyProductsEditUploadPath.pattern,
    type: EventPageTypeEnum.ProductUploadContentPane,
  },
  { path: paths.companyReportsPath.pattern, type: EventPageTypeEnum.Reports },
  {
    path: paths.companyContentLibraryPath.pattern,
    type: EventPageTypeEnum.ContentLibrary,
  },
  {
    path: paths.companyContentLibraryUploadPath.pattern,
    type: EventPageTypeEnum.ContentLibraryUploadPane,
  },
  { path: paths.companiesPath.pattern, type: EventPageTypeEnum.Companies },
  {
    path: paths.companyProfilePath.pattern,
    type: EventPageTypeEnum.CompanyProfile,
  },
  {
    path: paths.companyProfileEditPath.pattern,
    type: EventPageTypeEnum.CompanyProfileEdit,
  },
  { path: paths.usersPath.pattern, type: EventPageTypeEnum.Users },
  { path: paths.usersDetailPath.pattern, type: EventPageTypeEnum.UserDetail },
  { path: paths.usersEditPath.pattern, type: EventPageTypeEnum.UserEdit },
  {
    path: paths.companyUsersPath.pattern,
    type: EventPageTypeEnum.CompanyUsers,
  },
  {
    path: paths.companyUsersInvitePath.pattern,
    type: EventPageTypeEnum.CompanyUsersInvitePane,
  },
  {
    path: paths.creatorBrandsPath.pattern,
    type: EventPageTypeEnum.CreatorBrands,
  },
  {
    path: paths.creatorBrandsEditPath.pattern,
    type: EventPageTypeEnum.CreatorBrandEdit,
  },
  { path: paths.profilePath.pattern, type: EventPageTypeEnum.Profile },
  { path: paths.signInPath.pattern, type: EventPageTypeEnum.SignIn },
]

export const getPageType = (pathname: string) => {
  for (const route of routeTypeMapping) {
    if (matchPath({ path: route.path }, pathname)) {
      return route.type
    }
  }

  return EventPageTypeEnum.UnknownPage
}
