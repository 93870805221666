import { LinkButton } from "~/ui/link-button"
import Text from "~/ui/typography"

export const NotFoundScreen = ({
  title = "Error 404 - Page Not Found",
}: {
  title?: string
}) => {
  return (
    <div className="flex flex-col items-center justify-center h-full">
      <div className="text-[11rem] font-black text-[#e1e1e1] leading-[13rem]">
        404
      </div>
      <div className="flex flex-col gap-4 text-center">
        <h1 className="text-3xl font-medium">{title}</h1>
        <p className="max-w-[42ch] text-muted-foreground">
          <Text variant="body-14">
            Looks like you’ve wandered off the beaten path. Click the link below
            to get you back on track and find what you’re looking for.
          </Text>
        </p>
      </div>
      <div className="mt-8">
        <LinkButton to="/">Back to home</LinkButton>
      </div>
    </div>
  )
}
