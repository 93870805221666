import { isRouteErrorResponse, useRouteError } from "react-router-dom"
import { dashboardPath } from "~/common/paths"
import { useDocumentTitle } from "~/common/use-document-title"
import Text from "~/ui/typography"
import { LinkButton } from "./link-button"

export const ErrorBoundary = () => {
  const error = useRouteError()
  useDocumentTitle("Error")
  if (isRouteErrorResponse(error)) {
    return (
      <ErrorContainer>
        <div className="flex items-center justify-center h-full">
          <div className="space-y-4 max-w-[530px] text-center">
            <div className="text-[230px] leading-[230px] font-bold text-gray-300">
              {error.status}
            </div>
            <h2 className="text-3xl font-medium">
              Error {error.status} - {error.statusText}
            </h2>
            <p className="space-y-8">
              <Text variant="body-14" className="text-gray-500">
                {error.status === 401
                  ? "Looks like you don't have permission to view this page. Click the link below to go get you back on track and find what you're looking for."
                  : error.status === 404
                  ? "Looks like you’ve wandered off the beaten path. Click the link below to get you back on track and find what you’re looking for."
                  : "There was an error"}
              </Text>
            </p>
            <LinkButton to={dashboardPath.pattern}>Back to Home</LinkButton>
          </div>
        </div>
      </ErrorContainer>
    )
  }

  if (
    error &&
    typeof error === "object" &&
    "message" in error &&
    typeof error.message === "string"
  ) {
    return (
      <ErrorContainer>
        <h1 className="text-red-700 mb-2">An error occured</h1>
        <pre className="whitespace-pre-wrap">{error.message}</pre>
      </ErrorContainer>
    )
  }

  throw error
}

const ErrorContainer: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <div className="max-w-7xl mx-auto m-4 pt-16">
    <div className="rounded p-4 py-2 overflow-auto">{children}</div>
  </div>
)
