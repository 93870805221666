import {
  Role,
  CampaignDeliverableStatus as StatusEnum,
} from "~/__generated__/graphql"
import { useViewer } from "~/auth/viewer-context"

const campaignDeliverableStatusLabels: Record<StatusEnum, string> = {
  [StatusEnum.Approved]: "Approved",
  [StatusEnum.AssignedToCreative]: "Assigned to Creative",
  [StatusEnum.AwaitingAccountManagerApproval]:
    "Awaiting Account Manager Approval",
  [StatusEnum.AwaitingClientApproval]: "Awaiting Client Approval",
  [StatusEnum.AwaitingCreatorApproval]: "Awaiting Creator Approval",
  [StatusEnum.BriefNeeded]: "Brief Needed",
  [StatusEnum.Published]: "Published",
  [StatusEnum.Scheduled]: "Scheduled",
  [StatusEnum.AdScaffolded]: "Ad Scaffolded",
  [StatusEnum.AdCreationInProgress]: "Ad Creation In Progress",
  [StatusEnum.InProduction]: "In Production",
}

export const CampaignDeliverableStatusLabel: React.FC<{
  status: StatusEnum
}> = (props) => {
  const { viewer } = useViewer()
  const isClient = viewer?.role === Role.Client

  if (
    isClient &&
    [
      StatusEnum.AssignedToCreative,
      StatusEnum.AwaitingCreatorApproval,
      StatusEnum.AwaitingAccountManagerApproval,
    ].includes(props.status)
  ) {
    return "Ad Creation in Progress"
  }

  return campaignDeliverableStatusLabels[props.status]
}
