import { Post_CardFragment } from "~/__generated__/graphql"
import { FormattedContent } from "~/components/FormattedContent"

export const PostContentSection = ({ post }: { post: Post_CardFragment }) => {
  if (!post.content) return null

  return (
    <div className="text-sm tracking-wide">
      <FormattedContent content={post.content} />
    </div>
  )
}
