import { gql } from "~/__generated__"

import { useQuery } from "@apollo/client"
import { InputHTMLAttributes, useState } from "react"
import {
  Control,
  FieldPath,
  FieldValues,
  useFormContext,
} from "react-hook-form"
import { gqlMatchOptional } from "~/common/gql-match"
import { GraphqlError } from "~/ui/errors"
import { FormField, FormItem, FormLabel } from "~/ui/form"
import { MultiSelect } from "~/ui/multi-select"
import { UsersFilterInput } from "~/__generated__/graphql"

const userFieldQuery = gql(/* GraphQL */ `
  query UserField(
    $usersAfter: String
    $selectedUserIds: [ID!]!
    $filters: UsersFilterInput
    $first: Int!
  ) {
    selectedUsers: nodes(ids: $selectedUserIds) {
      __typename
      ... on User {
        id
        fullName
      }
    }

    users(first: $first, filters: $filters, after: $usersAfter) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          fullName
        }
      }
    }
  }
`)

export const UserField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  description,
  label,
  labelClassName,
  inputClassName,
  required,
  filters,
  ...inputProps
}: {
  label: string
  labelClassName?: string
  inputClassName?: string
  description?: string
  control: Control<TFieldValues> | undefined
  name: TName
  required?: boolean
  filters?: UsersFilterInput
} & InputHTMLAttributes<HTMLInputElement>) => {
  const [search, setSearch] = useState("")
  const form = useFormContext()
  const value = form.watch(name)

  const filterResult = useQuery(userFieldQuery, {
    variables: {
      first: 50,
      filters: {
        ...filters,
        search: search || null,
      },
      selectedUserIds: value,
      usersAfter: null,
    },
  })

  const filterData = filterResult.data ?? filterResult.previousData

  const selectedUsers =
    filterData?.selectedUsers.map((n) => {
      return gqlMatchOptional(n, "User")
    }) ?? []

  const userOptions =
    filterData?.users.edges
      .map((edge) => edge.node)
      .map((user) => ({
        label: user.fullName,
        value: user.id,
      })) ?? []

  if (filterResult.error) {
    return <GraphqlError error={filterResult.error} />
  }

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel required={required}>{label}</FormLabel>
          <MultiSelect
            name={field.name}
            options={userOptions}
            getLabel={(id: string) =>
              selectedUsers.find((x) => x?.id === id)?.fullName ?? ""
            }
            selected={field.value}
            inputValue={search}
            placeholder="Search for a user"
            onInputChange={(value) => setSearch(value)}
            onValuesChange={(ids) => {
              field.onChange(ids)
            }}
            shouldFilter={false}
          />
        </FormItem>
      )}
    />
  )
}
