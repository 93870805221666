import { CampaignDeliverableMetricsFragment } from "~/__generated__/graphql"
import { Subsection } from "~/campaigns/subsection"
import { SubsectionBody } from "~/campaigns/subsection-body"
import { SubsectionTitle } from "~/campaigns/subsection-title"

export const PodcastSidebarMetrics: React.FC<{
  campaignDeliverable: CampaignDeliverableMetricsFragment
}> = ({ campaignDeliverable }) => {
  const metrics = {
    downloads7DaysPostLaunchMetric:
      campaignDeliverable.downloads7DaysPostLaunchMetric ?? 0,
    downloads30DaysPostLaunchMetric:
      campaignDeliverable.downloads30DaysPostLaunchMetric ?? 0,
    impressions7DaysPostLaunchMetric:
      campaignDeliverable.impressions7DaysPostLaunchMetric ?? 0,
    impressions30DaysPostLaunchMetric:
      campaignDeliverable.impressions30DaysPostLaunchMetric ?? 0,
    impressionsOnYouTube: campaignDeliverable.impressionsMetric ?? 0,
    notes: campaignDeliverable.metricNotes ?? "",
  }

  return (
    <>
      <Subsection>
        <SubsectionTitle>Downloads 7 Days Post Launch</SubsectionTitle>
        <SubsectionBody>
          {metrics.downloads7DaysPostLaunchMetric.toLocaleString()}
        </SubsectionBody>
      </Subsection>
      <Subsection>
        <SubsectionTitle>Downloads 30 Days Post Launch</SubsectionTitle>
        <SubsectionBody>
          {metrics.downloads30DaysPostLaunchMetric.toLocaleString()}
        </SubsectionBody>
      </Subsection>
      <Subsection>
        <SubsectionTitle>Impressions 7 Days Post Launch</SubsectionTitle>
        <SubsectionBody>
          {metrics.impressions7DaysPostLaunchMetric.toLocaleString()}
        </SubsectionBody>
      </Subsection>
      <Subsection>
        <SubsectionTitle>Impressions 30 Days Post Launch</SubsectionTitle>
        <SubsectionBody>
          {metrics.impressions30DaysPostLaunchMetric.toLocaleString()}
        </SubsectionBody>
      </Subsection>
      <Subsection>
        <SubsectionTitle>Impressions on YouTube</SubsectionTitle>
        <SubsectionBody>
          {metrics.impressionsOnYouTube.toLocaleString()}
        </SubsectionBody>
      </Subsection>
      <Subsection>
        <SubsectionTitle>Notes</SubsectionTitle>
        <SubsectionBody>{metrics.notes ? metrics.notes : "–"}</SubsectionBody>
      </Subsection>
    </>
  )
}
