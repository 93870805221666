import { InputHTMLAttributes } from "react"
import { Control, FieldPath, FieldValues } from "react-hook-form"
import { cn } from "~/common/cn"
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/ui/form"
import { Select } from "../ui/select"

export const SelectField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  typeahead = false,
  label,
  isLoading = false,
  onOpen,
  onQueryChange,
  onChange,
  options,
  text,
  labelClassName,
  disabled = false,
  showMessage = true,
}: {
  label?: string
  isLoading?: boolean
  labelClassName?: string
  options: { label: string; value: string }[]
  text: (field: { value: string }) => React.ReactNode
  typeahead?: boolean
  onOpen?: () => void
  onQueryChange?: (query: string) => void
  onChange?: (value: string) => void
  control: Control<TFieldValues> | undefined
  name: TName
  disabled?: boolean
  showMessage?: boolean
} & InputHTMLAttributes<HTMLInputElement>) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          {label != null ? (
            <FormLabel className={cn("font-normal", labelClassName)}>
              {label}
            </FormLabel>
          ) : null}
          <FormControl>
            <Select
              typeAhead={typeahead}
              isLoading={isLoading}
              disabled={disabled}
              onOpen={onOpen}
              text={text(field)}
              options={options}
              onQueryChange={onQueryChange}
              {...field}
              onChange={onChange || field.onChange}
              value={field.value}
            />
          </FormControl>
          {showMessage && <FormMessage className="font-normal text-xs" />}
        </FormItem>
      )}
    />
  )
}
