import { useQuery } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { useParams } from "react-router-dom"
import { Params } from "static-path"
import { z } from "zod"
import { gql } from "~/__generated__"
import { formatDate } from "~/common/date-formatting"
import { gqlMatchOptional } from "~/common/gql-match"
import { campaignDeliverablePath } from "~/common/paths"
import { useSafeMutation } from "~/common/use-safe-mutation"
import { useValidationErrors } from "~/common/use-validation-errors"
import { Button } from "~/ui/button"
import { Form, FormControl, FormField, FormItem, FormMessage } from "~/ui/form"
import { Link } from "~/ui/link"
import { LinkButton } from "~/ui/link-button"
import { Pane, PaneBody } from "~/ui/pane"
import Text from "~/ui/typography"
import { useToast } from "~/ui/use-toast"
import { UserAvatar } from "~/users/user-avatar"

const query = gql(/* GraphQL */ `
  query CampaignDeliverableFeedbackQuery($campaignDeliverableId: ID!) {
    node(id: $campaignDeliverableId) {
      __typename
      ... on CampaignDeliverable {
        id
        feedbacks(first: 100) {
          edges {
            node {
              id
              body
              createdAt
              rejectionFeedback
              sender {
                id
                fullName
                firstName
                lastName
                title
              }
            }
          }
        }
      }
    }
  }
`)

const mutation = gql(/* GraphQL */ `
  mutation createFeedback($input: FeedbackCreateInput!) {
    feedbackCreate(input: $input) {
      campaignDeliverable {
        id
        feedbacks(first: 100) {
          edges {
            node {
              id
              body
              createdAt
              rejectionFeedback
              sender {
                id
                fullName
                firstName
                lastName
                title
              }
            }
          }
        }
      }
    }
  }
`)

export const CampaignDeliverableFeedbackPane = () => {
  const params = useParams() as Params<typeof campaignDeliverablePath.pattern>

  const formSchema = z.object({
    body: z.string().min(1, "Feedback is required"),
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      body: "",
    },
  })

  const queryResult = useQuery(query, {
    variables: {
      campaignDeliverableId: params.deliverableId,
    },
  })

  const deliverable = gqlMatchOptional(
    queryResult.data?.node,
    "CampaignDeliverable"
  )

  const [exec, result] = useSafeMutation(mutation)
  useValidationErrors(form.setError, result)
  const { toast } = useToast()

  if (!deliverable) {
    return null
  }

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const result = await exec({
      variables: {
        input: {
          campaignDeliverableId: deliverable.id,
          feedbackInput: {
            body: values.body,
          },
        },
      },
    })

    if (result.errors) {
      toast({
        title: "Error",
        description: "There was an error saving the feedback",
      })
    } else {
      form.reset()
    }
  }

  return (
    <Pane>
      <PaneBody className="pt-4 flex flex-col border-b">
        <Link
          to={campaignDeliverablePath({
            campaignId: params.campaignId,
            deliverableId: params.deliverableId,
          })}
          variant="close-pane"
          className="mb-2"
        >
          Close
        </Link>
        <h2 className="font-medium text-lg">Internal Feedback</h2>
      </PaneBody>
      <PaneBody className="pt-4 flex flex-col gap-8">
        {deliverable?.feedbacks.edges &&
          deliverable?.feedbacks.edges.length > 0 && (
            <div className="grid gap-6">
              {deliverable?.feedbacks?.edges.map((edge) => (
                <div key={edge.node.id}>
                  <div className="flex items-center justify-between gap-4">
                    <div className="flex items-center gap-2">
                      <UserAvatar user={edge.node.sender} />
                      <div>
                        <div className="flex flex-col">
                          <Text variant="body-12-medium">
                            {edge.node.sender.fullName}
                          </Text>
                          <Text variant="body-10" className="text-gray-400">
                            {edge.node.sender.title}
                          </Text>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-xs-plus mt-2">
                    {edge.node.rejectionFeedback && (
                      <span className="text-red-600">Rejection Feedback: </span>
                    )}
                    {edge.node.body}
                  </div>
                  <div className="leading-3">
                    <Text variant="body-10" className="text-gray-400">
                      {formatDate(edge.node.createdAt, "MMMM dd, yyyy - pp")}
                    </Text>
                  </div>
                </div>
              ))}
            </div>
          )}
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="grid gap-4">
              <FormField
                control={form.control}
                name="body"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <textarea
                        className="flex min-h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                        placeholder="Write feedback..."
                        {...field}
                      >
                        {field.value}
                      </textarea>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="flex justify-between items-center mt-2">
              <Button
                type="submit"
                size="sm"
                disabled={form.formState.isSubmitting}
                className="font-light"
              >
                Submit Feedback
              </Button>
              <LinkButton
                variant="linkMuted"
                type="button"
                to={campaignDeliverablePath({
                  campaignId: params.campaignId,
                  deliverableId: params.deliverableId,
                })}
                className="text-xs-plus text-gray-400 pr-0"
              >
                Cancel
              </LinkButton>
            </div>
          </form>
        </Form>
      </PaneBody>
    </Pane>
  )
}
