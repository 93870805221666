import { cn } from "~/common/cn"

export const Pane: React.FC<{ children?: React.ReactNode }> = ({
  ...props
}) => <div className="border-l w-64" {...props} />

export const PaneBody: React.FC<{
  children?: React.ReactNode
  className?: string
}> = ({ className, ...props }) => (
  <div className={cn("px-4 pb-4", className)} {...props} />
)

export const PaneHeader: React.FC<{ children?: React.ReactNode }> = (props) => (
  <h2 className="font-medium text-lg p-4">{props.children}</h2>
)
