import { useQuery } from "@apollo/client"
import { InputHTMLAttributes, useEffect } from "react"
import { Control, FieldPath, FieldValues } from "react-hook-form"
import Imgix from "react-imgix"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import { cn } from "~/common/cn"
import { gqlMatchOptional } from "~/common/gql-match"
import closeCircle from "~/images/icons/close-circle"
import { Button, buttonVariants } from "~/ui/button"
import { Card, CardContent } from "~/ui/card"
import { GraphqlError } from "~/ui/errors"
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/ui/form"
import { LoadingIndicatorCentered } from "~/ui/loading-indicator"
import { useUploadImage } from "~/uploads/use-upload"

export const ImageField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  label,
  isAvatar = false,
  chooseFileButtonText = "Choose file",
}: {
  label: string
  control: Control<TFieldValues> | undefined
  name: TName
  isAvatar?: boolean
  chooseFileButtonText?: string
} & InputHTMLAttributes<HTMLInputElement>) => {
  const { upload, loading } = useUploadImage()
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <Card
              className={cn(
                !isAvatar && "bg-gray-f9 border-gray-d0",
                isAvatar && "border-0 shadow-none"
              )}
            >
              <CardContent className="grid p-3 gap-2 justify-center">
                {loading ? (
                  <div className="py-4">
                    <LoadingIndicatorCentered />
                  </div>
                ) : field.value != null ? (
                  <ImagePreview imageId={field.value} isAvatar={isAvatar} />
                ) : isAvatar ? (
                  <div className="bg-gray-f9 w-[250px] h-[250px] rounded-full"></div>
                ) : null}
                <div
                  className={cn(
                    "flex",
                    !isAvatar && "justify-between",
                    isAvatar && "justify-center"
                  )}
                >
                  <label
                    className={cn(
                      "relative",
                      buttonVariants({
                        variant: isAvatar ? "cardControl" : "default",
                      })
                    )}
                  >
                    {chooseFileButtonText}
                    <input
                      className="absolute top-0 right-0 min-w-full min-h-full text-right opacity-0 outline-none bg-white cursor-inherit block"
                      type="file"
                      accept="image/jpeg, image/png"
                      onChange={async (event) => {
                        const file = event.target.files?.[0]
                        if (!file) return

                        const image = await upload(file)
                        field.onChange(image.id)
                      }}
                    />
                  </label>
                  {field.value !== null && (
                    <Button
                      type="button"
                      variant="ghost"
                      size="icon"
                      onClick={() => {
                        field.onChange(null)
                      }}
                    >
                      <img {...closeCircle} alt="Remove" />
                    </Button>
                  )}
                </div>
              </CardContent>
            </Card>
          </FormControl>
          <FormMessage className="font-normal text-xs" />
        </FormItem>
      )}
    />
  )
}

const query = gql(/* GraphQL */ `
  query ImageField($imageId: ID!) {
    node(id: $imageId) {
      __typename
      ... on Image {
        height
        width
        filename
        contentType
        formattedDimensions
        imgixUrl
      }
    }
  }
`)

const ImagePreview = ({
  imageId,
  isAvatar = false,
}: {
  imageId: string
  isAvatar?: boolean
}) => {
  const { startPolling, stopPolling, ...result } = useQuery(query, {
    variables: { imageId },
  })
  const image = gqlMatchOptional(result.data?.node, "Image")

  useEffect(() => {
    if (image && !image.formattedDimensions) {
      startPolling(500)
    }
  }, [image, startPolling])

  useEffect(() => {
    if (image?.formattedDimensions) {
      stopPolling()
    }
  }, [image?.formattedDimensions, stopPolling])

  if (result.loading) return <LoadingIndicatorCentered />
  if (result.error) return <GraphqlError error={result.error} />

  invariant(image)
  const imageSize = isAvatar ? 256 : 40
  return (
    <div className="grid grid-cols-[auto_1fr] gap-3">
      <Imgix
        src={image.imgixUrl}
        width={imageSize}
        height={imageSize}
        className={cn(isAvatar && "rounded-full bg-cover")}
      />
      {!isAvatar && (
        <div className="overflow-hidden">
          <div className="truncate text-sm">{image.filename}</div>
          <div className="text-xs-plus text-gray-99">
            {formatContentType(image.contentType)} - {image.formattedDimensions}
          </div>
        </div>
      )}
    </div>
  )
}

function formatContentType(contentType: string) {
  switch (contentType) {
    case "image/jpeg":
      return "JPEG"
    case "image/png":
      return "PNG"
    default:
      return contentType
  }
}
