import { zodResolver } from "@hookform/resolvers/zod"
import { useFieldArray, useForm } from "react-hook-form"
import { Link, useNavigate } from "react-router-dom"
import { z } from "zod"
import { gql } from "~/__generated__"
import { ClientVisibility, CompanyRole, Role } from "~/__generated__/graphql"
import { usersPath } from "~/common/paths"
import { useSafeMutation } from "~/common/use-safe-mutation"
import { displayErrors } from "~/common/validations"
import { StyledFormMessage } from "~/forms/styled-form-message"
import { Button } from "~/ui/button"
import { Form } from "~/ui/form"
import { useToast } from "~/ui/use-toast"
import { TextField } from "~/fields/text-field"
import { SelectField } from "~/fields/select-field"
import { CompanySelector } from "./company-selector"

export const InviteUserPane = () => {
  const { toast } = useToast()
  const navigate = useNavigate()

  const formSchema = z.object({
    firstName: z.string(),
    lastName: z.string(),
    title: z.string(),
    email: z
      .string()
      .trim()
      .min(1, { message: "Enter an email" })
      .email({ message: "Email is invalid" }),
    role: z.nativeEnum(Role),
    clientVisibility: z.nativeEnum(ClientVisibility),
    reportingContact: z.boolean().optional(),
    creativeContact: z.boolean().optional(),
    companyMemberships: z.array(
      z.object({
        companyId: z.string(),
        companyRole: z.nativeEnum(CompanyRole).optional(),
        creativeContact: z.boolean().optional(),
        reportingContact: z.boolean().optional(),
      })
    ),
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      title: "",
      email: "",
      role: Role.Client,
      clientVisibility: ClientVisibility.Visible,
      reportingContact: false,
      creativeContact: false,
      companyMemberships: [
        {
          companyId: "",
          companyRole: CompanyRole.Client,
          creativeContact: false,
          reportingContact: false,
        },
      ],
    },
  })

  const {
    fields: companyFields,
    remove: removeCompany,
    append: appendCompany,
  } = useFieldArray({
    name: "companyMemberships",
    control: form.control,
  })

  const [runMutation, { loading }] = useSafeMutation(USER_INVITE_MUTATION)

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const { errors } = await runMutation({
      variables: {
        input: {
          userInput: {
            firstName: values.firstName,
            lastName: values.lastName,
            title: values.title,
            email: values.email,
            role: values.role,
            ...(isClient && {
              companyMemberships: values.companyMemberships.map((company) => ({
                companyId: company.companyId,
                companyRole: company.companyRole,
                creativeContact: company.creativeContact,
                reportingContact: company.reportingContact,
              })),
            }),
          },
        },
      },
    })

    const setFieldError = (field: any, message: string | undefined) => {
      form.setError(field, { message })
    }

    const showToast = (title: string, description?: string) => {
      toast({
        title: title,
        description: description,
        variant: "destructive",
      })
    }

    if (errors) {
      displayErrors(errors, setFieldError, showToast)
    } else {
      navigate(usersPath({}))
    }
  }

  const roleOptions = [
    { label: "Client", value: Role.Client },
    { label: "Creative", value: Role.Creative },
    { label: "Workweek Team", value: Role.WorkweekTeam },
    { label: "Workweek Admin", value: Role.WorkweekAdmin },
  ]

  const { watch } = form
  const role = watch("role")

  const isClient = role === Role.Client

  return (
    <div className="border-l w-64 p-4">
      <h2 className="font-medium text-lg mb-4">Invite User</h2>

      <Form {...form}>
        {Object.keys(form.formState.errors).length > 0 && (
          <div className="my-3">
            <StyledFormMessage>
              <div>Missing required* fields</div>
            </StyledFormMessage>
          </div>
        )}
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="space-y-4">
            <TextField
              control={form.control}
              name="firstName"
              label="First name*"
              required
            />
            <TextField
              control={form.control}
              name="lastName"
              label="Last name*"
              required
            />
            <TextField
              control={form.control}
              name="title"
              label="Title*"
              required
            />
            <TextField
              control={form.control}
              name="email"
              label="Email*"
              required
            />
            <SelectField
              control={form.control}
              name="role"
              label="Role*"
              options={roleOptions}
              text={(field) =>
                roleOptions.find((option) => option.value === field.value)
                  ?.label || "Select a role"
              }
            />

            {isClient && (
              <CompanySelector
                form={form}
                onRemove={removeCompany}
                fields={companyFields}
                onAppend={() =>
                  appendCompany({
                    companyId: "",
                    companyRole: CompanyRole.Client,
                    creativeContact: undefined,
                    reportingContact: undefined,
                  })
                }
              />
            )}
          </div>

          <div className="bg-gray-50 rounded text-center py-4 px-3 text-sm mt-6">
            This user will not be added into Salesforce
          </div>

          <div className="flex justify-between items-center mt-6">
            <Button type="submit" disabled={loading} className="font-light">
              Send invite
            </Button>
            <Link to={usersPath({})} className="text-xs-plus text-gray-400">
              Cancel
            </Link>
          </div>
        </form>
      </Form>
    </div>
  )
}

const USER_INVITE_MUTATION = gql(`
  mutation userInvite($input: UserInviteInput!) {
    userInvite(input: $input) {
      user {
        id
        firstName
        lastName
        title
        email
        role
      }
    }
  }
`)
