import {
  CampaignDeliverableDetailFragmentFragment,
  CampaignDeliverableStatus,
} from "~/__generated__/graphql"
import calendar from "~/images/icons/calendar2"
import externalLink from "~/images/icons/external-link"
import { Anchor } from "~/ui/link"
import Text from "~/ui/typography"

export const CampaignScheduledPublishedBanner: React.FC<{
  campaignDeliverable: CampaignDeliverableDetailFragmentFragment
}> = ({ campaignDeliverable }) => {
  const isScheduledOrPublished =
    campaignDeliverable.status === CampaignDeliverableStatus.Scheduled ||
    campaignDeliverable.status === CampaignDeliverableStatus.Published

  const hasSailthruCampaignUrl =
    campaignDeliverable.sailthruCampaignUrl &&
    campaignDeliverable.sailthruCampaignUrl.length > 0

  if (!isScheduledOrPublished) {
    return null
  }

  return (
    <div className="rounded-lg bg-gray-f9 p-4 flex items-center justify-between mb-4">
      <div className="flex items-center justify-between gap-6">
        <img {...calendar} alt="" className="inline-block align-baseline" />
        <div className="flex flex-col">
          <Text variant="body-12" className="text-gray-400">
            {campaignDeliverable.status ===
              CampaignDeliverableStatus.Scheduled && "Scheduled"}
            {campaignDeliverable.status ===
              CampaignDeliverableStatus.Published && "Published"}
          </Text>
          <Text variant="body-14-medium">
            {campaignDeliverable.scheduledForFormatted}
          </Text>
        </div>
      </div>
      {hasSailthruCampaignUrl && campaignDeliverable.sailthruCampaignUrl && (
        <div>
          <Anchor
            href={campaignDeliverable.sailthruCampaignUrl}
            target="_blank"
            className="bg-white border border-gray-d0 shadow text-sm -tracking-001 h-8 w-8 p-2 rounded flex items-center justify-center"
          >
            <img
              {...externalLink}
              alt=""
              className="inline-block align-baseline w-3 h-3"
            />
          </Anchor>
        </div>
      )}
    </div>
  )
}
