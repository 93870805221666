import { useQuery } from "@apollo/client"
import { useOutlet, useParams } from "react-router-dom"
import { Params } from "static-path"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import { CampaignUserSection } from "~/campaigns/user-section"
import {
  companyProfileEditPath,
  companyProfilePath,
  companyProfileUserPath,
  companyUsersPath,
} from "~/common/paths"
import edit2 from "~/images/icons/edit-2"
import externalLink12 from "~/images/icons/external-link-12"
import { TablePageLayout } from "~/layouts/table-page-layout"
import { Empty } from "~/ui/empty"
import { ErrorBox, GraphqlError } from "~/ui/errors"
import { Heading } from "~/ui/heading"
import { Link } from "~/ui/link"
import { LinkButton } from "~/ui/link-button"
import { LoadingIndicatorCentered } from "~/ui/loading-indicator"
import Text from "~/ui/typography"
import { CampaignBlock } from "./campaign-block"
import { CompanyLogo } from "./company-logo"

const query = gql(/* GraphQL */ `
  query CompanyDetailQuery($slug: String!) {
    company(slug: $slug) {
      ...CompanyDetail_company
    }
  }
`)

gql(/* GraphQL */ `
  fragment CompanyDetailsCampaign on Campaign {
    id
    campaignName
    campaignDeliverablesCount
    campaignDeliverablesCompletedCount
    closeDate
  }
`)

gql(/* GraphQL */ `
  fragment CompanyDetail_company on Company {
    id
    slug
    name
    description
    website
    categories
    ...CompanyLogo
    address {
      ...AutocompleteAddress
    }
    users(first: 200) {
      edges {
        node {
          id
          ...UserCard
        }
      }
    }
    workweekMembers(first: 200) {
      edges {
        node {
          id
          ...UserCard
        }
      }
    }
    canUpdate {
      value
    }
    campaigns(first: 100, orderBy: COMPLETION) {
      edges {
        node {
          ...CompanyDetailsCampaign
        }
      }
      pageInfo {
        ...Pagination
      }
    }
  }
`)

export const CompanyDetailScreen = () => {
  const params = useParams() as Params<typeof companyProfilePath.pattern>

  const result = useQuery(query, {
    variables: {
      slug: params.slug,
    },
  })
  const outlet = useOutlet()

  if (result.loading) {
    return <LoadingIndicatorCentered />
  }
  if (result.error) {
    return <GraphqlError error={result.error} />
  }

  invariant(result.data)

  const company = result.data.company

  if (!company) {
    return <ErrorBox>Company not found</ErrorBox>
  }

  return (
    <TablePageLayout padding={false} rightSideSlot={outlet}>
      <div className="grid grid-cols-[minmax(650px,1fr),474px] gap-chunky p-chunky">
        <div>
          <div className="flex flex-col bg-gray-f9 rounded-lg mb-8">
            <div className="flex justify-between">
              <div className="p-8">
                <CompanyLogo company={company} size="32" />
              </div>

              <div className="p-4 self-start flex gap-2">
                {company.canUpdate.value ? (
                  <LinkButton
                    variant="cardControl"
                    size="cardControl"
                    to={companyProfileEditPath({
                      slug: company.slug,
                    })}
                  >
                    <img {...edit2} alt="" className="me-1" />
                    Edit
                  </LinkButton>
                ) : null}
              </div>
            </div>
            <div className="px-8 pb-8">
              <Text as="div" variant="title-24">
                {company.name}
              </Text>
            </div>
          </div>
          <dl className="px-chunky">
            <CompanySection
              title="Company Description"
              body={
                company.description ? (
                  <div className="whitespace-pre-wrap">
                    {company.description}
                  </div>
                ) : (
                  <Empty />
                )
              }
            />
            <CompanySection
              title="Company website"
              body={
                company.website ? (
                  <a
                    href={company.website}
                    target="_blank"
                    rel="noreferrer"
                    className="flex items-center"
                  >
                    {company.website}
                    <img {...externalLink12} alt="" className="ms-1" />
                  </a>
                ) : (
                  <Empty />
                )
              }
            />
            <CompanySection
              title="Address"
              body={
                company.address ? (
                  <div>
                    <div>
                      {company.address.streetNumber} {company.address.route}
                    </div>
                    <div>
                      {company.address.locality},{" "}
                      {company.address.administrativeAreaLevel1}{" "}
                      {company.address.postalCode}
                    </div>
                  </div>
                ) : (
                  <Empty />
                )
              }
            />
            <CompanySection
              title="Categories"
              body={
                company.categories.length > 0 ? (
                  <ul>
                    {company.categories.map((category) => (
                      <li key={category}>{category}</li>
                    ))}
                  </ul>
                ) : (
                  <Empty />
                )
              }
            />
          </dl>

          <div className="px-chunky space-y-4">
            <Heading title="Campaigns" count={company.campaigns.edges.length} />
            <div>
              {company.campaigns?.edges.map(({ node }) => (
                <CampaignBlock key={node.id} campaign={node} />
              ))}
            </div>
          </div>
        </div>
        <div className="grid gap-4 content-start">
          <CampaignUserSection
            title="Workweek members"
            linkTo={(user) =>
              companyProfileUserPath({ slug: company.slug, userId: user.id })
            }
            users={company.workweekMembers.edges.map(({ node }) => node)}
            topRightSlot={
              company.canUpdate.value ? (
                <Link
                  variant="close-pane"
                  to={companyUsersPath({ slug: company.slug })}
                  className="ms-auto"
                >
                  Manage Users
                </Link>
              ) : null
            }
          />

          <CampaignUserSection
            title="Company members"
            linkTo={(user) =>
              companyProfileUserPath({ slug: company.slug, userId: user.id })
            }
            users={company.users.edges.map(({ node }) => node)}
          />
        </div>
      </div>
    </TablePageLayout>
  )
}

const CompanySection: React.FC<{
  title: string
  body: React.ReactNode
}> = ({ title, body }) => {
  return (
    <>
      <dt className="mb-2">
        <Text as="div" variant="body-10" className="text-gray-99">
          {title}
        </Text>
      </dt>
      <dd className="mb-6">{body}</dd>
    </>
  )
}
