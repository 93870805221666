import { UseFormReturn } from "react-hook-form"
import { z } from "zod"
import { metricsFormSchema } from "~/campaigns/campaign-metrics-screen"
import { CampaignTotals } from "../campaign-totals"

export const CampaignPodcastTotals: React.FC<{
  form: UseFormReturn<z.infer<typeof metricsFormSchema>>
}> = ({ form }) => {
  const { watch } = form
  const campaignDeliverables = watch("deliverables")
  const nonPendingDeliverables = campaignDeliverables.filter(
    (deliverable) => !deliverable.metricsPending
  )
  const totalDownloads7 = nonPendingDeliverables.reduce(
    (sum, deliverable) =>
      sum + parseInt(deliverable.downloads7DaysPostLaunchMetric || "0"),
    0
  )
  const totalDownloads30 = nonPendingDeliverables.reduce(
    (sum, deliverable) =>
      sum + parseInt(deliverable.downloads30DaysPostLaunchMetric || "0"),
    0
  )
  const totalImpressions7 = nonPendingDeliverables.reduce(
    (sum, deliverable) =>
      sum + parseInt(deliverable.impressions7DaysPostLaunchMetric || "0"),
    0
  )
  const totalImpressions30 = nonPendingDeliverables.reduce(
    (sum, deliverable) =>
      sum + parseInt(deliverable.impressions30DaysPostLaunchMetric || "0"),
    0
  )
  const totalImpressions = nonPendingDeliverables.reduce(
    (sum, deliverable) => sum + parseInt(deliverable.impressionsMetric || "0"),
    0
  )

  return (
    <CampaignTotals
      totals={[
        {
          value: totalDownloads7,
          label: "Total Downloads 7 days post launch",
        },
        {
          value: totalDownloads30,
          label: "Total Downloads 30 days post launch",
        },
        {
          value: totalImpressions7,
          label: "Total Impressions 7 days post launch",
        },
        {
          value: totalImpressions30,
          label: "Total Impressions 30 days post launch",
        },
        {
          value: totalImpressions,
          label: "Total Impressions on YouTube Video",
        },
      ]}
    />
  )
}
