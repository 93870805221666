import { useQuery } from "@apollo/client"
import { useEffect } from "react"
import { Outlet, useMatch, useNavigate } from "react-router-dom"
import { companyPath, rootPath, signInPath } from "~/common/paths"
import { CompanyProvider } from "~/companies/company-context"
import { companyQuery } from "~/companies/require-company"
import { UserDialogContextProvider } from "~/directory/UserDialogContext"
import { useViewerMaybe } from "./viewer-context"

export const RequireUserSignedOut = () => {
  const navigate = useNavigate()
  const { viewer, result } = useViewerMaybe()

  useEffect(() => {
    if (viewer) {
      navigate(rootPath.pattern)
    }
  }, [viewer, navigate])

  if (result.loading) return null

  return <Outlet />
}

export const RequireUserSignedIn = () => {
  const navigate = useNavigate()
  const { viewer, result } = useViewerMaybe()

  useEffect(() => {
    if (!result.loading && !viewer) {
      navigate(
        signInPath({}) +
          "?" +
          new URLSearchParams({ returnTo: window.location.pathname })
      )
    }
  }, [navigate, result.loading, viewer])
  const match = useMatch(companyPath.pattern + "/*")

  const companyResult = useQuery(companyQuery, {
    variables: { slug: match?.params.slug ?? "" },
    skip: !match,
  })

  if (result.loading) return null
  return (
    <CompanyProvider company={companyResult.data?.company ?? null}>
      <UserDialogContextProvider>
        <Outlet />
      </UserDialogContextProvider>
    </CompanyProvider>
  )
}
