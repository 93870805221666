import { Room, User } from "~/__generated__/graphql"

interface OtherUserInterface extends Pick<User, "name"> {}
interface RoomNameInterface extends Pick<Room, "name" | "defaultName"> {
  dmOtherUser?: OtherUserInterface | null
}

export const roomDisplayName = (room: RoomNameInterface) => {
  if (room.dmOtherUser) {
    return room.dmOtherUser.name
  } else if (room.name && room.name.length > 0) {
    return room.name
  } else {
    return room.defaultName
  }
}
