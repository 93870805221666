import React from "react"
import { ChangeEvent } from "react"
import searchIcon from "~/images/icons/search"
import { Input } from "~/ui/input"

type SearchInputProps = {
  value: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  placeholder: string
}

export const SearchInput = React.forwardRef(
  (
    { value, onChange, placeholder }: SearchInputProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <div className="relative w-[367px]">
        <img
          {...searchIcon}
          className="absolute left-3 top-1/2 -translate-y-1/2 pointer-events-none"
          alt="Search Icon"
        />
        <Input
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className="text-xs-plus placeholder:text-gray-99 font-medium py-0 pl-8 h-8 tracking-tight"
          ref={ref}
        />
      </div>
    )
  }
)
