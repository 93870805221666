import invariant from "tiny-invariant"
import { z } from "zod"

export const getMetaVarMaybe = (name: string) => {
  const metaTag = document.querySelector(`meta[name=${name}]`)
  invariant(metaTag)
  const content = metaTag.getAttribute("content")

  return content
}

export const getMetaVar = (name: string) => {
  const content = getMetaVarMaybe(name)
  invariant(content)

  return content
}

const flashMessageListSchema = z.array(z.tuple([z.string(), z.string()]))

const convertFlashMessages = (
  messageList: z.infer<typeof flashMessageListSchema>
) =>
  messageList.map(([type, message]) => {
    return { type, message }
  })

export const getMetaVarFlashMessageList = () => {
  const content = getMetaVarMaybe("flash-messages")
  if (!content) return null

  const data = JSON.parse(content)
  return convertFlashMessages(flashMessageListSchema.parse(data))
}

/** returns the first flash message */
export const getMetaVarFlashMessage = () => {
  const messages = getMetaVarFlashMessageList()
  if (!messages) return null

  return messages[0]
}
