import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { Link, useNavigate } from "react-router-dom"
import { z } from "zod"
import { gql } from "~/__generated__"
import { ClientVisibility, CompanyRole, Role } from "~/__generated__/graphql"
import { companyUsersPath } from "~/common/paths"
import { useSafeMutation } from "~/common/use-safe-mutation"
import { useValidationErrors } from "~/common/use-validation-errors"
import { useCompany } from "~/companies/company-context"
import { CheckboxField } from "~/fields/checkbox-field"
import { SelectField } from "~/fields/select-field"
import { TextField } from "~/fields/text-field"
import { StyledFormMessage } from "~/forms/styled-form-message"
import { Button } from "~/ui/button"
import { Form } from "~/ui/form"

export const InviteCompanyUserPane = () => {
  const navigate = useNavigate()
  const { company } = useCompany()

  const formSchema = z.object({
    firstName: z.string(),
    lastName: z.string(),
    title: z.string(),
    email: z
      .string()
      .trim()
      .min(1, { message: "Enter an email" })
      .email({ message: "Email is invalid" }),
    role: z.nativeEnum(CompanyRole),
    clientVisibility: z.nativeEnum(ClientVisibility),
    reportingContact: z.boolean().optional(),
    creativeContact: z.boolean().optional(),
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      title: "",
      email: "",
      role: CompanyRole.Client,
      clientVisibility: ClientVisibility.Visible,
      reportingContact: false,
      creativeContact: false,
    },
  })

  const [runMutation, mutationResult] = useSafeMutation(USER_INVITE_MUTATION)
  useValidationErrors(form.setError, mutationResult)

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const { errors } = await runMutation({
      variables: {
        input: {
          userInput: {
            firstName: values.firstName,
            lastName: values.lastName,
            title: values.title,
            email: values.email,
            role: Role.Client,
          },
          creativeContact: values.creativeContact,
          reportingContact: values.reportingContact,
          companyId: company.id,
          companyRole: values.role,
        },
      },
    })

    if (!errors) {
      navigate(companyUsersPath({ slug: company.slug }))
    }
  }

  const roleOptions = [
    { label: "Client", value: CompanyRole.Client },
    { label: "Admin", value: CompanyRole.Admin },
  ]

  return (
    <div className="border-l w-64 p-4">
      <h2 className="font-medium text-lg mb-4">Invite User</h2>

      <Form {...form}>
        {Object.keys(form.formState.errors).length > 0 && (
          <div className="my-3">
            <StyledFormMessage>
              <div>Missing required* fields</div>
            </StyledFormMessage>
          </div>
        )}
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="space-y-4">
            <TextField
              control={form.control}
              name="firstName"
              label="First name*"
              required
            />
            <TextField
              control={form.control}
              name="lastName"
              label="Last name*"
              required
            />
            <TextField
              control={form.control}
              name="email"
              label="Email*"
              required
            />
            <TextField
              control={form.control}
              name="title"
              label="Title*"
              required
            />
            <SelectField
              control={form.control}
              name="role"
              label="Role*"
              options={roleOptions}
              text={(field) =>
                roleOptions.find((option) => option.value === field.value)
                  ?.label || "Select a role"
              }
            />
            <CheckboxField
              control={form.control}
              name="reportingContact"
              label="Reporting Contact"
            />
            <CheckboxField
              control={form.control}
              name="creativeContact"
              label="Creative Contact"
            />
          </div>

          <div className="bg-gray-50 rounded text-center py-4 px-3 text-sm mt-6">
            This user will not be added into Salesforce
          </div>

          <div className="flex justify-between items-center mt-6">
            <Button type="submit" className="font-light">
              Send invite
            </Button>
            <Link
              to={companyUsersPath({ slug: company.slug })}
              className="text-xs-plus text-gray-400"
            >
              Cancel
            </Link>
          </div>
        </form>
      </Form>
    </div>
  )
}

const USER_INVITE_MUTATION = gql(`
  mutation companyUserInvite($input: CompanyUserInviteInput!) {
    companyUserInvite(input: $input) {
      user {
        id
        firstName
        lastName
        title
        email
        role
      }
    }
  }
`)
