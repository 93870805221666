import { useFormContext } from "react-hook-form"
import { CampaignMetricsDeliverableFragment } from "~/__generated__/graphql"

export const DeliverablePendingField = ({
  deliverable,
  index,
}: {
  deliverable: CampaignMetricsDeliverableFragment
  index: number
}) => {
  const { register } = useFormContext()

  return (
    <input
      type="checkbox"
      className="rounded border-gray-500 checked:bg-black focus:checked:bg-black checked:hover:bg-black/80 focus:ring-black disabled:border-gray-200 disabled:checked:bg-gray-200 disabled:checked:hover:bg-gray-200 disabled:hover:bg-white"
      {...register(`deliverables.${index}.metricsPending`)}
    />
  )
}
