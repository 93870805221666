import { useState } from "react"
import { MinimalTiptapEditor } from "./minimal-tiptap"
import { JSONContent } from "@tiptap/react"

export const TiptapScreen: React.FC = () => {
  const [value, setValue] = useState<JSONContent>(initialContent)

  return (
    <div className="p-4">
      <div className="mb-4">
        <MinimalTiptapEditor
          value={value}
          onValueChange={(value) => {
            setValue(value)
          }}
        />
      </div>
      <pre>{JSON.stringify(value, null, 2)}</pre>
    </div>
  )
}

const initialContent: JSONContent = {
  type: "doc",
  content: [
    {
      type: "paragraph",
      content: [
        {
          type: "text",
          text: "hello ",
        },
        {
          type: "text",
          marks: [
            {
              type: "bold",
            },
          ],
          text: "world",
        },
        {
          type: "text",
          text: "!",
        },
      ],
    },
  ],
}
