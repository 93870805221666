import { format, parseISO } from "date-fns"
export function formatDatetime(input: Date | string) {
  const date = new Date(input)

  const dateString = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  })

  const timeString = date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "short",
    hour12: true,
  })

  return `${dateString} – ${timeString}`
}

/**
 * date is an iso8601 e.g. 2024-06-15
 * this formats it to the pattern passed or 'June 15, 2024' default
 */
export const formatDate = (
  date: string | Date,
  pattern: string = "MMMM dd, yyyy"
) => {
  // Using parseISO first allows us to format the date irrespective of local timezone.
  const isoDate = typeof date === "string" ? parseISO(date) : date
  return format(isoDate, pattern)
}

/**
 * Adjusts the input date to UTC
 */
export const toCalendarDate = (date: Date) => {
  const timezoneOffset = date.getTimezoneOffset()
  return new Date(date.getTime() + timezoneOffset * 60000)
}

interface TimeInterval {
  label: string
  seconds: number
}

export const formatRelativeTime = (dateString: string): string => {
  const date: Date = new Date(dateString)
  const now: Date = new Date()
  const diffInSeconds: number = Math.floor(
    (now.getTime() - date.getTime()) / 1000
  )

  const intervals: TimeInterval[] = [
    { label: "y", seconds: 31536000 },
    { label: "mo", seconds: 2592000 },
    { label: "d", seconds: 86400 },
    { label: "h", seconds: 3600 },
    { label: "m", seconds: 60 },
    { label: "s", seconds: 1 },
  ]

  for (const interval of intervals) {
    const count: number = Math.floor(diffInSeconds / interval.seconds)
    if (count >= 1) {
      return `${count}${interval.label}`
    }
  }

  return "now"
}
