import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/ui/form"
import { Control, FieldPath, FieldValues } from "react-hook-form"
import { InputHTMLAttributes } from "react"
import { cn } from "~/common/cn"
import { Input } from "../ui/input"

export const HexColorField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  description,
  label,
  labelClassName,
  inputClassName,
  ...inputProps
}: {
  label: string
  labelClassName?: string
  inputClassName?: string
  description?: string
  control: Control<TFieldValues> | undefined
  name: TName
} & InputHTMLAttributes<HTMLInputElement>) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel className={cn(labelClassName, "font-normal")}>
            {label}
          </FormLabel>
          <FormControl>
            <div className="relative">
              <Input {...field} className="pr-8" />
              <div className="absolute end-0 top-0 bottom-0 flex items-center pr-2">
                <HexColorCirlce color={field.value} />
              </div>
            </div>
          </FormControl>
          <FormMessage className="font-normal text-xs" />
        </FormItem>
      )}
    />
  )
}

const HexColorCirlce = (props: { color: string }) => {
  const withHash = /^#/.test(props.color) ? props.color : `#${props.color}`
  const isValidColor = /^#[0-9A-F]{6}$/i.test(withHash)

  const color = isValidColor ? withHash : undefined
  return (
    <div
      className="w-4 h-4 rounded-full bg-gray-200"
      style={{ backgroundColor: color }}
    />
  )
}
