import { useLocation, useNavigate } from "react-router-dom"

export const useGoBack = (fallbackPath: string) => {
  const location = useLocation()
  const navigate = useNavigate()

  const goBack = () => {
    const isBackAvailable = location.key !== "default"
    if (isBackAvailable) {
      navigate(-1)
    } else {
      navigate(fallbackPath)
    }
  }

  return goBack
}
