import { GraphQLError } from "graphql"
import { useCallback, useEffect, useMemo, useState } from "react"
import { unstable_usePrompt } from "react-router-dom"
import SparkMD5 from "spark-md5"
import { getGraphqlErrors } from "~/common/validations"

const UNSAVED_WORK_PROMPT =
  "You have unsaved changes, are you sure you want to leave?"

export const useDirtyTracking = ({
  savedTrackedObject,
  currentTrackedObject,
}: {
  savedTrackedObject: any
  currentTrackedObject: any
}) => {
  const [disableUnloadHandler, setDisableUnloadHandler] = useState(false)

  const savedMd5 = useMemo(() => {
    if (!savedTrackedObject) return null
    return SparkMD5.hash(JSON.stringify(savedTrackedObject))
  }, [savedTrackedObject])

  const currentMd5 = useMemo(() => {
    if (!currentTrackedObject) return null
    return SparkMD5.hash(JSON.stringify(currentTrackedObject))
  }, [currentTrackedObject])

  const isDirty = useMemo(() => {
    if (!currentMd5) return false
    if (!savedMd5) return true
    return currentMd5 !== savedMd5
  }, [currentMd5, savedMd5])

  const showBeforeUnload = useMemo(() => {
    return isDirty && !disableUnloadHandler
  }, [isDirty, disableUnloadHandler])

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault()
      event.returnValue = true
    }
    if (showBeforeUnload) {
      window.addEventListener("beforeunload", handleBeforeUnload)
    }
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload)
    }
  }, [showBeforeUnload])

  unstable_usePrompt({
    message: UNSAVED_WORK_PROMPT,
    when: showBeforeUnload,
  })

  const onSave = useCallback((_errors: readonly GraphQLError[] | undefined) => {
    const errors = getGraphqlErrors(_errors)
    // eslint-disable-next-line no-console
    console.log("on save errors", errors)

    if (errors?.some((e) => e.extensions.code === "CONTENT_VERSION")) {
      setDisableUnloadHandler(true)
    }
  }, [])

  return useMemo(
    () => ({
      isDirty,
      onSave,
      setDisableUnloadHandler,
    }),
    [isDirty, onSave]
  )
}
