import { Section } from "~/campaigns/section"
import Text from "~/ui/typography"

export const ToggleSection = ({
  label,
  name,
  value,
  onChange,
  children,
}: {
  label: string
  name: string
  value: boolean
  onChange: (value: boolean) => void
  children?: React.ReactNode
}) => {
  return (
    <Section variant="bordered">
      <div className="flex items-center">
        <div className="w-[160px]">
          <Text variant="body-14-medium">{label}</Text>
        </div>
        <div className="flex items-center gap-8">
          <label className="flex items-center gap-2">
            <input
              type="radio"
              name={name}
              checked={value}
              onChange={() => {
                onChange(true)
              }}
            />
            <Text variant="body-14">Preset</Text>
          </label>
          <label className="flex items-center gap-2">
            <input
              type="radio"
              name={name}
              checked={!value}
              onChange={() => {
                onChange(false)
              }}
            />
            <Text variant="body-14">None</Text>
          </label>
        </div>
      </div>
      {value && <div className="mt-4 space-y-4">{children}</div>}
    </Section>
  )
}
