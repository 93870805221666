import { CampaignDeliverableMetricsFragment } from "~/__generated__/graphql"
import { Subsection } from "~/campaigns/subsection"
import { SubsectionBody } from "~/campaigns/subsection-body"
import { SubsectionTitle } from "~/campaigns/subsection-title"
import { formatPercentage } from "~/common/percentage-formattingt"
import { calculatePercent } from "~/ui/percent"

export const EventsSidebarMetrics: React.FC<{
  campaignDeliverable: CampaignDeliverableMetricsFragment
}> = ({ campaignDeliverable }) => {
  const metrics = {
    registrants: campaignDeliverable.registrantsMetric ?? 0,
    liveAttendees: campaignDeliverable.liveAttendeesMetric ?? 0,
    opens: campaignDeliverable.opensMetric ?? 0,
    clicks: campaignDeliverable.totalClicksMetric ?? 0,
    notes: campaignDeliverable.metricNotes ?? "",
  }

  const clickThroughRate = calculatePercent(metrics.clicks, metrics.opens)

  return (
    <>
      <Subsection>
        <SubsectionTitle>Registrants</SubsectionTitle>
        <SubsectionBody>{metrics.registrants.toLocaleString()}</SubsectionBody>
      </Subsection>
      <Subsection>
        <SubsectionTitle>Live Attendees</SubsectionTitle>
        <SubsectionBody>
          {metrics.liveAttendees.toLocaleString()}
        </SubsectionBody>
      </Subsection>
      <Subsection>
        <SubsectionTitle>Opens</SubsectionTitle>
        <SubsectionBody>{metrics.opens.toLocaleString()}</SubsectionBody>
      </Subsection>
      <Subsection>
        <SubsectionTitle>Clicks</SubsectionTitle>
        <SubsectionBody>{metrics.clicks.toLocaleString()}</SubsectionBody>
      </Subsection>
      <Subsection>
        <SubsectionTitle>CTR</SubsectionTitle>
        <SubsectionBody>{formatPercentage(clickThroughRate)}</SubsectionBody>
      </Subsection>
      <Subsection>
        <SubsectionTitle>Notes</SubsectionTitle>
        <SubsectionBody>{metrics.notes ? metrics.notes : "–"}</SubsectionBody>
      </Subsection>
    </>
  )
}
