import { User_CardFragment } from "~/__generated__/graphql"
import { cn } from "~/common/cn"
import { dmPath } from "~/common/paths"
import { AvatarWithFallback } from "~/ui/AvatarWithFallback"
import { LoadingIndicatorCentered } from "~/ui/LoadingIndicator"

import { Card, CardContent, CardHeader, CardTitle } from "~/ui/card"
import { Dialog, DialogContent } from "~/ui/dialog"
import { LinkButton } from "~/ui/link-button"

import { format } from "date-fns"
import { useCurrentUser } from "~/auth/viewer-context"
import EnvelopeOpen from "~/images/icons/envelope-open.svg?react"
import { LoadingIndicator } from "~/ui/LoadingIndicator"

export const UserDialog = ({
  isOpen,
  onClose,
  user,
  isLoading = false,
  isError = false,
}: {
  isOpen: boolean
  onClose: () => void
  user: User_CardFragment | null
  isLoading?: boolean
  isError?: boolean
}) => {
  const { currentUser } = useCurrentUser()

  return (
    <Dialog open={isOpen} onOpenChange={(isOpen) => !isOpen && onClose()}>
      <DialogContent
        onEscapeKeyDown={onClose}
        className="p-0 outline-0 bg-transparent border-0"
      >
        <Card className={cn("rounded-lg [box-shadow:none]")}>
          <CardHeader className="flex-row items-center justify-between">
            <CardTitle>Directory Contact</CardTitle>
          </CardHeader>
          <CardContent className={cn("px-6 py-4 tracking-[0.5px]")}>
            {isError ? (
              <div className="h-[200px] flex items-center justify-center">
                Error loading directory card.
              </div>
            ) : isLoading ? (
              <div className="h-[200px] flex items-center justify-center">
                <LoadingIndicator />
              </div>
            ) : user ? (
              <>
                <div className="flex flex-col gap-3 items-center sm:flex-row">
                  <AvatarWithFallback user={user} size="dialog" />
                  <div className="flex-1 flex flex-col items-center sm:block">
                    <div className="text-center sm:text-left">
                      <div className="flex items-end gap-2">
                        <div className="text-[20px] leading-[23px] font-semibold">
                          {user.name}
                        </div>
                      </div>
                    </div>
                    {user.jobTitle && user.jobTitle.length > 0 && (
                      <div className="mt-2 text-2xs">{user.jobTitle}</div>
                    )}
                    {user.companyName?.length && (
                      <div className={cn("mt-[2px] text-[#999999] text-2xs")}>
                        {user.companyName}
                      </div>
                    )}
                  </div>
                  {user.id !== currentUser.id && (
                    <LinkButton
                      className="text-2xs"
                      to={dmPath({ otherUserId: user.id })}
                    >
                      <EnvelopeOpen className="mr-2" />
                      Message
                    </LinkButton>
                  )}
                </div>

                <div className="mt-4 text-2xs">
                  Member Since:{" "}
                  {format(new Date(user.createdAt), "MMM d, yyyy")}
                </div>
                {isLoading && (
                  <div className="mt-4">
                    <LoadingIndicatorCentered />
                  </div>
                )}
              </>
            ) : null}
          </CardContent>
        </Card>
      </DialogContent>
    </Dialog>
  )
}
