import { Heading } from "~/ui/heading"
import Text from "~/ui/typography"

export const CampaignTotals = ({
  totals,
}: {
  totals: Array<{ value: number | string; label: string }>
}) => {
  return (
    <div className="grid gap-6 border-t border-gray-d0 py-6 -mx-10 px-10">
      <Heading title="Total & Averages" />

      <div className="px-8 border rounded-md border-gray-d0 flex items-center justify-between divide-x">
        {totals.map(({ value, label }) => (
          <div
            key={label}
            className="flex flex-col items-center gap-1 flex-1 py-8"
          >
            <Text variant="body-24-medium">{value.toLocaleString()}</Text>
            <Text variant="body-12" className="text-gray-400">
              {label}
            </Text>
          </div>
        ))}
      </div>
    </div>
  )
}
