import { ApolloError } from "@apollo/client"
import { GraphQLError } from "graphql"

export type SetFieldErrorType = (
  field: string,
  message: string | undefined
) => void

/** based on app/graphql/validation_error.rb */
type ValidationError = {
  field: string
  resource: string
  fullMessage: string
  message: string
  type: string
}

export type ValidationErrorPayload = {
  extensions: {
    code: "VALIDATION_ERROR"
    validationErrors: Array<ValidationError>
  }
  message: string
}

const showFieldErrors = (
  key: string,
  errorMessage: string,
  setFieldError: SetFieldErrorType
) => {
  setFieldError(key, errorMessage)
}

const isApolloError = (
  t: readonly GraphQLError[] | ApolloError
): t is ApolloError => {
  return (t as ApolloError).graphQLErrors !== undefined
}

export const getGraphqlErrors = (
  _errors?: readonly GraphQLError[] | ApolloError
) => {
  if (!_errors) return
  let errors: GraphQLError[] = []

  if (isApolloError(_errors)) {
    errors = [..._errors.graphQLErrors]
  } else {
    errors = [..._errors]
  }

  return errors
}

/** @deprecated use useValidationErrors instead */
export const displayErrors = (
  _errors?: readonly GraphQLError[] | ApolloError,
  setFieldError?: SetFieldErrorType,
  showToast?: (title: string, description?: string) => void
) => {
  if (!_errors) return
  const errors = getGraphqlErrors(_errors)

  const isValidationError = errors?.find(
    (error) => error.extensions?.code === "VALIDATION_ERROR"
  )

  if (isValidationError) {
    const typedValidationErrors =
      isValidationError as unknown as ValidationErrorPayload
    showToast?.(typedValidationErrors.message || "Validation error occurred")

    if (setFieldError) {
      for (const validationError of typedValidationErrors.extensions
        .validationErrors) {
        showFieldErrors(
          validationError.field,
          validationError.fullMessage,
          setFieldError
        )
      }
    }
  } else if (isApolloError(_errors)) {
    showToast?.(_errors.message || "An error occurred, please try again")
  }
}
