import { CampaignDeliverableMetricsFragment } from "~/__generated__/graphql"
import { Subsection } from "~/campaigns/subsection"
import { SubsectionBody } from "~/campaigns/subsection-body"
import { SubsectionTitle } from "~/campaigns/subsection-title"
import { formatPercentage } from "~/common/percentage-formattingt"
import { calculatePercent } from "~/ui/percent"

export const BrandedArticlesSidebarMetrics: React.FC<{
  campaignDeliverable: CampaignDeliverableMetricsFragment
}> = ({ campaignDeliverable }) => {
  const metrics = {
    timeOnSiteMetric: campaignDeliverable.timeOnSiteMetric ?? "00:00",
    totalClicksMetric: campaignDeliverable.totalClicksMetric ?? 0,
    pageViewsMetric: campaignDeliverable.pageviewsMetric ?? 0,
    notes: campaignDeliverable.metricNotes ?? "",
  }

  const clickThroughRate = calculatePercent(
    metrics.totalClicksMetric,
    metrics.pageViewsMetric
  )

  return (
    <>
      <Subsection>
        <SubsectionTitle>Time on Site</SubsectionTitle>
        <SubsectionBody>
          {metrics.timeOnSiteMetric.toLocaleString()}
        </SubsectionBody>
      </Subsection>
      <Subsection>
        <SubsectionTitle>Pageviews</SubsectionTitle>
        <SubsectionBody>
          {metrics.pageViewsMetric.toLocaleString()}
        </SubsectionBody>
      </Subsection>
      <Subsection>
        <SubsectionTitle>Clicks</SubsectionTitle>
        <SubsectionBody>
          {metrics.totalClicksMetric.toLocaleString()}
        </SubsectionBody>
      </Subsection>
      <Subsection>
        <SubsectionTitle>CTR</SubsectionTitle>
        <SubsectionBody>{formatPercentage(clickThroughRate)}</SubsectionBody>
      </Subsection>
      <Subsection>
        <SubsectionTitle>Notes</SubsectionTitle>
        <SubsectionBody>{metrics.notes ? metrics.notes : "–"}</SubsectionBody>
      </Subsection>
    </>
  )
}
