import { useLazyQuery } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import { useEffect } from "react"
import { useForm, UseFormReturn } from "react-hook-form"
import { z } from "zod"
import { gql } from "~/__generated__"
import {
  CampaignDeliverableStatus,
  CampaignDeliverableType,
} from "~/__generated__/graphql"
import { toCalendarDate } from "~/common/date-formatting"
import { useSafeMutation } from "~/common/use-safe-mutation"
import { useValidationErrors } from "~/common/use-validation-errors"
import { SelectField } from "~/fields/select-field"
import { TextField } from "~/fields/text-field"
import { Button } from "~/ui/button"
import { DatePicker } from "~/ui/date-picker"
import { Dialog, DialogContent } from "~/ui/dialog"
import { Form, FormControl, FormField, FormItem, FormLabel } from "~/ui/form"
import Text from "~/ui/typography"
import { toast } from "~/ui/use-toast"
import { CampaignDeliverableFormSchema } from "./campaign-deliverable-detail-screen"

const CAMPAIGN_DELIVERABLE_UPDATE_STATUS_MUTATION = gql(/* GraphQL */ `
  mutation campaignDeliveryUpdateStatus(
    $input: CampaignDeliverableUpdateStatusInput!
  ) {
    campaignDeliveryUpdateStatus(input: $input) {
      campaignDeliverable {
        id
        status
        scheduledForFormatted
        publishDateCanonical
        sailthruCampaignId
        sailthruCampaignUrl
        deliverableBriefDueDate
        assignedToCreativeDueDate
        awaitingCreatorApprovalDueDate
        awaitingAccountManagerDueDate
        awaitingClientApprovalDueDate
        approvedDueDate
        adScaffoldedDueDate
        inProductionDueDate
        ctaUrl
      }
    }
  }
`)

const SAILTHRU_CAMPAIGN_QUERY = gql(/* GraphQL */ `
  query SailthruCampaignQuery {
    sailthruCampaign {
      campaigns {
        blastId
        name
      }
    }
  }
`)

const SAILTHRU_BLASTS_QUERY = gql(/* GraphQL */ `
  query SailthruBlastsQuery($status: String!) {
    sailthruBlasts(status: $status) {
      blasts {
        blastId
        name
      }
    }
  }
`)

export const ChangeStatusModal = ({
  isOpen,
  onClose,
  campaignDeliverable,
  deliverableForm,
}: {
  isOpen: boolean
  onClose: () => void
  campaignDeliverable: any
  deliverableForm: UseFormReturn<CampaignDeliverableFormSchema>
}) => {
  const isNewsletterOrDeepDive =
    campaignDeliverable.deliverableType ===
      CampaignDeliverableType.NewsletterAds ||
    campaignDeliverable.deliverableType === CampaignDeliverableType.DeepDives

  const formSchema = z
    .object({
      status: z.nativeEnum(CampaignDeliverableStatus),
      sailthruCampaignId: z.string().optional(),
      publishDate: z.date().optional(),
      publishTime: z.string().optional(),
      publishTimezone: z.string().optional(),
      ctaUrl: z.string().optional(),
    })
    .superRefine((data, ctx) => {
      if (
        [
          CampaignDeliverableStatus.Scheduled,
          CampaignDeliverableStatus.Published,
        ].includes(data.status)
      ) {
        if (!data.publishDate) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Date cannot be empty",
            path: ["publishDate"],
          })
        }

        if (
          !data.ctaUrl &&
          campaignDeliverable.deliverableType ===
            CampaignDeliverableType.NewsletterAds
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Call to Action Url cannot be blank",
            path: ["ctaUrl"],
          })
        }

        if (!data.sailthruCampaignId && isNewsletterOrDeepDive) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Sailthru Campaign cannot be empty",
            path: ["sailthruCampaignId"],
          })
        }

        if (!data.publishDate || !data.ctaUrl || !data.sailthruCampaignId) {
          return false
        }
      }
      return true
    })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    values: {
      status: campaignDeliverable.status,
      publishDate: campaignDeliverable.publishDateCanonical
        ? toCalendarDate(new Date(campaignDeliverable.publishDateCanonical))
        : undefined,
      publishTime:
        campaignDeliverable.publishTime ??
        new Date().toLocaleTimeString("en-US", {
          hour12: false,
          hour: "2-digit",
          minute: "2-digit",
        }),
      publishTimezone: campaignDeliverable.publishTimezone ?? "UTC",
      ctaUrl: campaignDeliverable.ctaUrl ?? "",
      sailthruCampaignId:
        campaignDeliverable.sailthruCampaignId?.toString() ?? "",
    },
  })

  const [exec, result] = useSafeMutation(
    CAMPAIGN_DELIVERABLE_UPDATE_STATUS_MUTATION
  )
  useValidationErrors(form.setError, result)

  const [
    sendScheduledBlastsQuery,
    { data: scheduledBlastsData, previousData: scheduledBlastsPreviousData },
  ] = useLazyQuery(SAILTHRU_BLASTS_QUERY, {
    variables: { status: "scheduled" },
  })

  const [
    sendPublishedBlastsQuery,
    { data: publishedBlastsData, previousData: publishedBlastsPreviousData },
  ] = useLazyQuery(SAILTHRU_BLASTS_QUERY, {
    variables: { status: "published" },
  })

  const [sendQuery, { data: currentData, previousData }] = useLazyQuery(
    SAILTHRU_CAMPAIGN_QUERY,
    {
      variables: {},
    }
  )

  useEffect(() => {
    if (!campaignDeliverable.sailthruCampaignId) return

    if (campaignDeliverable.status === CampaignDeliverableStatus.Published) {
      sendPublishedBlastsQuery()
    } else if (
      campaignDeliverable.status === CampaignDeliverableStatus.Scheduled
    ) {
      sendScheduledBlastsQuery()
    } else if (
      campaignDeliverable.status === CampaignDeliverableStatus.AdScaffolded
    ) {
      sendQuery()
    }
  }, [
    campaignDeliverable.sailthruCampaignId,
    campaignDeliverable.status,
    sendPublishedBlastsQuery,
    sendQuery,
    sendScheduledBlastsQuery,
  ])

  const data = currentData || previousData
  const templates = data?.sailthruCampaign?.campaigns ?? []
  const templateOptions = templates.map((template) => ({
    label: template.name,
    value: template.blastId,
  }))

  const scheduledBlasts = scheduledBlastsData || scheduledBlastsPreviousData
  const scheduledBlastOptions = scheduledBlasts
    ? scheduledBlasts?.sailthruBlasts?.blasts.map((blast) => ({
        label: blast.name,
        value: blast.blastId,
      }))
    : []

  const publishedBlasts = publishedBlastsData || publishedBlastsPreviousData
  const publishedBlastOptions = publishedBlasts
    ? publishedBlasts?.sailthruBlasts?.blasts.map((blast) => ({
        label: blast.name,
        value: blast.blastId,
      }))
    : []

  const formStatus = form.watch("status")
  const isDeepDive =
    campaignDeliverable.deliverableType === CampaignDeliverableType.DeepDives
  const isNewsletter =
    campaignDeliverable.deliverableType ===
    CampaignDeliverableType.NewsletterAds
  const statusIsAdScaffoldedOrPublishedOrScheduled =
    formStatus === CampaignDeliverableStatus.AdScaffolded ||
    formStatus === CampaignDeliverableStatus.Published ||
    formStatus === CampaignDeliverableStatus.Scheduled
  const showSailthruCampaignSelect =
    statusIsAdScaffoldedOrPublishedOrScheduled && (isDeepDive || isNewsletter)

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const result = await exec({
      variables: {
        input: {
          id: campaignDeliverable.id,
          deliverableInput: {
            productBriefId: deliverableForm.getValues().productBriefId,
            trackingUrls: deliverableForm.getValues().trackingUrls,
            keyDirection: deliverableForm.getValues().keyDirection,
            deliverableContent: deliverableForm.getValues().deliverableContent,
            ctaButton: deliverableForm.getValues().ctaButton,
            ctaUrl: values.ctaUrl,
          },
          status: values.status,
          ...(values.status !== CampaignDeliverableStatus.AdScaffolded && {
            publishDate: values.publishDate
              ? toCalendarDate(values.publishDate).toISOString()
              : new Date().toISOString(),
            publishTime: values.publishTime,
            publishTimezone: values.publishTimezone,
          }),
          ...(showSailthruCampaignSelect && {
            sailthruCampaignId: values.sailthruCampaignId,
          }),
        },
      },
    })

    if (!result.errors) {
      toast({
        title: "Deliverable Status updated",
        description: "The campaign deliverable status has been updated",
      })

      onClose()
    }
  }

  const statusLabelMap = {
    [CampaignDeliverableStatus.BriefNeeded]: "Brief Needed",
    [CampaignDeliverableStatus.AssignedToCreative]: "Assigned to Creative",
    [CampaignDeliverableStatus.AwaitingCreatorApproval]:
      "Awaiting Creator Approval",
    [CampaignDeliverableStatus.AwaitingAccountManagerApproval]:
      "Awaiting Account Manager Approval",
    [CampaignDeliverableStatus.AwaitingClientApproval]:
      "Awaiting Client Approval",
    [CampaignDeliverableStatus.Approved]: "Approved",
    [CampaignDeliverableStatus.InProduction]: "In Production",
    [CampaignDeliverableStatus.AdScaffolded]: "Ad Scaffolded",
    [CampaignDeliverableStatus.Scheduled]: "Scheduled",
    [CampaignDeliverableStatus.Published]: "Published",
  }

  const statusOptions = Object.entries(statusLabelMap)
    .filter(([status]) => {
      // For LeadGen, only show Published and InProduction
      if (
        campaignDeliverable.deliverableType === CampaignDeliverableType.LeadGen
      ) {
        return [
          CampaignDeliverableStatus.Published,
          CampaignDeliverableStatus.InProduction,
        ].includes(status as CampaignDeliverableStatus)
      }

      // For event and podcast guest types, only show specific statuses
      if (
        [
          CampaignDeliverableType.PodcastGuest,
          CampaignDeliverableType.LiveOrVirtualEventSpeakingFee,
          CampaignDeliverableType.LiveEvents,
          CampaignDeliverableType.VirtualEvents,
          CampaignDeliverableType.CustomLiveEvent,
          CampaignDeliverableType.CustomVirtualEvent,
          CampaignDeliverableType.CustomPodcastContent,
        ].includes(campaignDeliverable.deliverableType)
      ) {
        return [
          CampaignDeliverableStatus.BriefNeeded,
          CampaignDeliverableStatus.InProduction,
          CampaignDeliverableStatus.Scheduled,
          CampaignDeliverableStatus.Published,
        ].includes(status as CampaignDeliverableStatus)
      }

      // Exclude InProduction for specific deliverable types
      if (status === CampaignDeliverableStatus.InProduction) {
        return ![
          CampaignDeliverableType.NewsletterAds,
          CampaignDeliverableType.DeepDives,
          CampaignDeliverableType.Podcasts,
          CampaignDeliverableType.LeadGenMagnet,
          CampaignDeliverableType.SocialPosts,
          CampaignDeliverableType.BrandedArticles,
        ].includes(campaignDeliverable.deliverableType)
      }

      // Only include AdScaffolded for newsletter or deep dive
      if (status === CampaignDeliverableStatus.AdScaffolded) {
        return isNewsletterOrDeepDive
      }

      return true
    })
    .map(([value, label]) => ({
      label,
      value: value as CampaignDeliverableStatus,
    }))

  const status = form.watch("status")
  const timezoneOptions = [
    { value: "UTC", label: "UTC" },
    {
      value: "America/New_York",
      label: "Eastern Time (ET)",
    },
    {
      value: "America/Chicago",
      label: "Central Time (CT)",
    },
    {
      value: "America/Denver",
      label: "Mountain Time (MT)",
    },
    {
      value: "America/Los_Angeles",
      label: "Pacific Time (PT)",
    },
  ]

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(value) => {
        if (!value) onClose()
      }}
    >
      <DialogContent className="w-2/3 max-w-xl gap-0">
        <div className="text-center">
          <div className="text-2xl mb-4">
            Change Campaign Deliverable Status
          </div>
          <div className="mb-6 text-sm">
            Change the status of this deliverable
          </div>
        </div>

        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col space-y-4"
          >
            <div className="grid gap-4">
              <FormField
                control={form.control}
                name="status"
                render={({ field }) => (
                  <SelectField
                    {...field}
                    control={form.control}
                    name="status"
                    label="Status*"
                    text={({ value }) => (
                      <>
                        {statusOptions.find((o) => o.value === value)?.label ??
                          "Select a status"}
                      </>
                    )}
                    options={statusOptions}
                  />
                )}
              />
            </div>
            {status === CampaignDeliverableStatus.AdScaffolded &&
              isNewsletterOrDeepDive && (
                <FormField
                  control={form.control}
                  name="sailthruCampaignId"
                  render={({ field }) => (
                    <SelectField
                      {...field}
                      control={form.control}
                      onOpen={() => sendQuery()}
                      name="sailthruCampaignId"
                      label="Sailthru Campaign*"
                      text={() =>
                        templateOptions.find(
                          (object) => object.value === field.value
                        )?.label || "Select Campaign"
                      }
                      options={templateOptions}
                      showMessage={false}
                    />
                  )}
                />
              )}
            {status === CampaignDeliverableStatus.Published &&
              isNewsletterOrDeepDive && (
                <FormField
                  control={form.control}
                  name="sailthruCampaignId"
                  render={({ field }) => (
                    <SelectField
                      {...field}
                      typeahead
                      control={form.control}
                      onOpen={() => sendPublishedBlastsQuery()}
                      name="sailthruCampaignId"
                      label="Sailthru Campaign*"
                      text={() =>
                        publishedBlastOptions?.find(
                          (option) => option.value === field.value
                        )?.label || "Select Campaign"
                      }
                      options={publishedBlastOptions ?? []}
                      showMessage={false}
                    />
                  )}
                />
              )}
            {status === CampaignDeliverableStatus.Scheduled &&
              isNewsletterOrDeepDive && (
                <FormField
                  control={form.control}
                  name="sailthruCampaignId"
                  render={({ field }) => (
                    <SelectField
                      {...field}
                      control={form.control}
                      onOpen={() => sendScheduledBlastsQuery()}
                      name="sailthruCampaignId"
                      label="Sailthru Campaign*"
                      text={() =>
                        scheduledBlastOptions?.find(
                          (option) => option.value === field.value
                        )?.label || "Select Campaign"
                      }
                      options={scheduledBlastOptions ?? []}
                      showMessage={false}
                    />
                  )}
                />
              )}
            {[
              CampaignDeliverableStatus.Published,
              CampaignDeliverableStatus.Scheduled,
            ].includes(status) &&
              campaignDeliverable.deliverableType ===
                CampaignDeliverableType.NewsletterAds && (
                <div className="flex flex-col gap-4">
                  <FormField
                    control={form.control}
                    name="ctaUrl"
                    render={({ field }) => (
                      <TextField
                        control={form.control}
                        {...field}
                        type="url"
                        label="Call to Action (URL)"
                      />
                    )}
                  />
                  <div className="flex items-center space-x-4">
                    <FormField
                      control={form.control}
                      name="publishDate"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel className="block">Date*</FormLabel>
                          <FormControl>
                            <DatePicker date={field.value} {...field} />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="publishTime"
                      render={({ field }) => (
                        <TextField
                          control={form.control}
                          {...field}
                          type="time"
                          label="Time"
                        />
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="publishTimezone"
                      render={({ field }) => (
                        <SelectField
                          {...field}
                          control={form.control}
                          text={({ value }) => (
                            <>
                              {
                                timezoneOptions.find((o) => o.value === value)
                                  ?.label
                              }
                            </>
                          )}
                          options={timezoneOptions}
                          name="publishTimezone"
                          label="Timezone"
                        />
                      )}
                    />
                  </div>
                </div>
              )}

            {Object.keys(form.formState.errors).length > 0 && (
              <Text className="text-red-500" variant="body-12">
                {Object.values(form.formState.errors).map((error) => (
                  <div key={error.message}>{error.message}</div>
                ))}
              </Text>
            )}

            <Button type="submit" disabled={result.loading}>
              Save
            </Button>
          </form>
        </Form>

        <div className="flex mt-4">
          <Button
            type="button"
            onClick={() => onClose()}
            variant="linkMuted"
            size="xs"
          >
            Cancel
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
