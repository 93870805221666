import { Outlet } from "react-router-dom"
import workweekPartnerPlatformLogo from "~/images/workweek-partner-platform-logo"

export const LoginLayout = () => {
  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="max-w-[353px] mx-auto ">
        <div className="grid gap-8 py-8">
          <img
            {...workweekPartnerPlatformLogo}
            alt="Workweek Partner Platform Logo"
            className="mx-auto"
          />
          <Outlet />
          <div>
            <div className="text-center text-xs">
              © {new Date().getFullYear()} Workweek Media Inc. Copyright All
              Rights Reserved
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
