import { ReactNode, useEffect } from "react"
import { createSearchParams, Link, useParams } from "react-router-dom"
import { gql } from "~/__generated__"
import { Post_CardFragment } from "~/__generated__/graphql"
import { postPath, userProfilePath } from "~/common/paths"
import { filterPostAttachments } from "~/common/postUtils"
import { HighlightContent } from "~/components/HighlightContent"
import { AvatarWithFallback } from "~/ui/AvatarWithFallback"
import { FileAttachments } from "./FileAttachments"
import { ImageAttachments } from "./ImageAttachments"
import { PostActions } from "./PostActions"
import { PostContentSection } from "./PostContentSection"
import { postDateDisplay } from "./postDateDisplay"
import { PostMenu } from "./PostMenu"
import { VideoSection } from "./VideoSection"

import { VariantProps } from "class-variance-authority"
import { useInView } from "react-intersection-observer"
import { cn } from "~/common/cn"
import { useUserDialogContext } from "~/directory/UserDialogContext"
import { useReactions } from "~/post-composer/useReactions"
import { Card, cardVariants } from "~/ui/card"
import { Separator } from "~/ui/separator"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/ui/tooltip"
import { usePostInViewport } from "./PostInViewportContext"
import { usePostHighlighting } from "./usePostHighlighting"
import { useReadTracking } from "./useReadTracking"

const AUTHOR_TRUNCATION = 100
const AUTHOR_LINE_LIMIT = 1
// const CONTENT_TRUNCATION = 100
// const CONTENT_LINE_LIMIT = 3

interface PostCardProps extends VariantProps<typeof cardVariants> {
  post: Post_CardFragment
  header?: ReactNode
  isReply?: boolean
  replyDisabled?: boolean
  cardClickable?: boolean
  onSinglePostPage?: boolean
  hidePostMenu?: boolean
  hidePostActions?: boolean
  trackReadStates?: boolean
  query?: string
}

export const PostCard = ({
  post,
  header,
  isReply = false,
  replyDisabled = false,
  cardClickable = false,
  onSinglePostPage = false,
  hidePostMenu = false,
  hidePostActions = false,
  query = "",
  trackReadStates = false,
  ...cardVariantProps
}: PostCardProps) => {
  const { channelSlug } = useParams()
  const [imageAttachments, otherAttachments] = filterPostAttachments(post)
  const { openUserDialog } = useUserDialogContext()
  const { addReaction, removeReaction } = useReactions({ postId: post.id })
  const { updateInView } = usePostInViewport() || {}

  const { inView, ref } = useInView({
    threshold: 0.5,
    trackVisibility: true,
    delay: 100,
  })
  useReadTracking({ inView, post, enabled: trackReadStates })
  const { isHighlighted } = usePostHighlighting({
    inView,
    post,
    enabled: trackReadStates,
  })

  useEffect(() => {
    updateInView && updateInView({ post, inView })
  }, [inView, post, updateInView])

  // useEffect(() => {
  //   console.log("inView changed", post.id, inView)
  // }, [inView, post.id])

  if (post.deleted) {
    return (
      <Card
        {...cardVariantProps}
        className={cn(
          isHighlighted && "border-highlight bg-card-highlight-background"
        )}
      >
        <div className="p-4 text-2xs italic tracking-wide text-[#666666]">
          This post has been deleted
        </div>
      </Card>
    )
  }

  return (
    <>
      <Card
        {...cardVariantProps}
        ref={ref}
        className={cn(
          isHighlighted && "border-highlight bg-card-highlight-background"
        )}
      >
        {header}
        <div className="flex p-4">
          <div
            onClick={() => openUserDialog(post.user.id)}
            className="mr-3 card-clickable cursor-pointer shrink-0"
          >
            <AvatarWithFallback user={post.user} />
          </div>

          <div className="flex flex-col gap-2 w-full">
            <div className="flex items-center">
              <Link
                to={userProfilePath({ userId: post.user.id })}
                className="text-sm tracking-wide font-semibold mt-0.5 mr-2 card-clickable hover:underline"
                onClick={(e) => {
                  e.preventDefault()
                  openUserDialog(post.user.id)
                }}
              >
                <HighlightContent
                  query={query}
                  content={post.user.name || ""}
                  truncationLimit={AUTHOR_TRUNCATION}
                  lineLimit={AUTHOR_LINE_LIMIT}
                />
              </Link>
              <div className="text-xs tracking-wide text-gray-400 mt-0.5">
                {postDateDisplay(post.createdAt)}
              </div>
              {!hidePostMenu && (
                <div className="ml-auto">
                  <PostMenu post={post} onSinglePostPage={onSinglePostPage} />
                </div>
              )}
            </div>

            <PostContentSection post={post} />
            <VideoSection videoUrl={post.videoUrl} />
            <ImageAttachments attachments={imageAttachments} />
            <FileAttachments attachments={otherAttachments} />

            <div className="flex gap-2">
              {post.reactionEntries.map((entry) => (
                <TooltipProvider key={entry.id}>
                  <Tooltip delayDuration={0}>
                    <TooltipTrigger>
                      <div
                        className={cn(
                          "rounded-full border border-mercury p-2 flex items-center text-2xs cursor-pointer card-clickable",
                          {
                            "border-blue-500 bg-blue-50":
                              entry.currentUserReacted,
                          }
                        )}
                        onClick={(e) => {
                          entry.currentUserReacted
                            ? removeReaction(entry.emoji)
                            : addReaction(entry.emoji)
                        }}
                      >
                        {entry.emoji}
                        <span className="ml-1">{entry.count}</span>
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>
                      {entry.userNames.map((name, index) => (
                        <p key={index} className="text-center">
                          {name}
                        </p>
                      ))}
                      {entry.userNames.length < entry.count && (
                        <p className="text-center">...and others</p>
                      )}
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              ))}
            </div>

            {!hidePostActions && (
              <>
                {!isReply && <Separator />}

                <PostActions
                  post={post}
                  isReply={isReply}
                  replyDisabled={replyDisabled}
                  onSinglePostPage={onSinglePostPage}
                />
              </>
            )}
          </div>
        </div>

        {cardClickable && (
          <Link
            to={{
              pathname: postPath({ postId: post.id }),
              search: channelSlug
                ? createSearchParams({ from: "channel" }).toString()
                : "",
            }}
            state={{ useBrowserBack: true }}
            className="absolute top-0 left-0 right-0 bottom-0"
          />
        )}
      </Card>
    </>
  )
}

gql(`
  fragment Post_Card on Post {
    id
    content
    createdAt
    isReply
    repliesCount
    videoUrl
    currentUserCanDestroy {
      value
    }
    currentUserCanFlag {
      value
    }
    currentUserCanUpdate {
      value
    }
    currentUserCanPin {
      value
    }
    deleted
    parentPost {
      id
    }
    user {
      id
      name
      ...User_Avatar
    }
    attachments {
      id
      editorUrl
      contentType
      byteSize
      filename
    }
    currentUserHasRead
    commentersCount
    reactionEntries {
      id
      emoji
      count
      userNames
      currentUserReacted
    }
  }
`)
