import { VariantProps, cva } from "class-variance-authority"
import React from "react"
import { Link as RRLink, LinkProps as RRLinkProps } from "react-router-dom"
import { cn } from "~/common/cn"
import { Slot } from "@radix-ui/react-slot"

const linkVariants = cva("", {
  variants: {
    variant: {
      default: "underline underline-offset-2",
      unstyled: "",
      "close-pane":
        "font-bold text-xs leading-3 tracking-001 text-gray-99 uppercase no-underline flex",
      add: "font-bold text-xs leading-3 tracking-001 text-gray-99 no-underline",
    },
  },
  defaultVariants: {
    variant: "default",
  },
})

type BaseLinkProps = {} & RRLinkProps & React.RefAttributes<HTMLAnchorElement>

type LinkProps = BaseLinkProps & VariantProps<typeof linkVariants>

export const Link = ({ variant, className, ...props }: LinkProps) => {
  return (
    <RRLink {...props} className={cn(linkVariants({ variant, className }))} />
  )
}

type AnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement> &
  VariantProps<typeof linkVariants>

export const Anchor = ({
  children,
  variant,
  className,
  ...props
}: AnchorProps) => {
  return (
    <a
      {...props}
      className={cn(linkVariants({ variant, className }))}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  )
}

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof linkVariants> {
  asChild?: boolean
}

/**
 * Button that looks like a link
 */
export const ButtonLink = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button"
    return (
      <Comp
        className={cn(linkVariants({ variant, className }))}
        ref={ref}
        {...props}
      />
    )
  }
)
ButtonLink.displayName = "Button"
