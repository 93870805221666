import { Check, ChevronsUpDown, Loader2, XIcon } from "lucide-react"
import { useCallback, useState } from "react"

import { useQuery } from "@apollo/client"
import { useDebounce } from "use-debounce"
import { gql } from "~/__generated__"
import { Address } from "~/__generated__/graphql"
import { cn } from "~/common/cn"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "~/ui/command"
import { Button } from "./button"
import { GraphqlError } from "./errors"
import { Popover, PopoverContent, PopoverTrigger } from "./popover"

gql(/* GraphQL */ `
  fragment AutocompleteAddress on Address {
    placeId
    administrativeAreaLevel1
    country
    formattedAddress
    locality
    postalCode
    route
    streetNumber
    premise
    subpremise
    lat
    lng
  }
`)

const query = gql(/* GraphQL */ `
  query AddressAutoCompleteInput($search: String!) {
    addressAutocomplete(search: $search) {
      ...AutocompleteAddress
    }
  }
`)

type AddressAutoCompleteInputProps = {
  address: Address | null
  onChangeAddress: (address: Address | null) => void
  placeholder?: string
  className?: string
}

export const AddressAutoCompleteInput: React.FC<
  AddressAutoCompleteInputProps
> = (props) => {
  const { onChangeAddress, placeholder, address, className } = props

  const [open, setOpen] = useState(false)
  const [searchInput, setSearchInput] = useState("")

  const [debouncedSearchInput] = useDebounce(searchInput.trim(), 500)

  const result = useQuery(query, {
    variables: { search: debouncedSearchInput },
    skip: debouncedSearchInput === "",
  })

  const predictions = result.data?.addressAutocomplete ?? []

  const handleSelect = useCallback(
    (prediction: Address) => {
      onChangeAddress(prediction)
      setSearchInput("")
      setOpen(false)
    },
    [onChangeAddress]
  )
  const handleRemove = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      onChangeAddress(null)
      setSearchInput("")
    },
    [onChangeAddress]
  )

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <div className="flex w-full items-center">
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className={cn(
              "w-full justify-between rounded-lg bg-white",
              className
            )}
          >
            <span className="truncate">
              {address?.formattedAddress ?? placeholder ?? ""}
            </span>
            <div className="flex items-center">
              <ChevronsUpDown className="h-4 w-4 shrink-0 opacity-50" />
            </div>
          </Button>
        </PopoverTrigger>
        {address && (
          <Button
            variant="ghost"
            size="sm"
            className="h-8 w-8 p-0"
            onClick={handleRemove}
            type="button"
          >
            <XIcon className="h-4 w-4" />
            <span className="sr-only">Remove address</span>
          </Button>
        )}
      </div>
      <PopoverContent className="w-[500px] p-0">
        <Command shouldFilter={false}>
          <CommandInput
            placeholder="Search address..."
            value={searchInput}
            onValueChange={setSearchInput}
          />
          <CommandEmpty>No address found</CommandEmpty>
          <CommandGroup>
            <CommandList>
              {result.loading ? (
                <div className="h-28 flex items-center justify-center">
                  <Loader2 className="size-6 animate-spin" />
                </div>
              ) : result.error ? (
                <GraphqlError error={result.error} />
              ) : (
                predictions.map((prediction) => (
                  <CommandItem
                    key={prediction.placeId}
                    onSelect={() => handleSelect(prediction)}
                    value={prediction.formattedAddress}
                  >
                    <Check
                      className={cn(
                        "mr-2 h-4 w-4",
                        address?.placeId === prediction.placeId
                          ? "opacity-100"
                          : "opacity-0"
                      )}
                    />
                    {prediction.formattedAddress}
                  </CommandItem>
                ))
              )}
            </CommandList>
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
