import { z } from "zod"
import deepEqual from "deep-equal"

type Schema = z.ZodObject<Record<string, z.ZodTypeAny>>

export class URLParamsSerializer<T extends Schema> {
  private schema: T
  private defaults: Partial<z.infer<T>>

  constructor(schema: T, defaults: Partial<z.infer<T>> = {}) {
    this.schema = schema
    this.defaults = defaults
  }

  private isDefault(key: string, value: unknown): boolean {
    return (
      this.defaults.hasOwnProperty(key) &&
      deepEqual(this.defaults[key], value, { strict: true })
    )
  }

  serialize(data: z.infer<T>): URLSearchParams {
    const params = new URLSearchParams()

    Object.entries(data).forEach(([key, value]) => {
      if (this.isDefault(key, value)) return

      const fieldSchema = this.schema.shape[key]

      if (fieldSchema instanceof z.ZodArray) {
        if (Array.isArray(value)) {
          value.forEach((item) => params.append(key, String(item)))
        }
      } else if (fieldSchema instanceof z.ZodBoolean) {
        params.set(key, value ? "true" : "false")
      } else {
        params.set(key, String(value))
      }
    })

    return params
  }

  deserialize(params: URLSearchParams): z.infer<T> {
    const result: Record<string, unknown> = { ...this.defaults }

    Object.entries(this.schema.shape).forEach(([key, fieldSchema]) => {
      if (!params.has(key)) return

      if (fieldSchema instanceof z.ZodArray) {
        const values = params.getAll(key)
        if (values.length > 0) {
          result[key] = values
        }
      } else if (fieldSchema instanceof z.ZodBoolean) {
        result[key] = params.get(key) === "true"
      } else {
        result[key] = params.get(key)
      }
    })

    return this.schema.parse(result)
  }
}
