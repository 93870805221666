import { UserCardFragment } from "~/__generated__/graphql"
import { UserCard } from "~/users/user-card"
import { SectionCounter } from "./section-counter"
import { Section, SectionLabel } from "./section"

export type CampaignUserSectionProps = {
  title: string
  users: UserCardFragment[]
  hideCounter?: boolean
  linkTo: (user: UserCardFragment) => string
  topRightSlot?: React.ReactNode
}

export const CampaignUserSection: React.FC<CampaignUserSectionProps> = ({
  title,
  users,
  linkTo,
  hideCounter = false,
  topRightSlot,
}) => {
  return (
    <Section>
      <div className="flex gap-3 w-full">
        <div className="flex gap-3">
          <SectionLabel>{title}</SectionLabel>
          {!hideCounter && <SectionCounter value={users.length} />}
        </div>
        {topRightSlot}
      </div>
      {users.map((user) => (
        <UserCard user={user} key={user.id} linkTo={linkTo(user)} />
      ))}
    </Section>
  )
}
