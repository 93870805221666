import { TableHead } from "~/ui/table"

export const PodcastColumns = () => (
  <>
    <TableHead className="w-28">
      Downloads
      <br /> 7 days post
    </TableHead>
    <TableHead className="w-28">
      Downloads
      <br /> 30 days post
    </TableHead>
    <TableHead className="w-28">
      Impressions
      <br /> 7 days post
    </TableHead>
    <TableHead className="w-28">
      Impressions
      <br /> 30 days post
    </TableHead>
    <TableHead className="w-28">
      Impressions
      <br /> on YouTube
    </TableHead>
  </>
)
