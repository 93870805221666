import React from "react"
import { HoverCard, HoverCardContent, HoverCardTrigger } from "~/ui/hover-card"

export const Percent: React.FC<{
  completed: number
  total: number
  zero?: React.ReactNode
}> = ({ completed, total, zero = "–" }) => {
  if (total === 0) {
    return zero
  }

  const percent = calculatePercent(completed, total)
  const percentFormatted = percent.toFixed(0)

  return (
    <HoverCard>
      <HoverCardTrigger>{percentFormatted}%</HoverCardTrigger>
      <HoverCardContent className="w-auto">
        {completed} / {total}
      </HoverCardContent>
    </HoverCard>
  )
}

export function calculatePercent(completed: number, total: number) {
  if (total === 0) {
    return 0
  }

  const value = (completed / total) * 100

  if (Number.isNaN(value)) {
    return 0
  }

  return value
}
