import { useQuery } from "@apollo/client"
import { useState } from "react"
import {
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFormReturn,
} from "react-hook-form"
import { useDebounce } from "use-debounce"
import { gql } from "~/__generated__"
import { CompanyRole, UserPaneCompanyFragment } from "~/__generated__/graphql"
import { CheckboxField } from "~/fields/checkbox-field"
import { SelectField } from "~/fields/select-field"
import xIcon from "~/images/icons/x"
import { Button } from "~/ui/button"
import { FormField, FormLabel, FormMessage } from "~/ui/form"

type CompanySelectorProps = {
  form: UseFormReturn<any>
  fields: FieldArrayWithId<any, any>[]
  onRemove: (index: number) => void
  onAppend: UseFieldArrayAppend<any>
  userCompanies?: UserPaneCompanyFragment[] | undefined
}

export const CompanySelector = ({
  form,
  onRemove,
  fields,
  onAppend,
  userCompanies = [],
}: CompanySelectorProps) => {
  const [companyQuery, setCompanyQuery] = useState("")
  const [debouncedCompanySearch] = useDebounce(companyQuery, 200)

  const {
    data: currentCompaniesData,
    previousData: previousCompaniesData,
    loading: companiesLoading,
  } = useQuery(COMPANIES_QUERY_DOCUMENT, {
    variables: {
      search: debouncedCompanySearch,
    },
  })

  const companiesData = currentCompaniesData || previousCompaniesData
  let companies = companiesData?.companies.edges.map((edge) => edge.node) ?? []

  const companyNameFromId = (id: string) => {
    const company = userCompanies
      .concat(companies)
      .find((company) => company?.id === id)
    return company?.name
  }

  const companyOptions = companies.map((company) => ({
    label: company.name,
    value: company.id,
  }))

  const companyRoleOptions = [
    { label: "Client", value: CompanyRole.Client },
    { label: "Admin", value: CompanyRole.Admin },
  ]

  return (
    <FormField
      control={form.control}
      name="companyMemberships"
      render={() => (
        <div className="space-y-2">
          <FormLabel className="font-normal">Companies*</FormLabel>
          <FormMessage className="font-normal text-xs" />
          <div className="space-y-2">
            {fields.map((formField, index) => (
              <FormField
                key={formField.id}
                control={form.control}
                name={`companyMemberships.${index}`}
                render={({ field }) => (
                  <div className="border rounded-md">
                    <div className="font-medium flex items-center justify-between p-2 px-3 border-b text-sm">
                      <span>{companyNameFromId(field.value.companyId)}</span>
                      <Button
                        className="p-2 px-2 h-6 -mr-1"
                        variant="ghost"
                        onClick={() => onRemove(index)}
                      >
                        <img
                          {...xIcon}
                          alt="Remove company icon"
                          className="w-2 h-2"
                        />
                      </Button>
                    </div>
                    <div className="p-2 px-3 pb-4 space-y-4">
                      <SelectField
                        typeahead
                        control={form.control}
                        name={`companyMemberships.${index}.companyId`}
                        label="Company*"
                        text={() =>
                          companyNameFromId(field.value.companyId) ||
                          "Select a company"
                        }
                        isLoading={companiesLoading}
                        onQueryChange={(query: string) =>
                          setCompanyQuery(query)
                        }
                        options={companyOptions}
                      />
                      <SelectField
                        name={`companyMemberships.${index}.companyRole`}
                        label="Company Role"
                        control={form.control}
                        text={() =>
                          companyRoleOptions.find(
                            (option) => option.value === field.value.companyRole
                          )?.label || "Select a role"
                        }
                        options={companyRoleOptions}
                      />

                      <CheckboxField
                        control={form.control}
                        name={`companyMemberships.${index}.creativeContact`}
                        label="Creative Contact"
                      />

                      <CheckboxField
                        control={form.control}
                        name={`companyMemberships.${index}.reportingContact`}
                        label="Reporting Contact"
                      />
                    </div>
                  </div>
                )}
              />
            ))}
            <div className="only:block hidden border rounded-md text-sm text-foreground bg-gray-50 p-3 text-center">
              Please select one or more companies
            </div>
          </div>
          <Button
            type="button"
            variant="link"
            className="p-0"
            onClick={onAppend}
          >
            Add company
          </Button>
        </div>
      )}
    />
  )
}

const COMPANIES_QUERY_DOCUMENT = gql(/* GraphQL */ `
  query Companies($search: String, $first: Int = 10) {
    companies(search: $search, first: $first) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`)
