"use client"

import * as React from "react"
import editIcon from "~/images/icons/edit"

import { formatDate } from "~/common/date-formatting"
import { Button } from "./button"
import { Calendar } from "./calendar"
import { Popover, PopoverContent, PopoverTrigger } from "./popover"
import Text from "./typography"

export const DatePicker = React.forwardRef<
  HTMLButtonElement,
  {
    date?: Date
    onChange: (e: any) => void
    onOpenChange?: (open: boolean) => void
    className?: string
  }
>(({ date, onChange, onOpenChange, className }, ref) => {
  return (
    <Popover onOpenChange={onOpenChange}>
      <PopoverTrigger asChild>
        <Button ref={ref} variant="ghost" size="sm" className={className}>
          <Text variant="body-12" className="flex items-center gap-2">
            {date ? formatDate(date, "PPP") : <span>Pick a date</span>}
            <img {...editIcon} alt="" className="w-3 h-3" />
          </Text>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={date}
          onSelect={onChange}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  )
})

// Optional: Add a display name for better debugging
DatePicker.displayName = "DatePicker"
