import { InputHTMLAttributes, useEffect, useState } from "react"
import { Control, FieldPath, FieldValues } from "react-hook-form"
import { FormField, FormItem, FormLabel } from "~/ui/form"
import { MultiSelect } from "~/ui/multi-select"
import { UsersFilterInput } from "~/__generated__/graphql"

const categories = [
  "Accounting & Financial Management",
  "Advertising & Campaign Management",
  "Affiliate Marketing Platforms",
  "AI & Data Analytics",
  "AI & Machine Learning",
  "AI Tools",
  "Analytics & Insights",
  "Applicant Tracking System (ATS)",
  "Background Checking",
  "Banking & Financial Institutions",
  "Benefits",
  "Business Intelligence & Automation",
  "Business Software & Platforms",
  "Business Solutions & Software",
  "Cloud Services & Technology",
  "Cloud Services",
  "Coaching",
  "Collaboration Tools",
  "Communication & Collaboration",
  "Competitor Intelligence",
  "Consumer Services",
  "Content Creation & Design",
  "Content Management & Creation",
  "Content Management & Optimization",
  "Content Management Systems (CMS)",
  "Content Marketing",
  "Content Performance Optimization",
  "Content Strategy & Creation",
  "Credit & Lending",
  "Credit Reporting & Identity Verification",
  "Customer Engagement & Retention",
  "Customer Feedback & Insights",
  "Customer Relationship Management (CRM) & Customer Support",
  "Customer Relationship Management (CRM) & Sales",
  "Customer Relationship Management",
  "Customer Support & Experience",
  "Customer Support & Service",
  "Data & Analytics",
  "Data Integration & Automation",
  "Data Platforms",
  "Data Privacy & Security",
  "Digital Asset Management",
  "Digital Banking",
  "Digital Health & Telemedicine",
  "Digital Marketing Platforms",
  "E-commerce & Website Builders",
  "E-commerce Logistics",
  "E-commerce Platforms",
  "E-commerce Services",
  "Email & Marketing Automation",
  "Employee Feedback & Engagement",
  "Event Management & Webinars",
  "Financial Data & Analytics",
  "Financial Management",
  "Financial Planning & Analysis",
  "Financial Technology Platforms",
  "Financial Wellness & 401k",
  "Franchise Accounting Software",
  "Franchise Brands",
  "Franchise Brokers",
  "Franchise Consulting Firms/Brokers",
  "Franchise CRMs",
  "Franchise Funding/Lending",
  "Franchise HR/Payroll Software",
  "Franchise Insurance",
  "Franchise Marketing Automation",
  "Franchise Merchant Services (POS/Payments)",
  "Franchise Recruiting/Hiring Platforms",
  "Franchisee Services",
  "Franchisors",
  "Freelance & Gig Economy Platforms",
  "Freelance Platforms",
  "Health & Wellbeing",
  "Health & Wellness Platforms",
  "Health Benefits & Services",
  "Healthcare Services & Providers",
  "Healthcare Technology & Software",
  "HR & Payroll Solutions",
  "Influencer & Content Marketing",
  "IT & Security",
  "Job Board",
  "Learning & Development",
  "Learning Management Systems & Training",
  "Logistics & Fulfillment",
  "Logistics & Shipping",
  "Market Research & Competitive Analysis",
  "Marketing & Analytics",
  "Marketing Automation",
  "Marketing Services",
  "Marketplaces",
  "Online Learning Platforms",
  "Operations & Logistics",
  "Outsourcing & Consulting",
  "Partnership Marketing Platforms",
  "Payment Processing & Financial Technology",
  "Payment Solutions",
  "PEO",
  "People Analytics",
  "Performance Management",
  "Pharmaceutical & Biotechnology",
  "Post Purchase Optimization",
  "Productivity & Organization",
  "Professional Services",
  "Project Management & Collaboration Tools",
  "Project Management",
  "Real Estate Brokers",
  "Recognition & Rewards",
  "Recruitment & Talent Management",
  "Remote Work Solutions",
  "Risk",
  "SaaS Platforms",
  "Salary Data",
  "Social Media Management & Engagement",
  "Social Media Management",
  "Software Development & Tools",
  "Subscription Tools",
  "Supply Chain Management",
  "Surveys & Feedback",
  "Technology & Business Solutions",
  "Telemedicine & Remote Care",
  "UGC Marketing",
  "Video Conferencing & Webinars",
  "Video Platforms & Tools",
  "Women's Health & Fertility",
]

const categoryOptions = categories.map((category) => ({
  label: category,
  value: category,
}))

export const CompanyCategoriesField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  description,
  label,
  labelClassName,
  inputClassName,
  required,
  filters,
  ...inputProps
}: {
  label: string
  labelClassName?: string
  inputClassName?: string
  description?: string
  control: Control<TFieldValues> | undefined
  name: TName
  required?: boolean
  filters?: UsersFilterInput
} & InputHTMLAttributes<HTMLInputElement>) => {
  const [search, setSearch] = useState("")
  const [filteredCategories, setFilteredCategories] = useState<
    { label: string; value: string }[]
  >([])

  useEffect(() => {
    const filtered = categoryOptions.filter((option) =>
      option.label.toLowerCase().includes(search.toLowerCase())
    )
    setFilteredCategories(filtered)
  }, [search])

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel required={required}>{label}</FormLabel>
          <MultiSelect
            name={field.name}
            options={filteredCategories}
            getLabel={(value: string) => value}
            selected={field.value ?? []}
            inputValue={search}
            placeholder="Search for a category"
            onInputChange={(value) => setSearch(value)}
            onValuesChange={(ids) => {
              field.onChange(ids)
            }}
            shouldFilter={false}
          />
        </FormItem>
      )}
    />
  )
}
