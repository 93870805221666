import { MoreHorizontal } from "lucide-react"
import toast from "react-hot-toast"
import { createSearchParams, useSearchParams } from "react-router-dom"
import { Post_CardFragment } from "~/__generated__/graphql"
import { editPostPath, postPath } from "~/common/paths"
import { usePostDestroy } from "~/post-composer/usePostDestroy"
import {
  StyledDropdown,
  StyledDropdownItem,
  StyledDropdownLink,
} from "~/ui/StyledDropdown"

import Download from "../images/icons/download.svg?react"
import Edit from "../images/icons/edit.svg?react"
import Trash from "../images/icons/trash-2.svg?react"

export const PostMenu = ({
  post,
  onSinglePostPage,
}: {
  post: Post_CardFragment
  onSinglePostPage: boolean
}) => {
  const { destroyPostWithConfirmation } = usePostDestroy({ postId: post.id })
  const [searchParams] = useSearchParams()

  return (
    <div className="relative -top-1 -right-1">
      <StyledDropdown
        size="small"
        trigger={
          <div className="text-gray-500 p-1 hover:bg-gray-100 rounded-2xl card-clickable">
            <MoreHorizontal size={14} />
          </div>
        }
      >
        <StyledDropdownItem
          data-no-navigate-to-post
          onClick={() => {
            const postUrl =
              window.location.origin + postPath({ postId: post.id })
            navigator.clipboard.writeText(postUrl)
            toast.success("Post link copied to clipboard.")
          }}
          title="Share Post"
          icon={<Download />}
        />
        {post.currentUserCanUpdate.value && (
          <StyledDropdownLink
            linkProps={{
              to: {
                pathname: editPostPath({ postId: post.id }),
                search:
                  searchParams.get("from") === "channel"
                    ? createSearchParams({ from: "channel" }).toString()
                    : "",
              },
            }}
            title="Edit"
            icon={<Edit />}
          />
        )}
        {post.currentUserCanDestroy.value && (
          <StyledDropdownItem
            onClick={async (e) => {
              const success = await destroyPostWithConfirmation()
              if (onSinglePostPage && success)
                window.alert("TODO: navigate back to feed")
            }}
            title="Delete"
            icon={<Trash />}
          />
        )}
      </StyledDropdown>
    </div>
  )
}
