import { useQuery } from "@apollo/client"
import React, { useState } from "react"
import { Link } from "react-router-dom"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import { CompanyDetailsCampaignFragment } from "~/__generated__/graphql"
import { DeliverableType } from "~/campaigns/deliverable-type"
import { Section } from "~/campaigns/section"
import { formatDate } from "~/common/date-formatting"
import { campaignDeliverablePath } from "~/common/paths"
import minusIcon from "~/images/icons/minus"
import plusIcon from "~/images/icons/plus"
import { GraphqlError } from "~/ui/errors"
import { LoadingIndicatorCentered } from "~/ui/loading-indicator"
import { Percent } from "~/ui/percent"
import Text from "~/ui/typography"

export const CampaignBlock: React.FC<{
  campaign: CompanyDetailsCampaignFragment
}> = ({ campaign }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Section
      key={campaign.id}
      className="mb-6 flex flex-row"
      variant="bordered"
    >
      <div
        className="w-10 cursor-pointer select-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="w-4 h-4 flex items-center">
          {!isOpen && (
            <img {...plusIcon} alt="" className="inline-block align-baseline" />
          )}
          {isOpen && (
            <img
              {...minusIcon}
              alt=""
              className="inline-block align-baseline"
            />
          )}
        </div>
      </div>
      <div className="space-y-4 flex flex-1 flex-col">
        <div className="flex">
          <Text variant="body-12-medium">{campaign.campaignName}</Text>
        </div>
        <div className="grid gap-1">
          <DetailItem
            label="Close Date"
            value={campaign.closeDate ? formatDate(campaign.closeDate) : "–"}
          />
          <DetailItem
            label="Deliverables"
            value={campaign.campaignDeliverablesCount}
          />
          <div className="space-x-1 flex items-center">
            <Text as="div" variant="body-12" className="text-gray-99">
              Percent Complete:
            </Text>
            <Text as="div" variant="body-12">
              <Percent
                completed={campaign.campaignDeliverablesCompletedCount}
                total={campaign.campaignDeliverablesCount}
              />
            </Text>
          </div>
        </div>

        {isOpen && (
          <div>
            <div className="space-y-4">
              <CampaignDeliverables campaignId={campaign.id} />
              <div className="only:block hidden">
                <Text variant="body-12">No deliverables</Text>
              </div>
            </div>
          </div>
        )}
      </div>
    </Section>
  )
}

const DetailItem: React.FC<{ label: string; value: string | number }> = ({
  label,
  value,
}) => (
  <div className="space-x-1 flex items-center">
    <Text as="div" variant="body-12" className="text-gray-99">
      {label}:
    </Text>
    <Text as="div" variant="body-12">
      {value}
    </Text>
  </div>
)

const campaignQuery = gql(/* GraphQL */ `
  query CampaignDetails($campaignId: ID!) {
    node(id: $campaignId) {
      ... on Campaign {
        id
        campaignDeliverables(first: 100) {
          edges {
            node {
              id
              deliverableName
              deliverableType
              status
              publishDateCanonical
            }
          }
        }
      }
    }
  }
`)

const CampaignDeliverables: React.FC<{ campaignId: string }> = ({
  campaignId,
}) => {
  const { data, error, loading } = useQuery(campaignQuery, {
    variables: {
      campaignId,
    },
  })

  if (error) {
    return <GraphqlError error={error} />
  }

  if (loading) {
    return <LoadingIndicatorCentered />
  }

  invariant(data)
  invariant(data.node)
  invariant(data.node.__typename === "Campaign")

  const campaignDeliverables = data.node.campaignDeliverables.edges.map(
    (e) => e.node
  )

  return (
    <>
      {campaignDeliverables.map((deliverable) => (
        <div className="flex items-center justify-between">
          <div className="flex flex-col">
            <Link
              to={campaignDeliverablePath({
                campaignId: campaignId,
                deliverableId: deliverable.id,
              })}
            >
              <Text variant="body-12-medium">
                {deliverable.deliverableName}
              </Text>
            </Link>
            <Text variant="body-10" className="text-gray-99">
              <DeliverableType {...deliverable} showIcon={false} />
            </Text>
          </div>
          <div className="flex flex-col items-end">
            <Text variant="body-12">
              {deliverable.publishDateCanonical
                ? formatDate(deliverable.publishDateCanonical)
                : "–"}
            </Text>
            <Text variant="body-10" className="text-gray-99">
              Publish Date
            </Text>
          </div>
        </div>
      ))}
    </>
  )
}
