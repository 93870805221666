import { UseFormReturn } from "react-hook-form"
import { z } from "zod"
import { formSchema } from "~/campaigns/campaign-deliverable-edit-metrics-pane"
import { TextField } from "~/fields/text-field"
import { Input } from "~/ui/input"
import Text from "~/ui/typography"

export const LeadGenSidebarEditMetrics: React.FC<{
  form: UseFormReturn<z.infer<typeof formSchema>>
}> = ({ form }) => {
  const leadsSuppliedMetric = form.watch("leadsSuppliedMetric") ?? 0
  const leadsToDateMetric = form.watch("leadsToDateMetric") ?? 0
  const leadsRemaining = Number(leadsSuppliedMetric) - Number(leadsToDateMetric)

  return (
    <>
      <TextField
        type="number"
        control={form.control}
        name="leadsSuppliedMetric"
        label="Leads Supplied"
      />
      <TextField
        type="number"
        control={form.control}
        name="leadsToDateMetric"
        label="Leads to Date"
      />
      <div className="space-y-2">
        <Text variant="body-14">
          Leads Remaining <span className="text-gray-400">(calculated)</span>
        </Text>
        <Input disabled value={leadsRemaining} />
      </div>
    </>
  )
}
