import toast from "react-hot-toast"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/use-safe-mutation"
import { displayErrors } from "~/common/validations"
import { ComposerOnSave } from "../post-composer/PostComposer"

export const useCreateMessage = ({
  roomId,
  onSuccess,
  otherUserId,
}: {
  roomId?: string | null
  onSuccess: () => void
  otherUserId: string | undefined
}) => {
  const [runPostCreate, postCreateResult] = useSafeMutation(
    MESSAGE_CREATE_MUTATION
  )

  const createReply: ComposerOnSave = async ({
    content,
    attachmentIds,
    videoUrl,
  }) => {
    if (!roomId) {
      invariant("room id required")
    }

    const { data, errors } = await runPostCreate({
      variables: {
        input: {
          content,
          attachmentIds,
          roomId,
          videoUrl,
        },
      },
    })

    if (errors) {
      displayErrors(errors)
      return false
    } else {
      toast.success("Created message")
      invariant(data?.postCreate.post.id)

      onSuccess()
      return true
    }
  }

  return { createReply, replayIsSaving: postCreateResult.loading }
}

const MESSAGE_CREATE_MUTATION = gql(`
  mutation MessageCreate($input: PostCreateInput!) {
    postCreate(input: $input) {
      post {
        id
      }
    }
  }
`)
