import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"

import FingerprintJS from "@fingerprintjs/fingerprintjs"
import useTimer from "easytimer-react-hook"
import { useCallback } from "react"
import { useLocation } from "react-router-dom"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import { generateUUID } from "~/common/generate-uuid"
import { getPageType } from "~/common/get-page-type"
import { usePageVisibility } from "~/common/use-page-visibility"
import { useSafeMutation } from "~/common/use-safe-mutation"
import {
  AhoyEventTypeEnum,
  EventPageTypeEnum,
  InputMaybe,
  Scalars,
} from "../__generated__/graphql"

type LogEventFunction = (
  name: AhoyEventTypeEnum,
  properties?: InputMaybe<Scalars["EventProperties"]["input"]>
) => Promise<void>

interface EventsContextType {
  logEvent: LogEventFunction
}

const EventsContext = createContext<EventsContextType | null>(null)

const fpPromise = FingerprintJS.load()

const getDeviceId = async () => {
  const fp = await fpPromise
  const result = await fp.get()

  return result.visitorId
}

const INACTIVITY_THRESHOLD = 60

export const EventsProvider = ({ children }: { children: ReactNode }) => {
  const location = useLocation()
  const [currentPageviewId, setCurrentPageviewId] = useState<string | null>(
    null
  )

  const [timer] = useTimer()
  const isVisible = usePageVisibility()
  const lastTrackedPageviewIdRef = useRef<string | undefined>()

  useEffect(() => {
    if (!timer.isRunning()) {
      timer.start({
        precision: "seconds",
      })
    }
  }, [timer])

  useEffect(() => {
    const secondsElapsed = timer.getTimeValues().seconds

    if (!isVisible || secondsElapsed > INACTIVITY_THRESHOLD) {
      // User has likely stopped interacting with the page
      timer.pause()
    } else {
      timer.start()
    }
  }, [isVisible, timer])

  const [currentPageType, setCurrentPageType] =
    useState<EventPageTypeEnum | null>(null)

  const [runLogEvent] = useSafeMutation(LOG_EVENT)

  const logEvent: LogEventFunction = useCallback(
    async (
      name: AhoyEventTypeEnum,
      properties?: InputMaybe<Scalars["EventProperties"]["input"]>
    ) => {
      const activityProperties = {} as {
        time_since_last_event_in_session: number
      }

      if (isVisible !== null) {
        const activeTime = timer.getTimeValues().seconds
        const sessionTimedOut = activeTime >= INACTIVITY_THRESHOLD
        const activeSecsSinceLastEvent = sessionTimedOut
          ? INACTIVITY_THRESHOLD / 2
          : activeTime

        activityProperties["time_since_last_event_in_session"] =
          activeSecsSinceLastEvent
      }

      const eventProperties = {
        url: window.location.href,
        device_id: await getDeviceId(),
        pageview_id: currentPageviewId,
        ...activityProperties,
        ...properties,
      }

      runLogEvent({
        variables: {
          input: {
            name,
            pageType: currentPageType,
            properties: eventProperties,
          },
        },
      })

      timer.reset()
    },
    [currentPageviewId, currentPageType, runLogEvent, timer, isVisible]
  )

  useEffect(() => {
    setCurrentPageType(getPageType(location.pathname))
    setCurrentPageviewId(generateUUID())
  }, [location.pathname, setCurrentPageviewId, setCurrentPageType])

  useEffect(() => {
    if (
      currentPageviewId &&
      currentPageviewId !== lastTrackedPageviewIdRef.current
    ) {
      lastTrackedPageviewIdRef.current = currentPageviewId
      logEvent(AhoyEventTypeEnum.PageViewed)
    }
  }, [currentPageviewId, logEvent])

  const value = useMemo(() => {
    return {
      logEvent,
    }
  }, [logEvent])

  return (
    <EventsContext.Provider value={value}>{children}</EventsContext.Provider>
  )
}

export const useLogEvent = () => {
  const contextValue = useContext(EventsContext)

  invariant(contextValue, "Context has not been Provided!")

  return useMemo(
    () => ({
      logEvent: contextValue.logEvent,
    }),
    [contextValue]
  )
}

export const LOG_EVENT = gql(/* GraphQL */ `
  mutation logEvent($input: LogEventInput!) {
    logEvent(input: $input) {
      event {
        name
      }
    }
  }
`)
