import EmojiPicker from "emoji-picker-react"
import { useState } from "react"
import ClickAwayListener from "react-click-away-listener"
import { useNavigate } from "react-router-dom"
import { Post_CardFragment } from "~/__generated__/graphql"
import { cn } from "~/common/cn"
import { postPath } from "~/common/paths"
import MessageSquare from "~/images/icons/message-square.svg?react"
import Smile from "~/images/icons/smile.svg?react"
import { useComposerContext } from "~/post-composer/ComposerContext"
import { useReactions } from "~/post-composer/useReactions"
import { ClickableIconWrapper } from "~/ui/ClickableIconWrapper"

export const PostActions = ({
  post,
  isReply,
  replyDisabled,
  onSinglePostPage,
}: {
  post: Post_CardFragment
  isReply: boolean
  replyDisabled: boolean
  onSinglePostPage?: boolean
}) => {
  const [emojiPickerOpen, setEmojiPickerOpen] = useState(false)
  const { composerRef } = useComposerContext() || {}
  const { addReaction } = useReactions({ postId: post.id })

  const replyToReply = () => {
    const mentionString = `@[${post.user.name}](${post.user.id}) `
    composerRef?.current?.prefillContent(mentionString)
  }

  const navigate = useNavigate()
  const navigateToReply = () =>
    navigate(`${postPath({ postId: post.id })}?reply=t`)

  return (
    <div className="flex flex-wrap items-center mt-1 gap-2 w-full self-start justify-between">
      <div className="flex flex-wrap items-center gap-2">
        <div className="relative">
          <ClickableIconWrapper
            border
            primary={emojiPickerOpen}
            onClick={() => {
              if (!emojiPickerOpen) {
                setEmojiPickerOpen(true)
              }
            }}
          >
            <Smile />
          </ClickableIconWrapper>
          {emojiPickerOpen && (
            <ClickAwayListener onClickAway={() => setEmojiPickerOpen(false)}>
              <div className="absolute top-[20px] left-[5px] z-[1000]">
                <EmojiPicker
                  reactionsDefaultOpen
                  allowExpandReactions
                  searchDisabled
                  skinTonesDisabled
                  lazyLoadEmojis
                  // reactions={community.defaultReactions}
                  previewConfig={{ showPreview: false }}
                  onEmojiClick={(emoji) => {
                    addReaction(emoji.emoji)
                    setEmojiPickerOpen(false)
                  }}
                />
              </div>
            </ClickAwayListener>
          )}
        </div>

        {!isReply && (
          <>
            <ClickableIconWrapper border onClick={navigateToReply}>
              <MessageSquare />
              {post.repliesCount && post.repliesCount > 0 ? (
                <span className="ml-1">{post.repliesCount}</span>
              ) : null}
            </ClickableIconWrapper>
          </>
        )}
      </div>

      {isReply && !replyDisabled && (
        <div
          className={cn(
            "cursor-pointer p-2 text-2xs text-foreground tracking-wide card-clickable text-foreground"
          )}
          onClick={replyToReply}
        >
          Reply
        </div>
      )}
    </div>
  )
}
