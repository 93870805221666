import { useState } from "react"
import { useOutlet, useParams } from "react-router-dom"
import { Params } from "static-path"
import {
  CampaignDeliverableDetailFragmentFragment,
  ProductBriefPricingStructure,
  ProductBriefSalesType,
  ProductDetailPaneFragmentFragment,
  ProductSnapshotFragmentFragment,
} from "~/__generated__/graphql"
import { Section } from "~/campaigns/section"
import { cn } from "~/common/cn"
import { formatCurrency } from "~/common/currency-formatting"
import { formatDate } from "~/common/date-formatting"
import * as paths from "~/common/paths"
import { campaignDeliverablePath } from "~/common/paths"
import { useGoBack } from "~/common/use-go-back"
import { CompanyLogo } from "~/companies/company-logo"
import arrowLeft from "~/images/icons/arrow-left"
import ExternalLink from "~/images/icons/external-link"
import minus from "~/images/icons/minus"
import pencil from "~/images/icons/pencil"
import plus from "~/images/icons/plus"
import { TablePageLayout } from "~/layouts/table-page-layout"
import { Button } from "~/ui/button"
import { Empty } from "~/ui/empty"
import { Heading } from "~/ui/heading"
import { LinkButton } from "~/ui/link-button"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/ui/table"
import Text from "~/ui/typography"
import { ContentReferences } from "./content-references"

interface ProductDetailProps<
  T extends ProductDetailPaneFragmentFragment | ProductSnapshotFragmentFragment,
> {
  product: T
  isSnapshot?: boolean
}

export const ProductDetail = <
  T extends ProductDetailPaneFragmentFragment | ProductSnapshotFragmentFragment,
>({
  product,
  isSnapshot,
}: ProductDetailProps<T>) => {
  const params = useParams() as Params<
    typeof paths.companyProductsDetailPath.pattern
  >
  const goBack = useGoBack(
    paths.companyProductsPath({ slug: params.slug ?? "" })
  )

  const outlet = useOutlet()

  const pricingStructureLables: Record<ProductBriefPricingStructure, string> = {
    [ProductBriefPricingStructure.BySeat]: "By Seat",
    [ProductBriefPricingStructure.ByUsage]: "By Usage",
    [ProductBriefPricingStructure.FlatPricing]: "Flat Pricing",
  }

  const salesTypeLables: Record<ProductBriefSalesType, string> = {
    [ProductBriefSalesType.SalesTeam]: "Sales Team",
    [ProductBriefSalesType.SelfServe]: "Self Serve",
  }
  const deliverablesGroupedByCampaign =
    product?.publishedDeliverables.edges.reduce(
      (acc, deliverable) => {
        acc[deliverable.node.campaign.campaignName] = [
          ...(acc[deliverable.node.campaign.campaignName] ?? []),
          deliverable.node,
        ]
        return acc
      },
      {} as Record<string, any[]>
    )
  return (
    <>
      <div className="px-[40px] border-b">
        <Button onClick={goBack} variant="ghost" className="-ms-4">
          <img {...arrowLeft} alt="" className="inline-block me-2" />
          <Text variant="mini-caps">Back</Text>
        </Button>
      </div>

      <TablePageLayout padding={false} rightSideSlot={outlet}>
        <div className="flex flex-col flex-1">
          {product != null && (
            <div className="flex flex-1 flex-col">
              <div className="grid grid-cols-[minmax(475px,1fr)_475px] p-[40px] gap-[40px] items-start overflow-auto">
                <div>
                  {isSnapshot && (
                    <div className="flex items-center justify-between bg-gray-f9 rounded-lg mb-8 p-8 py-2">
                      <div>
                        <Text
                          as="div"
                          variant="body-12"
                          className="text-gray-99"
                        >
                          Product Brief Snapshot
                        </Text>
                        <Text as="div" variant="body-14-medium">
                          {formatDate(product.createdAt, "MMMM dd, yyyy - p")}
                        </Text>
                      </div>
                      <div>
                        <LinkButton
                          variant="cardControl"
                          size="sm"
                          target="_blank"
                          className="gap-2"
                          to={paths.companyProductsDetailPath({
                            slug: params.slug,
                            productId: params.productId,
                          })}
                        >
                          <img
                            {...ExternalLink}
                            alt=""
                            className="inline-block align-baseline w-3 h-3"
                          />
                          View Current Version
                        </LinkButton>
                      </div>
                    </div>
                  )}
                  <div className="flex items-center justify-between bg-gray-f9 rounded-lg mb-8 p-8">
                    <div className="flex items-center gap-4">
                      <div className="">
                        <CompanyLogo company={product.company} size="48" />
                      </div>
                      <div>
                        <Text
                          as="div"
                          variant="body-14"
                          className="text-gray-500"
                        >
                          {product.company.name}
                        </Text>
                        <Text as="div" variant="title-24">
                          {product.name}
                        </Text>
                      </div>
                    </div>
                    <div>
                      {!isSnapshot && product.canUpdate.value && (
                        <LinkButton
                          variant="cardControl"
                          size="cardControl"
                          to={paths.companyProductsEditPath({
                            slug: params.slug ?? "",
                            productId: params.productId,
                          })}
                        >
                          <img {...pencil} alt="" className="me-1 w-3 h-3" />
                          Edit Brief
                        </LinkButton>
                      )}
                    </div>
                  </div>

                  <Section className="mb-6 px-8">
                    <div className="grid gap-4 mt-4">
                      <DetailItem
                        label="Main Problem or Pain Point"
                        value={product.mainProblem}
                      />
                      <DetailItem
                        label="Preferred Messaging"
                        value={product.preferredMessaging}
                      />
                      <div>
                        <Text
                          as="div"
                          variant="body-12"
                          className="text-gray-99"
                        >
                          Top Competitors
                        </Text>
                        <ul>
                          {product.topCompetitors.map(
                            (competitor: string, index: number) => (
                              <li key={index}>
                                <Text as="div" variant="body-14">
                                  {competitor}
                                </Text>
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                      <div>
                        <Text
                          as="div"
                          variant="body-12"
                          className="text-gray-99"
                        >
                          Pricing Structure
                        </Text>
                        <Text as="div" variant="body-14">
                          {
                            pricingStructureLables[
                              product.pricingStructure as ProductBriefPricingStructure
                            ]
                          }{" "}
                          - ACV {formatCurrency(product.avgContractValue ?? 0)}
                        </Text>
                      </div>
                      <div>
                        <Text
                          as="div"
                          variant="body-12"
                          className="text-gray-99"
                        >
                          Sales Type
                        </Text>
                        <Text as="div" variant="body-14">
                          {
                            salesTypeLables[
                              product.salesType as ProductBriefSalesType
                            ]
                          }{" "}
                          - {product.salesCycle} Days
                        </Text>
                      </div>

                      <DetailItem
                        label="MQL to Win Rate (Leads Converted)"
                        value={`${product.mqlToWinRate}%`}
                      />

                      <div>
                        <Text
                          as="div"
                          variant="body-12"
                          className="text-gray-99"
                        >
                          Categories
                        </Text>
                        <Text as="div" variant="body-14">
                          {product.categories.length > 0 ? (
                            <ul>
                              {product.categories.map((category) => (
                                <li key={category}>{category}</li>
                              ))}
                            </ul>
                          ) : (
                            <Empty />
                          )}
                        </Text>
                      </div>
                    </div>
                  </Section>
                  {!isSnapshot && (
                    <Section variant="bordered" className="space-y-4">
                      <Heading
                        variant="lg"
                        title="Published Deliverables"
                        count={product?.publishedDeliverables.edges.length}
                      />

                      <div className="flex flex-col gap-2">
                        {Object.entries(
                          deliverablesGroupedByCampaign ?? {}
                        ).map(([campaignName, deliverables]) => (
                          <div key={campaignName}>
                            <CampaignDeliverablesRow
                              campaignName={campaignName}
                              deliverables={deliverables}
                            />
                          </div>
                        ))}
                        <div className="only:block hidden mt-4">
                          <Text variant="body-12" className="text-gray-500">
                            No published deliverables
                          </Text>
                        </div>
                      </div>
                    </Section>
                  )}
                </div>
                <div className="grid">
                  <Section variant="bordered">
                    <ContentReferences
                      disableUpload={isSnapshot}
                      contentReferences={product?.contentReferences}
                    />
                  </Section>
                </div>
              </div>
            </div>
          )}
        </div>
      </TablePageLayout>
    </>
  )
}

const DetailItem: React.FC<{ label: string; value: string | number }> = ({
  label,
  value,
}) => (
  <div className="space-y-1">
    <Text as="div" variant="body-12" className="text-gray-99">
      {label}
    </Text>
    <Text as="div" variant="body-14" className="whitespace-pre-wrap">
      {value}
    </Text>
  </div>
)

const CampaignDeliverablesRow: React.FC<{
  campaignName: string
  deliverables: CampaignDeliverableDetailFragmentFragment[]
}> = ({ campaignName, deliverables }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div
      className={cn(
        "border border-gray-d0 rounded-lg p-4 flex flex-col text-sm",
        { "bg-gray-f9": isOpen }
      )}
    >
      <div
        className="flex items-center gap-2 space-x-2"
        onClick={() => setIsOpen(!isOpen)}
      >
        {!isOpen && (
          <img
            {...plus}
            className="inline-block align-baseline"
            alt="Open icon"
          />
        )}
        {isOpen && (
          <img
            {...minus}
            className="inline-block align-baseline"
            alt="Open icon"
          />
        )}
        <Text variant="body-12-medium">{campaignName}</Text>
      </div>
      {isOpen && (
        <div className="pl-8">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Deliverable</TableHead>
                <TableHead className="text-right pr-8">Publish Date</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {deliverables
                .sort((a, b) =>
                  (a.publishDateCanonical ?? "") >
                  (b.publishDateCanonical ?? "")
                    ? 1
                    : -1
                )
                .map((deliverable) => (
                  <TableRow className="bg-white hover:bg-white">
                    <TableCell>
                      <LinkButton
                        target="_blank"
                        variant="link"
                        className="p-0"
                        to={campaignDeliverablePath({
                          campaignId: deliverable.campaign.id,
                          deliverableId: deliverable.id,
                        })}
                      >
                        <Text variant="body-12">
                          {deliverable.deliverableName}
                        </Text>
                      </LinkButton>
                    </TableCell>
                    <TableCell className="text-right">
                      <Text variant="body-12">
                        {deliverable.publishDateCanonical
                          ? formatDate(deliverable.publishDateCanonical)
                          : null}
                      </Text>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
      )}
    </div>
  )
}
