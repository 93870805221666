import { TableHead } from "~/ui/table"

export const LeadGenColumns = () => (
  <>
    <TableHead className="w-[150px]">Leads Supplied</TableHead>
    <TableHead className="w-[150px]">Leads to Date</TableHead>
    <TableHead className="w-[150px]">Leads Remaining</TableHead>
    <TableHead className="w-[150px]" colSpan={2}>
      Date of Completion
    </TableHead>
  </>
)
