import { gql } from "~/__generated__"
import {
  CampaignDeliverableMetricsFragment,
  CampaignDeliverableStatus,
  CampaignDeliverableType,
} from "~/__generated__/graphql"
import { campaignDeliverableEditMetricsPath } from "~/common/paths"
import { LinkButton } from "~/ui/link-button"
import Text from "~/ui/typography"
import { BrandedArticlesSidebarMetrics } from "./metrics/branded-articles/branded-articles-sidebar-metrics"
import { EventsSidebarMetrics } from "./metrics/events/events-sidebar-metrics"
import { LeadGenerationSidebarMetrics } from "./metrics/lead-generation/lead-generation-sidebar-metrics"
import { NewsletterAdsSidebarMetrics } from "./metrics/newsletters/newsletter-ads-sidebar-metrics"
import { PodcastSidebarMetrics } from "./metrics/podcasts/podcast-sidebar-metrics"
import { SocialPostsSidebarMetrics } from "./metrics/social/social-posts-sidebar-metrics"

gql(/* GraphQL */ `
  fragment CampaignDeliverableMetrics on CampaignDeliverable {
    id
    status
    metricNotes
    deliverableType
    canEditMetrics {
      value
    }
    campaign {
      id
    }
    dateOfCompletion
    downloads30DaysPostLaunchMetric
    downloads7DaysPostLaunchMetric
    impressions30DaysPostLaunchMetric
    impressions7DaysPostLaunchMetric
    impressionsMetric
    leadsSuppliedMetric
    leadsToDateMetric
    liveAttendeesMetric
    opensMetric
    pageviewsMetric
    recipientsMetric
    registrantsMetric
    timeOnSiteMetric
    totalClicksMetric
    metricsPending
  }
`)

export const CampaignDeliverableMetrics: React.FC<{
  campaignDeliverable: CampaignDeliverableMetricsFragment
}> = ({ campaignDeliverable }) => {
  if (campaignDeliverable.status !== CampaignDeliverableStatus.Published) {
    return null
  }

  const metricsAvailable = !campaignDeliverable.metricsPending
  const metricsVisible =
    campaignDeliverable.deliverableType !==
    CampaignDeliverableType.LeadGenMagnet

  if (!metricsVisible) {
    return null
  }

  const renderMetrics = () => {
    switch (campaignDeliverable.deliverableType) {
      case CampaignDeliverableType.NewsletterAds:
      case CampaignDeliverableType.DeepDives:
        return (
          <NewsletterAdsSidebarMetrics
            campaignDeliverable={campaignDeliverable}
          />
        )
      case CampaignDeliverableType.SocialPosts:
        return (
          <SocialPostsSidebarMetrics
            campaignDeliverable={campaignDeliverable}
          />
        )
      case CampaignDeliverableType.BrandedArticles:
        return (
          <BrandedArticlesSidebarMetrics
            campaignDeliverable={campaignDeliverable}
          />
        )
      case CampaignDeliverableType.LeadGen:
        return (
          <LeadGenerationSidebarMetrics
            campaignDeliverable={campaignDeliverable}
          />
        )
      case CampaignDeliverableType.Podcasts:
      case CampaignDeliverableType.PodcastGuest:
      case CampaignDeliverableType.CustomPodcastContent:
        return (
          <PodcastSidebarMetrics campaignDeliverable={campaignDeliverable} />
        )
      case CampaignDeliverableType.LiveEvents:
      case CampaignDeliverableType.VirtualEvents:
      case CampaignDeliverableType.CustomLiveEvent:
      case CampaignDeliverableType.CustomVirtualEvent:
      case CampaignDeliverableType.LiveOrVirtualEventSpeakingFee:
        return (
          <EventsSidebarMetrics campaignDeliverable={campaignDeliverable} />
        )

      default:
        return `Unknown deliverable type: ${campaignDeliverable.deliverableType}`
    }
  }

  return (
    <div>
      <div className="flex items-center justify-between">
        <Text variant="body-18-medium">Metrics</Text>
        {campaignDeliverable.canEditMetrics.value && (
          <LinkButton
            variant="linkMuted"
            className="uppercase font-medium"
            to={campaignDeliverableEditMetricsPath({
              campaignId: campaignDeliverable.campaign.id,
              deliverableId: campaignDeliverable.id,
            })}
          >
            Edit metrics
          </LinkButton>
        )}
      </div>

      {metricsAvailable && (
        <div className="grid gap-4 pt-4">{renderMetrics()}</div>
      )}

      {!metricsAvailable && (
        <div className="h-64 flex flex-col justify-center">
          <div className="flex flex-col items-center justify-center">
            <Text variant="body-24" className="mb-4">
              Metrics Unavailable
            </Text>
            <Text variant="body-14">Metrics are currently unavailable.</Text>
            <Text variant="body-14">Please check back later.</Text>
          </div>
        </div>
      )}
    </div>
  )
}
