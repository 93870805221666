import { PropsWithChildren } from "react"
import Text from "~/ui/typography"

export const SubsectionBody: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Text as="div" variant="body-12-medium" className="overflow-hidden">
      {children}
    </Text>
  )
}
