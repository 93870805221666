import { z } from "zod"
import deepEqual from "deep-equal"

type Schema = z.ZodObject<Record<string, z.ZodTypeAny>>

export function pickNonDefaults<T extends Schema>(
  schema: T,
  defaults: Partial<z.infer<T>>,
  values: z.infer<T>
): Partial<z.infer<T>> {
  const result: Partial<z.infer<T>> = {}

  Object.entries(schema.shape).forEach(([key, fieldSchema]) => {
    const value = values[key]
    const defaultValue = defaults[key]

    if (!deepEqual(value, defaultValue, { strict: true })) {
      // @ts-expect-error
      result[key] = value
    }
  })

  return result
}

export function countNonDefaults<T extends Schema>(
  schema: T,
  defaults: Partial<z.infer<T>>,
  values: z.infer<T>
): number {
  return Object.keys(pickNonDefaults(schema, defaults, values)).length
}
