import { useMutation } from "@apollo/client"
import { gql } from "~/__generated__"
import { displayErrors } from "~/common/validations"
import { Button } from "~/ui/button"
import { Dialog, DialogContent } from "~/ui/dialog"
import { useToast } from "~/ui/use-toast"
import { UserAvatar } from "./user-avatar"

export const ArchiveUserModal = ({
  isOpen,
  onClose,
  user,
}: {
  isOpen: boolean
  onClose: () => void
  user: any
}) => {
  const [runMutation] = useMutation(ARCHIVE_USER_MUTATION)
  const { toast } = useToast()

  const archiveUser = async () => {
    const { errors } = await runMutation({
      variables: { input: { id: user.id } },
    })

    if (errors) {
      displayErrors(errors)
    } else {
      toast({ title: "User has been archived" })
      onClose()
    }
  }

  if (!user) return null

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(value) => {
        if (!value) onClose()
      }}
    >
      <DialogContent className="w-2/3 max-w-xl gap-0 text-center">
        <div className="text-2xl mb-4">Archive User</div>
        <div className="mb-6 text-sm">
          Archived users will become inactive and no longer have access to the
          platform. Are you sure you want to archive this user?
        </div>

        <div className="border border-gray-200 rounded-lg bg-gray-50 p-4 flex items-center justify-between">
          <div className="flex items-center gap-4">
            <UserAvatar user={user} size="lg" />
            <div className="flex flex-col text-left">
              <div className="text-sm font-medium">
                {user.firstName} {user.lastName}
              </div>
              <div className="text-xs-plus text-gray-500">{user.email}</div>
            </div>
          </div>
          <div>
            <Button type="button" className="px-6" onClick={archiveUser}>
              Archive
            </Button>
          </div>
        </div>

        <div className="flex mt-4">
          <Button
            type="button"
            onClick={() => onClose()}
            variant="linkMuted"
            size="xs"
          >
            Cancel
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

const ARCHIVE_USER_MUTATION = gql(`
  mutation ArchiveUser($input: UserArchiveInput!) {
    userArchive(input: $input) {
      user {
        id
        status
      }
    }
  }
`)
