import { useMutation, useQuery } from "@apollo/client"
import { gql } from "~/__generated__"
import { Role, UserStatus } from "~/__generated__/graphql"
import { displayErrors } from "~/common/validations"
import { Button } from "~/ui/button"
import { Dialog, DialogContent } from "~/ui/dialog"
import { useToast } from "~/ui/use-toast"
import { UserAvatar } from "~/users/user-avatar"

const CLIENT_USERS_QUERY = gql(`
  query ClientUsers($input: UsersFilterInput!) {
    users(filters: $input) {
      edges {
        node {
          id
          firstName
          lastName
          email
          ...UserAvatar
        }
      }
    }
  }
`)

export const NotifyUsersAboutMetricsModal = ({
  isOpen,
  onClose,
  campaignId,
  companyId,
}: {
  isOpen: boolean
  onClose: () => void
  campaignId: string
  companyId: string
}) => {
  const [runMutation] = useMutation(SEND_METRICS_MUTATION)
  const { toast } = useToast()

  const { data } = useQuery(CLIENT_USERS_QUERY, {
    variables: {
      input: {
        companies: [companyId],
        roles: [Role.Client],
        statuses: [UserStatus.Active],
      },
    },
  })

  const clientUsers = data?.users?.edges.map((edge) => edge.node)

  const sendMetrics = async () => {
    const { errors } = await runMutation({
      variables: { input: { campaignId: campaignId } },
    })

    if (errors) {
      displayErrors(errors)
    } else {
      toast({ title: "Clients has been notified!" })
      onClose()
    }
  }

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(value) => {
        if (!value) onClose()
      }}
    >
      <DialogContent className="w-2/3 max-w-xl gap-0 text-center">
        <div className="text-2xl mb-4">Notify Clients</div>
        <div className="mb-6 text-sm">
          Would you like to send a notification to the clients that new metrics
          are ready to view?
        </div>

        <div className="border border-gray-200 rounded-lg bg-gray-50 p-4 flex flex-col gap-4 max-h-[270px] overflow-y-auto">
          {clientUsers &&
            clientUsers.length > 0 &&
            clientUsers.map((user) => (
              <div className="flex items-center gap-4">
                <UserAvatar user={user} size="lg" />
                <div className="flex flex-col text-left">
                  <div className="text-sm font-medium">
                    {user.firstName} {user.lastName}
                  </div>
                  <div className="text-xs-plus text-gray-500">{user.email}</div>
                </div>
              </div>
            ))}
        </div>

        <div className="flex mt-4 items-center justify-between">
          <Button
            type="button"
            onClick={() => onClose()}
            variant="linkMuted"
            size="xs"
          >
            Cancel
          </Button>
          <Button type="button" className="px-6" onClick={sendMetrics}>
            Send
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

const SEND_METRICS_MUTATION = gql(`
  mutation SendMetrics($input: CampaignSendMetricsInput!) {
    campaignSendMetrics(input: $input) {
      campaign {
        id
      }
    }
  }
`)
