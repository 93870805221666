import { CompanyRole, Role } from "~/__generated__/graphql"
import { useViewer } from "~/auth/viewer-context"
import { useCompanyMaybe } from "~/companies/company-context"

export function useAuthorizedAccess(allowedRoles: (Role | CompanyRole)[]) {
  const { viewer } = useViewer()
  const { companyRole } = useCompanyMaybe()

  const isClientAndCompanyAdmin =
    viewer.role === Role.Client && companyRole === CompanyRole.Admin

  const hasAccess = isClientAndCompanyAdmin
    ? allowedRoles.includes(CompanyRole.Admin) ||
      allowedRoles.includes(Role.Client)
    : allowedRoles.includes(viewer?.role)

  return {
    hasAccess,
    viewer,
  }
}
