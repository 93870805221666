import Imgix from "react-imgix"
import { gql } from "~/__generated__"
import { UserAvatarFragment } from "~/__generated__/graphql"
import { Avatar, AvatarFallback, AvatarProps } from "~/ui/avatar"

gql(`
  fragment UserAvatar on User {
    id
    firstName
    lastName
    image {
      id
      imgixUrl
    }
  }
`)

// TODO: images
export const UserAvatar: React.FC<
  { user: UserAvatarFragment } & Pick<AvatarProps, "size" | "className">
> = ({ user, ...rest }) => {
  const initials = `${user.firstName[0] ?? "?"}${user.lastName[0] ?? ""}`
  return (
    <Avatar {...rest}>
      {user.image ? (
        <Imgix
          src={user.image.imgixUrl}
          width={30}
          height={30}
          imgixParams={{
            fit: "clamp",
          }}
          className="rounded-full w-full h-full"
        />
      ) : null}
      <AvatarFallback>{initials}</AvatarFallback>
    </Avatar>
  )
}
