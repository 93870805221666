import { createContext, useContext, useMemo } from "react"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import { CompanyContextFragment, CompanyRole } from "~/__generated__/graphql"
import { useViewerMaybe } from "~/auth/viewer-context"

type CompanyContextType = {
  company: CompanyContextFragment | null
  /** viewer's role at this company */
  companyRole: CompanyRole | null
}
const CompanyContext = createContext<CompanyContextType | null>(null)

gql(/* GraphQL */ `
  fragment CompanyContext on Company {
    id
    slug
    name
  }
`)

export const CompanyProvider = ({
  children,
  company,
}: {
  children: React.ReactNode
  company: CompanyContextFragment | null
}) => {
  const { viewer } = useViewerMaybe()

  // might be a better way to expose this in graphql but this should work fine
  // given users will only have a couple companies
  const viewerCompanyEdge = viewer?.companies?.edges.find(
    (edge) => edge.node.slug === company?.slug
  )

  const companyRole = viewerCompanyEdge ? viewerCompanyEdge.role : null

  const value = useMemo(
    () => ({
      company,
      companyRole: companyRole,
    }),
    [company, companyRole]
  )

  return (
    <CompanyContext.Provider value={value}>{children}</CompanyContext.Provider>
  )
}

export const useCompany = () => {
  const contextValue = useContext(CompanyContext)
  invariant(contextValue, "Context has not been Provided!")

  const value = useMemo(() => {
    invariant(contextValue, "Context has not been Provided!")
    invariant(contextValue.company, "Company should be available")

    const { company, companyRole } = contextValue
    return { company, companyRole }
  }, [contextValue])

  return value
}

export const useCompanyMaybe = () => {
  const contextValue = useContext(CompanyContext)
  invariant(contextValue, "Context has not been Provided!")
  return contextValue
}
