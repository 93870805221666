import Text from "~/ui/typography"

export const SectionCounter: React.FC<{ value: number }> = ({ value }) => {
  return (
    <div className="flex flex-col">
      <div className="flex border border-foreground/20 rounded-lg px-2">
        <Text variant="subtext-10" className="text-gray-99">
          {value}
        </Text>
      </div>
    </div>
  )
}
