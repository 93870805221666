import { useEffect, useMemo } from "react"
import { getMetaVarFlashMessageList } from "~/common/get-meta-var"
import { useToast } from "~/ui/use-toast"

const ignoredMessages = ["Token is invalid"]

export const RootFlashMessages = () => {
  const { toast } = useToast()
  const messages = useMemo(() => {
    const list = getMetaVarFlashMessageList() ?? []
    return list.filter(({ message }) => !ignoredMessages.includes(message))
  }, [])

  useEffect(() => {
    for (const { type, message } of messages) {
      toast({
        title: message,
        variant: type === "error" ? "destructive" : "default",
      })
    }
  }, [messages, toast])

  return null
}
