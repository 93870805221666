import Imgix from "react-imgix"
import { gql } from "~/__generated__"
import { CompanyLogoFragment } from "~/__generated__/graphql"
import { Avatar, AvatarFallback, AvatarProps } from "~/ui/avatar"

gql(/* GraphQL */ `
  fragment CompanyLogo on Company {
    id
    name
    image {
      id
      imgixUrl
    }
  }
`)

// TODO: images
export const CompanyLogo = ({
  company,
  ...rest
}: { company: CompanyLogoFragment } & AvatarProps) => {
  const initials = `${company.name[0] ?? "?"}`
  return (
    <Avatar {...rest}>
      {company.image ? (
        <Imgix
          src={company.image.imgixUrl}
          width={30}
          height={30}
          imgixParams={{
            fit: "clamp",
          }}
          className="rounded-full w-full h-full"
        />
      ) : null}
      <AvatarFallback>{initials}</AvatarFallback>
    </Avatar>
  )
}
