import { cva, type VariantProps } from "class-variance-authority"
import React from "react"
import { cn } from "~/common/cn"

export const textVariants = cva("", {
  variants: {
    variant: {
      default: "",
      "mini-caps": "text-xs tracking-001 uppercase font-bold",
      "title-24": "text-24 tracking-002 text-gray-2b font-medium ",
      "body-10": "text-xs",
      "body-10-medium": "text-xs tracking-001 font-medium",
      "body-10-bold": "text-xs tracking-001 font-bold",
      "body-12": "text-xs-plus",
      "body-12-medium": "text-xs-plus tracking-001 font-medium",
      "body-14": "text-sm tracking-001",
      "body-14-medium": "text-sm tracking-001 font-medium",
      "body-18-medium": "tracking-001 font-medium",
      "body-24": "text-2xl",
      "body-24-medium": "text-2xl font-medium",
      "subtext-10": "text-xs",
      "subtext-10-bold": "text-xs font-bold",
    },
    wrapping: {
      default: "",
      wrap: "whitespace-pre-wrap break-words",
    },
  },
  defaultVariants: {
    variant: "default",
    wrapping: "default",
  },
})

export interface TypographyProps
  extends React.HTMLAttributes<HTMLHeadingElement>,
    VariantProps<typeof textVariants> {
  as?: React.ElementType
}

const Text = React.forwardRef<HTMLHeadingElement, TypographyProps>(
  ({ className, as: Comp = "span", variant, wrapping, ...props }, ref) => {
    return (
      <Comp
        className={cn(textVariants({ variant, wrapping, className }))}
        ref={ref}
        {...props}
      />
    )
  }
)
Text.displayName = "Text"

export default Text
