import { useQuery } from "@apollo/client"
import { useState } from "react"
import { UseFormReturn } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import { Params } from "static-path"
import { useDebounce } from "use-debounce"
import { z } from "zod"
import { gql } from "~/__generated__"
import {
  ProductBriefPricingStructure,
  ProductBriefSalesType,
} from "~/__generated__/graphql"
import { Section } from "~/campaigns/section"
import * as paths from "~/common/paths"
import { SelectField } from "~/fields/select-field"
import { TextField } from "~/fields/text-field"
import { TextareaField } from "~/fields/textarea-field"
import { Button } from "~/ui/button"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/ui/form"
import { Input } from "~/ui/input"
import { ButtonLink } from "~/ui/link"
import { MultiSelect } from "~/ui/multi-select"
import Text from "~/ui/typography"

export const formSchema = z.object({
  companyId: z.string(),
  name: z.string(),
  mainProblem: z.string(),
  preferredMessaging: z.string(),
  topCompetitors: z.array(z.string()),
  pricingStructure: z.nativeEnum(ProductBriefPricingStructure),
  avgContractValue: z.number().optional(),
  pricePerSeat: z.number().optional(),
  salesType: z.nativeEnum(ProductBriefSalesType),
  salesCycle: z.number().optional(),
  mqlToWinRate: z.number().optional(),
  categories: z.array(z.string()),
})

const COMPANY_CATEGORIES_QUERY = gql(/* GraphQL */ `
  query CompanyCategories($companyId: ID!) {
    node(id: $companyId) {
      ... on Company {
        categories
      }
    }
  }
`)

export const ProductForm = ({
  form,
  onSubmit,
}: {
  form: UseFormReturn<z.infer<typeof formSchema>>
  onSubmit: any
}) => {
  const params = useParams() as Params<
    typeof paths.companyProductsEditPath.pattern
  >
  const [companyQuery, setCompanyQuery] = useState("")
  const [debouncedCompanySearch] = useDebounce(companyQuery, 200)

  const {
    data: currentCompaniesData,
    previousData: previousCompaniesData,
    loading: companiesLoading,
  } = useQuery(COMPANIES_QUERY_DOCUMENT, {
    variables: {
      search: debouncedCompanySearch,
    },
  })

  const companiesData = currentCompaniesData || previousCompaniesData
  let companies = companiesData?.companies.edges.map((edge) => edge.node) ?? []

  const companyNameFromId = (id: string) => {
    const company = companies.find((company) => company?.id === id)
    return company?.name
  }

  const companyOptions = companies.map((company) => ({
    label: company.name,
    value: company.id,
  }))

  const pricingStructureLables: Record<ProductBriefPricingStructure, string> = {
    [ProductBriefPricingStructure.BySeat]: "By Seat",
    [ProductBriefPricingStructure.ByUsage]: "By Usage",
    [ProductBriefPricingStructure.FlatPricing]: "Flat Pricing",
  }

  const pricingStructure = form.watch().pricingStructure
  const pricingFlatStructureOrByUsage =
    pricingStructure === ProductBriefPricingStructure.FlatPricing ||
    pricingStructure === ProductBriefPricingStructure.ByUsage

  const salesType = form.watch().salesType
  const salesTypeSalesTeam = salesType === ProductBriefSalesType.SalesTeam

  const { data: categoriesData, loading: categoriesLoading } = useQuery(
    COMPANY_CATEGORIES_QUERY,
    {
      variables: {
        companyId: form.watch("companyId"),
      },
      skip: !form.watch("companyId"),
    }
  )

  const companyCategories =
    categoriesData?.node?.__typename === "Company"
      ? categoriesData.node.categories
      : []

  const salesTypeLables: Record<ProductBriefSalesType, string> = {
    [ProductBriefSalesType.SalesTeam]: "Sales Team",
    [ProductBriefSalesType.SelfServe]: "Self Serve",
  }

  const navigate = useNavigate()

  const topCompetitors = form.watch().topCompetitors
  const MAX_TOP_COMPETITORS = 5

  return (
    <Form {...form}>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          form.handleSubmit(onSubmit)()
        }}
      >
        <Section variant="grayBackground" className="space-y-4">
          <div>
            <Text variant="body-12" className="text-foreground">
              *
            </Text>{" "}
            <Text variant="body-12">Required Fields</Text>
          </div>
          {!form.formState.isValid && (
            <div className="bg-foreground text-background text-center py-5 px-5 rounded font-normal text-sm text-white mb-4">
              Please correct the required fields below to save your deliverable
              updates.
            </div>
          )}
          <FormField
            control={form.control}
            name="companyId"
            render={(field) => (
              <SelectField
                typeahead
                {...field}
                control={form.control}
                name={`companyId`}
                label="Company*"
                text={() =>
                  companyNameFromId(field.field.value) || "Select a company"
                }
                isLoading={companiesLoading}
                onQueryChange={(query: string) => setCompanyQuery(query)}
                options={companyOptions}
              />
            )}
          />
          <TextField
            control={form.control}
            name="name"
            label="Product Name*"
            required
          />
          <TextareaField
            label="Main Problem or Pain Point*"
            control={form.control}
            name="mainProblem"
          />
          <TextareaField
            label="Preferred Messaging*"
            control={form.control}
            name="preferredMessaging"
          />
          <div>
            <FormItem>
              <div className="flex items-center justify-between">
                <FormLabel>Top Competitors*</FormLabel>
                <Text as="div" variant="body-10" className="text-gray-99">
                  {MAX_TOP_COMPETITORS} maximum
                </Text>
              </div>
              {topCompetitors?.map((competitor, index) => (
                <FormControl key={index}>
                  <Input
                    key={index}
                    className="mb-2 border border-gray-d0 rounded-lg"
                    value={competitor}
                    onChange={(e) => {
                      form.setValue(`topCompetitors.${index}`, e.target.value)
                    }}
                  />
                </FormControl>
              ))}
              {form.formState.errors.topCompetitors && (
                <div className="text-sm font-medium text-destructive">
                  {form.formState.errors.topCompetitors.message}
                </div>
              )}
            </FormItem>
            {topCompetitors?.length < MAX_TOP_COMPETITORS && (
              <ButtonLink
                type="button"
                onClick={() =>
                  form.setValue("topCompetitors", [...topCompetitors, ""])
                }
                variant="add"
              >
                Add Another
              </ButtonLink>
            )}
          </div>
          <SelectField
            label="Pricing Structure*"
            name="pricingStructure"
            control={form.control}
            text={({ value }) =>
              pricingStructureLables[value as ProductBriefPricingStructure]
            }
            options={Object.entries(pricingStructureLables).map(
              ([value, label]) => ({
                value,
                label,
              })
            )}
          />
          {pricingFlatStructureOrByUsage && (
            <FormField
              control={form.control}
              name="avgContractValue"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Average Contract Value*</FormLabel>
                  <FormControl>
                    <div className="flex items-center">
                      <Input
                        {...field}
                        type="number"
                        onChange={(e) => {
                          form.setValue(
                            "avgContractValue",
                            e.target.valueAsNumber
                          )
                        }}
                        value={field.value ?? ""}
                        className="rounded-r-none"
                      />
                      <div className="flex h-10 rounded-r-md border border-l-0 border-input bg-background disabled:bg-gray-f9 px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50">
                        $
                      </div>
                    </div>
                  </FormControl>
                  <FormMessage className="text-xs" />
                </FormItem>
              )}
            />
          )}
          {pricingStructure === ProductBriefPricingStructure.BySeat && (
            <FormField
              control={form.control}
              name="pricePerSeat"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Price Per Seat*</FormLabel>
                  <FormControl>
                    <div className="flex items-center">
                      <Input
                        {...field}
                        type="number"
                        onChange={(e) => {
                          form.setValue("pricePerSeat", e.target.valueAsNumber)
                        }}
                        value={field.value ?? ""}
                        className="rounded-r-none"
                      />
                      <div className="flex h-10 rounded-r-md border border-l-0 border-input bg-background disabled:bg-gray-f9 px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50">
                        $
                      </div>
                    </div>
                  </FormControl>
                  <FormMessage className="text-xs" />
                </FormItem>
              )}
            />
          )}
          <SelectField
            label="Sales Type*"
            name="salesType"
            control={form.control}
            text={({ value }) =>
              salesTypeLables[value as ProductBriefSalesType]
            }
            options={Object.entries(salesTypeLables).map(([value, label]) => ({
              value,
              label,
            }))}
          />
          {salesTypeSalesTeam && (
            <FormField
              control={form.control}
              name="salesCycle"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Sales Cycle*</FormLabel>
                  <FormControl>
                    <div className="flex items-center">
                      <Input
                        {...field}
                        type="number"
                        onChange={(e) => {
                          form.setValue("salesCycle", e.target.valueAsNumber)
                        }}
                        value={field.value ?? ""}
                        className="rounded-r-none"
                      />
                      <div className="flex h-10 rounded-r-md border border-l-0 border-input bg-background disabled:bg-gray-f9 px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50">
                        days
                      </div>
                    </div>
                  </FormControl>
                  <FormMessage className="text-xs" />
                </FormItem>
              )}
            />
          )}
          <FormField
            control={form.control}
            name="mqlToWinRate"
            render={({ field }) => (
              <FormItem>
                <FormLabel>MQL to Win Rate (Leads Converted)*</FormLabel>
                <FormControl>
                  <div className="flex items-center">
                    <Input
                      {...field}
                      type="number"
                      className="rounded-r-none"
                      value={field.value ?? ""}
                      onChange={(e) => {
                        form.setValue("mqlToWinRate", e.target.valueAsNumber)
                      }}
                    />
                    <div className="flex h-10 rounded-r-md border border-l-0 border-input bg-background disabled:bg-gray-f9 px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50">
                      %
                    </div>
                  </div>
                </FormControl>
                <FormMessage className="text-xs" />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="categories"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Categories</FormLabel>
                {companyCategories.length > 0 && (
                  <MultiSelect
                    disabled={categoriesLoading}
                    selected={field.value ?? []}
                    onValuesChange={(values) => {
                      field.onChange(values)
                    }}
                    {...field}
                    options={companyCategories.map((category) => ({
                      value: category,
                      label: category,
                    }))}
                    placeholder="Select categories"
                  />
                )}
                {companyCategories.length === 0 && (
                  <div className="text-sm text-muted-foreground">
                    No categories to display. Please assign categories to the
                    company profile.
                  </div>
                )}
              </FormItem>
            )}
          />
        </Section>

        <div className="border-t border-gray-d0 pt-10 space-y-4 mt-4">
          <Button
            type="submit"
            disabled={form.formState.isSubmitting}
            className="block w-full"
          >
            Save
          </Button>
          <Button
            variant="ghost"
            className="block w-full"
            disabled={form.formState.isSubmitting}
            onClick={() =>
              navigate(
                paths.companyProductsPath({
                  slug: params.slug ?? "",
                })
              )
            }
          >
            Cancel
          </Button>
        </div>
      </form>
    </Form>
  )
}

const COMPANIES_QUERY_DOCUMENT = gql(/* GraphQL */ `
  query Companies($search: String, $first: Int = 10) {
    companies(search: $search, first: $first) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`)
