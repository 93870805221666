import * as React from "react"
import { FormMessage } from "~/ui/form"

export const StyledFormMessage: React.FC<{ children?: React.ReactNode }> = (
  props
) => {
  return (
    <FormMessage
      {...props}
      className="bg-foreground text-background text-center py-5 px-5 rounded font-normal text-sm text-white"
    />
  )
}
