import type { Editor as TiptapEditor } from "@tiptap/core"
import { getMarkRange } from "@tiptap/core"
import { CharacterCount } from "@tiptap/extension-character-count"
import HardBreak from "@tiptap/extension-hard-break"
import { Link } from "@tiptap/extension-link"
import TextStyle from "@tiptap/extension-text-style"
import { Underline } from "@tiptap/extension-underline"
import { DOMParser } from "@tiptap/pm/model"
import { Plugin, TextSelection } from "@tiptap/pm/state"
import { EditorContent, Extension, JSONContent, useEditor } from "@tiptap/react"
import StarterKit from "@tiptap/starter-kit"
import React, { forwardRef, useEffect, useRef } from "react"
import { FontSize } from "tiptap-extension-font-size"
import { cn } from "~/common/cn"
import { LinkBubbleMenu } from "./bubble-menu/link-bubble-menu"
import SectionTwo, { ToolbarButtonConfig } from "./section-2"

export interface MinimalTiptapProps
  extends React.HTMLAttributes<HTMLDivElement> {
  value: JSONContent | null | undefined
  disabled?: boolean
  contentClass?: string
  onValueChange: (value: JSONContent, html: string) => void
  config?: ToolbarButtonConfig
  size?: "sm" | "default"
  editorRef?: React.MutableRefObject<TiptapEditor | null>
  contentStyle?: React.CSSProperties
  linkStyle?: React.CSSProperties
  showWordCount?: boolean
}

const objectToCSSString = (styles: React.CSSProperties): string => {
  return Object.entries(styles)
    .map(([key, value]) => {
      // Convert camelCase to kebab-case
      const property = key.replace(/([A-Z])/g, "-$1").toLowerCase()
      return `${property}: ${value};`
    })
    .join(" ")
}

const ParagraphFontSize = Extension.create({
  name: "paragraphFontSize",

  addGlobalAttributes() {
    return [
      {
        types: ["paragraph"],
        attributes: {
          fontSize: {
            default: "15px",
            parseHTML: (element) => element.style.fontSize,
            renderHTML: (attributes) => {
              if (!attributes.fontSize) {
                return {}
              }
              return {
                style: `font-size: ${attributes.fontSize}`,
              }
            },
          },
        },
      },
    ]
  },

  addCommands() {
    return {
      setFontSize:
        (fontSize: string) =>
        ({ commands }) => {
          return commands.updateAttributes("paragraph", { fontSize })
        },
    }
  },
})

const MinimalTiptapEditor = forwardRef<HTMLDivElement, MinimalTiptapProps>(
  (
    {
      value,
      disabled,
      contentClass,
      onValueChange,
      className,
      size = "default",
      config = {
        bold: true,
        italic: true,
        underline: true,
        strike: true,
        link: true,
        orderedList: true,
        bulletList: true,
      },
      editorRef,
      contentStyle = null,
      linkStyle = null,
      showWordCount = false,
      ...props
    },
    ref
  ) => {
    const editor = useEditor({
      extensions: [
        StarterKit.configure({
          paragraph: {
            HTMLAttributes: {
              style: contentStyle ? objectToCSSString(contentStyle) : null,
            },
          },
        }),
        HardBreak.extend({
          addKeyboardShortcuts() {
            return {
              Enter: () => this.editor.commands.setHardBreak(),
            }
          },
        }),
        TextStyle,
        FontSize,
        ParagraphFontSize,
        Underline.configure({}),
        Link.configure({
          openOnClick: false,
          HTMLAttributes: {},
        }).extend({
          // https://github.com/ueberdosis/tiptap/issues/2571
          inclusive: false,

          addAttributes() {
            return {
              ...this.parent?.(),
              style: {
                default: linkStyle ? objectToCSSString(linkStyle) : "",
                parseHTML: (element) => {
                  return element.getAttribute("style")
                },
                renderHTML: (attributes) => {
                  if (!attributes.style) {
                    return {}
                  }
                  return { style: attributes.style }
                },
              },
            }
          },

          addProseMirrorPlugins() {
            return [
              new Plugin({
                // mark the link
                props: {
                  handleClick(view, pos) {
                    const { schema, doc, tr } = view.state
                    const range = getMarkRange(
                      doc.resolve(pos),
                      schema.marks.link
                    )

                    if (!range) {
                      return
                    }

                    const { from, to } = range
                    const start = Math.min(from, to)
                    const end = Math.max(from, to)

                    if (pos < start || pos > end) {
                      return
                    }

                    const $start = doc.resolve(start)
                    const $end = doc.resolve(end)
                    const transaction = tr.setSelection(
                      new TextSelection($start, $end)
                    )

                    view.dispatch(transaction)
                  },
                },
              }),
            ]
          },
        }),
        CharacterCount,
      ],
      editorProps: {
        attributes: {
          class:
            "prose mx-auto focus:outline-none max-w-none prose-stone dark:prose-invert prose-p:text-[15px]",
        },
        handlePaste: (view, event) => {
          const clipboardData = event.clipboardData
          if (!clipboardData) return false

          const html = clipboardData.getData("text/html")
          if (!html) return false

          // Create a temporary div to parse the HTML
          const div = document.createElement("div")
          div.innerHTML = html

          // Remove all formatting elements
          const unwantedTags = ["style", "script", "font", "meta", "title"]
          unwantedTags.forEach((tag) => {
            const elements = div.getElementsByTagName(tag)
            while (elements.length > 0) {
              const element = elements[0]
              element.parentNode?.removeChild(element)
            }
          })

          // Remove all style attributes from remaining elements
          const removeStyles = (element: Element) => {
            // Remove all attributes except href for links
            const isLink = element.tagName.toLowerCase() === "a"
            const href = isLink ? element.getAttribute("href") : null

            // Remove all attributes
            while (element.attributes.length > 0) {
              element.removeAttribute(element.attributes[0].name)
            }

            // Restore href for links
            if (isLink && href) {
              element.setAttribute("href", href)
            }

            // Process children
            Array.from(element.children).forEach(removeStyles)
          }
          removeStyles(div)

          // Convert block elements to paragraphs
          const blockElements = div.querySelectorAll(
            "div, h1, h2, h3, h4, h5, h6, pre, blockquote"
          )
          blockElements.forEach((element) => {
            const p = document.createElement("p")
            p.innerHTML = element.innerHTML
            element.parentNode?.replaceChild(p, element)
          })

          // Use the editor's schema to parse the cleaned HTML
          const { state, dispatch } = view
          const parser = DOMParser.fromSchema(state.schema)

          // Create a DocumentFragment from the child nodes
          const fragment = document.createDocumentFragment()
          while (div.firstChild) {
            fragment.appendChild(div.firstChild)
          }

          // Parse the fragment into a Slice
          const slice = parser.parseSlice(fragment)

          // Check if the current selection is inside an empty block
          const { $from } = state.selection
          const node = $from.node($from.depth)
          const isEmptyBlock = node.isBlock && node.content.size === 0

          let tr = state.tr

          if (isEmptyBlock) {
            // Replace the entire empty block with the new content
            const from = $from.start($from.depth)
            const to = $from.end($from.depth)
            tr = tr.replaceRange(from, to, slice)
          } else {
            // Replace the selection with the new content
            tr = tr.replaceSelection(slice)
          }

          dispatch(tr.scrollIntoView())

          return true
        },
      },
      onUpdate: (props) => {
        onValueChange(props.editor.getJSON(), props.editor.getHTML())
      },
      content: value,
      editable: !disabled,
    })

    // Track previous value to only update when coming from null/undefined
    const prevValueRef = useRef(value)
    useEffect(() => {
      if (
        editor &&
        value &&
        (prevValueRef.current === null || prevValueRef.current === undefined)
      ) {
        editor.commands.setContent(value)
      }
      prevValueRef.current = value
    }, [editor, value])

    // This way we can update the editor using setContent from the onChange callbacks in the parent
    // to dynamically update the editor content when we pick something from the dropdown
    if (editor && editorRef) {
      editorRef.current = editor
    }

    return (
      <div
        className={cn(
          "flex h-auto min-h-72 w-full flex-col rounded-md border border-input shadow-sm focus-within:border-primary",
          className
        )}
        {...props}
        ref={ref}
      >
        {editor && (
          <>
            <LinkBubbleMenu editor={editor} />
            <Toolbar editor={editor} config={config} size={size} />
          </>
        )}
        <div className="relative h-full grow">
          <div className="h-full" onClick={() => editor?.chain().focus().run()}>
            <EditorContent
              editor={editor}
              className={cn(
                size === "default" && "p-5 min-h-[300px]",
                size === "sm" && "p-2",
                contentClass,
                {
                  "cursor-not-allowed opacity-50 bg-gray-f9": disabled,
                }
              )}
            />
          </div>
          {showWordCount && editor && (
            <div className="absolute bottom-2 right-3 text-sm text-gray-400">
              {editor.storage.characterCount.words()} words
            </div>
          )}
        </div>
      </div>
    )
  }
)

MinimalTiptapEditor.displayName = "MinimalTiptapEditor"

const Toolbar = ({
  editor,
  config,
  size = "default",
}: {
  editor: TiptapEditor
  config: ToolbarButtonConfig
  size: "sm" | "default"
}) => {
  return (
    <div
      className={cn(
        size === "default" && "p-2",
        size === "sm" && "p-1",
        "border-b border-border bg-gray-f9 rounded-t-md"
      )}
    >
      <div className="flex w-full flex-wrap items-center">
        <SectionTwo editor={editor} config={config} size={size} />
      </div>
    </div>
  )
}

export { MinimalTiptapEditor }
