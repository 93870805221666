import { PropsWithChildren } from "react"
import Text from "~/ui/typography"

export const SubsectionTitle: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Text as="div" variant="subtext-10" className="text-gray-99 flex">
      {children}
    </Text>
  )
}
