import { useQuery } from "@apollo/client"
import { useParams } from "react-router-dom"
import { Params } from "static-path"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import { CompanyRole } from "~/__generated__/graphql"
import { companyUsersPath } from "~/common/paths"
import { ErrorBox, GraphqlError } from "~/ui/errors"
import { LoadingIndicatorCentered } from "~/ui/loading-indicator"
import { UsersScreen } from "~/users/users-screen"
import { useCompany } from "./company-context"

gql(/* GraphQL */ `
  fragment CompanyUsersScreen on Company {
    id
    slug
  }
`)

const query = gql(/* GraphQL */ `
  query CompanyUsersScreenQuery($slug: String!) {
    company(slug: $slug) {
      ...CompanyUsersScreen
    }
  }
`)

export const CompanyUsersScreen: React.FC = () => {
  const { companyRole } = useCompany()
  const params = useParams() as Params<typeof companyUsersPath.pattern>

  const result = useQuery(query, { variables: { slug: params.slug } })

  if (result.loading) return <LoadingIndicatorCentered />
  if (result.error) return <GraphqlError error={result.error} />
  invariant(result.data, "Expected company")
  if (!result.data.company) return <ErrorBox>Company not found</ErrorBox>
  return (
    <UsersScreen
      company={result.data.company}
      viewerIsCompanyAdmin={companyRole === CompanyRole.Admin}
    />
  )
}
