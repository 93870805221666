import { useEffect } from "react"

export const useDocumentTitle = (
  title: string | null,
  suffix = " · Workweek Partner Platform"
) => {
  useEffect(() => {
    if (title != null) {
      document.title = `${title}${suffix}`
    }
  }, [title, suffix])
}
