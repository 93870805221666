import { Role } from "~/__generated__/graphql"

const roleLabels: Record<Role, string> = {
  [Role.Client]: "Client",
  [Role.Creative]: "Creative",
  [Role.WorkweekTeam]: "Workweek Team",
  [Role.WorkweekAdmin]: "Workweek Admin",
  [Role.Unassigned]: "Unassigned",
}

export function userRoleLabel(role: Role): string {
  return roleLabels[role]
}

export const UserRole: React.FC<{ role: Role }> = ({ role }) => {
  return roleLabels[role]
}
