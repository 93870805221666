import { UseFormReturn } from "react-hook-form"
import { z } from "zod"
import { formSchema } from "~/campaigns/campaign-deliverable-edit-metrics-pane"
import { formatPercentage } from "~/common/percentage-formattingt"
import { TextField } from "~/fields/text-field"
import { Input } from "~/ui/input"
import Text from "~/ui/typography"

export const EventsSidebarEditMetrics: React.FC<{
  form: UseFormReturn<z.infer<typeof formSchema>>
}> = ({ form }) => {
  const opensMetric = form.watch("opensMetric") ?? 0
  const totalClicksMetric = form.watch("totalClicksMetric") ?? 0
  const clickThroughRate =
    (Number(totalClicksMetric) / Number(opensMetric)) * 100

  return (
    <>
      <TextField
        type="number"
        control={form.control}
        name="registrantsMetric"
        label="Registrants"
      />
      <TextField
        type="number"
        control={form.control}
        name="liveAttendeesMetric"
        label="Live Attendees"
      />
      <TextField
        type="number"
        control={form.control}
        name="opensMetric"
        label="Opens"
      />
      <TextField
        type="number"
        control={form.control}
        name="totalClicksMetric"
        label="Clicks"
      />
      <div className="space-y-2">
        <Text variant="body-14">
          Click Through Rate <span className="text-gray-400">(calculated)</span>
        </Text>
        <Input disabled value={formatPercentage(clickThroughRate)} />
      </div>
    </>
  )
}
