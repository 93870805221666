import { useQuery } from "@apollo/client"
import { RefObject } from "react"
import { gql } from "~/__generated__"
import MessagesIcon from "~/images/icons/messages.svg?react"
import { PostComposer } from "~/post-composer/PostComposer"
import { MessagesPaneHeader } from "./MessagesPaneHeader"
import { MessagesSectionInner } from "./MessagesSectionInner"
import { useCreateMessage } from "./useCreateMessage"

export const MessagesPane = ({
  roomId,
  dmOtherUserId,
  scrollviewRef,
}: {
  roomId?: string
  dmOtherUserId?: string
  scrollviewRef: RefObject<HTMLDivElement>
}) => {
  const roomResult = useQuery(ROOM_QUERY_DOCUMENT, {
    variables: {
      dmOtherUserId,
      roomId,
    },
  })
  const createMessageBag = useCreateMessage({
    roomId: roomResult.data?.room.id,
    otherUserId: dmOtherUserId,
    onSuccess: () => {},
  })

  if (roomResult.error) {
    return (
      <div className="flex-1-1-auto flex flex-col justify-center items-center">
        <div className="text-gray-500 text-center flex items-center gap-2">
          <MessagesIcon /> This chat doesn't exist
        </div>
      </div>
    )
  }

  if (!roomResult.data) {
    return null
  }

  return (
    <>
      <MessagesPaneHeader roomResult={roomResult} />
      <div
        className="flex-1-1-auto overflow-y-scroll flex flex-col-reverse pb-4 px-4 gap-4"
        ref={scrollviewRef}
      >
        <MessagesSectionInner roomData={roomResult.data} />
      </div>
      <div className="p-4 border-t border-mercury">
        <PostComposer
          onSave={createMessageBag.createReply}
          isSaving={createMessageBag.replayIsSaving}
          buttonCopy="Send"
          mentionsDisabled
          actionsDivider
        />
      </div>
    </>
  )
}

const ROOM_QUERY_DOCUMENT = gql(`
  query RoomQuery($roomId: ID, $dmOtherUserId: ID) {
    room(roomId: $roomId, dmOtherUserId: $dmOtherUserId) {
      id
      hasUnreadPosts
      name
      defaultName
      currentUserCanUpdate {
        value
      }
      users {
        ...User_Avatar
        name
      }
      dmOtherUser {
        ...User_Avatar
        name
        email
      }
    }
  }
`)
