import * as React from "react"
import { RouterProvider } from "react-router-dom"
import { router } from "./router"

import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client"
import { KeyArgsFunction } from "@apollo/client/cache/inmemory/policies"
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev"
import { relayStylePagination } from "@apollo/client/utilities"
import { createConsumer } from "@rails/actioncable"
import ActionCableLink from "graphql-ruby-client/subscriptions/ActionCableLink"
import invariant from "tiny-invariant"
import { ViewerProvider, viewerQuery } from "../auth/viewer-context"
import { getMetaVar } from "../common/get-meta-var"

// @ts-expect-error this is a vite-only feature
if (import.meta.env.DEV) {
  // Adds messages only in a dev environment
  loadDevMessages()
  loadErrorMessages()
}

const cable = createConsumer()

const hasSubscriptionOperation = ({ query: { definitions } }: any) => {
  return definitions.some(
    ({ kind, operation }: any) =>
      kind === "OperationDefinition" && operation === "subscription"
  )
}

const csrfToken = getMetaVar("csrf-token")

if (csrfToken == null) {
  // eslint-disable-next-line no-console
  console.warn("missing csrf token")
}

const httpLink = new HttpLink({
  credentials: "same-origin",
  headers: {
    "X-CSRF-Token": csrfToken!,
  },
})

const link = ApolloLink.split(
  hasSubscriptionOperation,
  new ActionCableLink({ cable }),
  httpLink
)

const excludePaginationArgs: KeyArgsFunction = (args) => {
  if (!args) return false
  return Object.keys(args).filter((k) => !["first", "after"].includes(k))
}

const apolloClient = new ApolloClient({
  uri: "/graphql",
  link,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          users: relayStylePagination(excludePaginationArgs),
          companies: relayStylePagination(excludePaginationArgs),
          campaigns: relayStylePagination(excludePaginationArgs),
          campaignDeliverables: relayStylePagination(excludePaginationArgs),
          notifications: relayStylePagination(excludePaginationArgs),
          posts: relayStylePagination(excludePaginationArgs),
          myRooms: relayStylePagination(excludePaginationArgs),
        },
      },
      Campaign: {
        fields: {
          campaignDeliverables: relayStylePagination(excludePaginationArgs),
        },
      },
    },
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
  },
})

const metaTag = document.querySelector("meta[name=viewer-cache]")
invariant(metaTag)

const viewerDataContent = metaTag.getAttribute("content")
invariant(viewerDataContent)

const userData = JSON.parse(viewerDataContent)

if (!userData.data) {
  // eslint-disable-next-line no-console
  console.warn("Error preloading viewer")
} else {
  apolloClient.writeQuery({
    query: viewerQuery,
    data: userData.data,
    variables: {},
  })
}

export default function App() {
  return (
    <React.StrictMode>
      <ApolloProvider client={apolloClient}>
        <ViewerProvider>
          <RouterProvider router={router} />
        </ViewerProvider>
      </ApolloProvider>
    </React.StrictMode>
  )
}
