import { useQuery } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import { ControllerFieldState, useFieldArray, useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import { Params } from "static-path"
import { z } from "zod"
import { gql } from "~/__generated__"
import { ClientVisibility, CompanyRole, Role } from "~/__generated__/graphql"
import { gqlMatchOptional } from "~/common/gql-match"
import * as paths from "~/common/paths"
import { useGoBack } from "~/common/use-go-back"
import { useSafeMutation } from "~/common/use-safe-mutation"
import { displayErrors } from "~/common/validations"
import { SelectField } from "~/fields/select-field"
import { TextField } from "~/fields/text-field"
import { TextareaField } from "~/fields/textarea-field"
import { StyledFormMessage } from "~/forms/styled-form-message"
import { Button } from "~/ui/button"
import { Checkbox } from "~/ui/checkbox"
import { ErrorBox } from "~/ui/errors"
import { Form, FormControl, FormField, FormItem, FormLabel } from "~/ui/form"
import { ButtonLink } from "~/ui/link"
import { Pane, PaneBody, PaneHeader } from "~/ui/pane"
import Text from "~/ui/typography"
import { useToast } from "~/ui/use-toast"
import { CompanySelector } from "./company-selector"

gql(/* GraphQL */ `
  fragment UserPaneCompany on Company {
    id
    name
  }
`)

const query = gql(`
  query UserPaneQuery($userId: ID!) {
    node(id: $userId) {
      __typename
      ... on User {
        id
        firstName
        lastName
        title
        email
        role
        favoriteAdOfAllTime
        briefSummaryOfExperience
        calendlyUrl
        clientVisibility

        companies {
          edges {
            role
            reportingContact
            creativeContact
            node {
              ...UserPaneCompany
            }
          }
        }

        canUpdate {
          value
        }
      }
    }
  }
`)

const FieldError = ({ fieldState }: { fieldState: ControllerFieldState }) => {
  if (!fieldState.error) return null
  return <div className="text-red-500 text-xs">{fieldState.error.message}</div>
}

const formSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  title: z.string(),
  email: z
    .string()
    .trim()
    .min(1, { message: "Enter an email" })
    .email({ message: "Email is invalid" }),
  role: z.nativeEnum(Role),
  favoriteAdOfAllTime: z.string().optional(),
  briefSummaryOfExperience: z.string().optional(),
  calendlyUrl: z.string(),
  clientVisibility: z.nativeEnum(ClientVisibility),
  companyId: z.string().optional(),
  companyMemberships: z.array(
    z.object({
      companyId: z.string(),
      companyRole: z.nativeEnum(CompanyRole).optional(),
      creativeContact: z.boolean().optional(),
      reportingContact: z.boolean().optional(),
    })
  ),
})

export type UserFormSchema = z.infer<typeof formSchema>

type EditUserParams =
  | Params<typeof paths.companyUsersEditPath.pattern>
  | Params<typeof paths.usersEditPath.pattern>

export const EditUserPane = () => {
  const params = useParams() as EditUserParams
  const { toast } = useToast()

  const {
    data: currentData,
    previousData,
    loading: userLoading,
  } = useQuery(query, {
    variables: {
      userId: params.userId,
    },
  })

  const data = currentData || previousData
  const user = gqlMatchOptional(data?.node, "User")
  const [runMutation, { loading }] = useSafeMutation(USER_UPDATE_MUTATION)

  const navigate = useNavigate()

  const backPath =
    "slug" in params
      ? paths.companyUsersPath({ slug: params.slug })
      : paths.usersPath({})

  const goBack = useGoBack(backPath)

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const isWorkweekMember =
      values.role === Role.WorkweekAdmin || values.role === Role.WorkweekTeam

    const isClient = values.role === Role.Client

    const { errors } = await runMutation({
      variables: {
        input: {
          id: params.userId,
          userInput: {
            firstName: values.firstName,
            lastName: values.lastName,
            title: values.title,
            email: values.email,
            role: values.role,
            ...(isWorkweekMember && {
              favoriteAdOfAllTime: values.favoriteAdOfAllTime,
              briefSummaryOfExperience: values.briefSummaryOfExperience,
              calendlyUrl: values.calendlyUrl,
            }),
            ...(isCreative && {
              clientVisibility: values.clientVisibility,
            }),
            ...(isClient && {
              clientVisibility: values.clientVisibility,
              companyMemberships: values.companyMemberships.map((company) => ({
                companyId: company.companyId,
                companyRole: company.companyRole,
                creativeContact: company.creativeContact,
                reportingContact: company.reportingContact,
              })),
            }),
          },
        },
      },
    })

    const setFieldError = (field: any, message: string | undefined) => {
      form.setError(field, { message })
    }

    const showToast = (title: string, description?: string) => {
      toast({
        title: title,
        description: description,
        variant: "destructive",
      })
    }

    if (errors) {
      displayErrors(errors, setFieldError, showToast)
    } else {
      navigate(backPath)
    }
  }

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    values: {
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      title: user?.title || "",
      email: user?.email || "",
      role: user?.role || Role.Unassigned,
      favoriteAdOfAllTime: user?.favoriteAdOfAllTime || "",
      briefSummaryOfExperience: user?.briefSummaryOfExperience || "",
      calendlyUrl: user?.calendlyUrl || "",
      clientVisibility: user?.clientVisibility || ClientVisibility.Anonymous,
      companyMemberships:
        user?.companies.edges.map((company) => {
          return {
            companyId: company.node.id,
            companyRole: company.role,
            creativeContact: company.creativeContact,
            reportingContact: company.reportingContact,
          }
        }) || [],
    },
  })

  const {
    fields: companyFields,
    remove: removeCompany,
    append: appendCompany,
  } = useFieldArray({
    name: "companyMemberships",
    control: form.control,
  })

  const { watch } = form
  const role = watch("role")

  const isWorkweekAdminOrTeam =
    role === Role.WorkweekAdmin || role === Role.WorkweekTeam

  const isClient = role === Role.Client

  const isCreative = role === Role.Creative

  const roleOptions = [
    { label: "Client", value: Role.Client },
    { label: "Creative", value: Role.Creative },
    { label: "Workweek Team", value: Role.WorkweekTeam },
    { label: "Workweek Admin", value: Role.WorkweekAdmin },
  ]

  if (user && !user.canUpdate.value) {
    return <ErrorBox>Not authorized to edit this user</ErrorBox>
  }

  return (
    <Pane>
      <PaneHeader>Edit user</PaneHeader>
      <PaneBody>
        {!user && !userLoading && (
          <Text variant="body-12">Can't find user</Text>
        )}
        {!userLoading && user && (
          <Form {...form}>
            {Object.keys(form.formState.errors).length > 0 && (
              <div className="my-3">
                <StyledFormMessage>
                  <div>Missing required* fields</div>
                </StyledFormMessage>
              </div>
            )}
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <div className="space-y-4">
                <TextField
                  control={form.control}
                  name="firstName"
                  label="First name*"
                  required
                />
                <TextField
                  control={form.control}
                  name="lastName"
                  label="Last name*"
                  required
                />
                <TextField
                  control={form.control}
                  name="title"
                  label="Title*"
                  required
                />
                <TextField
                  control={form.control}
                  name="email"
                  label="Email Address*"
                  required
                />
                <SelectField
                  control={form.control}
                  name="role"
                  label="Role*"
                  options={roleOptions}
                  text={(field) =>
                    roleOptions.find((option) => option.value === field.value)
                      ?.label || "Select a role"
                  }
                />

                {isWorkweekAdminOrTeam && (
                  <>
                    <TextField
                      control={form.control}
                      name="favoriteAdOfAllTime"
                      label="Favorite Ad of All Time"
                      required
                    />
                    <TextareaField
                      label="Summary of Past Experience"
                      name="briefSummaryOfExperience"
                      control={form.control}
                    />
                    <TextField
                      control={form.control}
                      name="calendlyUrl"
                      label="Calendly URL"
                      required
                    />
                  </>
                )}
                {isClient && (
                  <CompanySelector
                    form={form}
                    onRemove={removeCompany}
                    fields={companyFields}
                    userCompanies={user?.companies.edges.map(
                      (company) => company.node
                    )}
                    onAppend={() =>
                      appendCompany({
                        companyId: "",
                        companyRole: CompanyRole.Client,
                        creativeContact: undefined,
                        reportingContact: undefined,
                      })
                    }
                  />
                )}
                {isCreative && (
                  <FormField
                    control={form.control}
                    name="clientVisibility"
                    render={({ field, fieldState }) => (
                      <FormItem>
                        <FormLabel className="font-normal space-x-2 flex items-center mt-6">
                          <Checkbox
                            {...field}
                            checked={field.value === ClientVisibility.Visible}
                            onCheckedChange={(checked: boolean) =>
                              form.setValue(
                                "clientVisibility",
                                checked
                                  ? ClientVisibility.Visible
                                  : ClientVisibility.Anonymous
                              )
                            }
                          />
                          <span>Profile visible to clients</span>
                        </FormLabel>
                        <FieldError fieldState={fieldState} />
                        <FormControl></FormControl>
                      </FormItem>
                    )}
                  />
                )}
              </div>

              <div className="flex justify-between items-center mt-6">
                <Button type="submit" disabled={loading} className="font-light">
                  Save &amp; Update
                </Button>
                <ButtonLink
                  type="button"
                  onClick={goBack}
                  className="text-xs-plus text-gray-400"
                >
                  Cancel
                </ButtonLink>
              </div>
            </form>
          </Form>
        )}
      </PaneBody>
    </Pane>
  )
}

const USER_UPDATE_MUTATION = gql(`
  mutation editUserUserUpdate($input: UserUpdateInput!) {
    userUpdate(input: $input) {
      user {
        id
        firstName
        lastName
        title
        email
        role
        favoriteAdOfAllTime
        briefSummaryOfExperience
        calendlyUrl

        companies {
          edges {
            role
            reportingContact
            creativeContact
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`)
