import { InView } from "react-intersection-observer"
import { LoadingIndicator } from "./loading-indicator"
import { cn } from "~/common/cn"

export const InfiniteLoadMore = ({
  onEndReached,
  canLoadMore,
  loadingText,
  loading,
  className = "mt-8 border rounded text-sm px-3 py-1 text-gray-500 hover:text-gray-700 gap-2",
}: {
  onEndReached: () => void
  canLoadMore: boolean
  loadingText: string
  loading: boolean
  className?: string
}) => {
  return (
    <div className="flex justify-center">
      {loading && (
        <div className={cn("flex items-center", className)}>
          <LoadingIndicator />
          {loadingText}
        </div>
      )}

      <InView
        onChange={(inView) => {
          if (inView && canLoadMore) {
            onEndReached()
          }
        }}
      />

      {/* Backup button */}
      {canLoadMore && (
        <div className={cn("cursor-pointer", className)} onClick={onEndReached}>
          Load More
        </div>
      )}
    </div>
  )
}
