import clsx from "clsx"
import { X } from "lucide-react"
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react"
import { Mention, MentionsInput } from "react-mentions"
import { useNavigate } from "react-router-dom"
import Toggle from "react-toggle"
import { Post, User_AvatarFragment } from "~/__generated__/graphql"
import { useCurrentUser } from "~/auth/viewer-context"
import { useDirtyTracking } from "~/editor/useDirtyTracking"
import { AvatarWithFallback } from "~/ui/AvatarWithFallback"
import { Button } from "~/ui/button"
import { Card } from "~/ui/card"
import { Input } from "~/ui/input"
import AddPhoto from "../images/icons/add-photo.svg?react"
import AddVideo from "../images/icons/add-video.svg?react"
import Attachment from "../images/icons/attachment.svg?react"
import { AnonymousPostInfoSection } from "./AnonymousPostInfoSection"
import { Attachments } from "./Attachments"
import {
  ServerFile,
  fileIsLocal,
  serverFileToManagerFile,
  useFileManager,
} from "./useFileManager"
import { CustomDataItem, useMentionDataSource } from "./useMentionDataSource"

const VIDEO_REGEX =
  /(https?:\/\/)(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(&\S+)?/i

export type ComposerOnSave = (args: {
  content: string
  attachmentIds: string[]
  videoUrl: string | null
  anonymous?: boolean | null
}) => Promise<boolean>

export type ComposerHandle = {
  prefillContent: (text: string) => void
  focus: () => void
}

type PostComposerFragment = Pick<Post, "content" | "videoUrl"> & {
  id?: string | null
  attachments?: ServerFile[]
}

type Props = {
  isSaving: boolean
  onSave: ComposerOnSave
  post?: PostComposerFragment
  isEditing?: boolean
  title?: string
  buttonCopy?: string
  cancelPath?: string
  isReply?: boolean
  author?: User_AvatarFragment | null
  actionsDivider?: boolean
  mentionsInputPlaceholder?: string
  mentionsDisabled?: boolean
  autoFocus?: boolean
  allowAnonymous?: boolean
}

export const PostComposer = forwardRef<ComposerHandle, Props>(
  (
    {
      isSaving,
      onSave: _onSave,
      isEditing = false,
      title,
      cancelPath,
      isReply = false,
      buttonCopy,
      author,
      actionsDivider,
      mentionsInputPlaceholder = "Say something!",
      mentionsDisabled = false,
      autoFocus = false,
      allowAnonymous = false,
      post,
    },
    ref: React.ForwardedRef<ComposerHandle>
  ) => {
    const navigate = useNavigate()
    const [content, setContent] = useState("")
    const scrollIntoViewCompleteRef = useRef(false)
    const contentInputRef = useRef<HTMLInputElement>(null)
    const containerRef = useRef<HTMLDivElement>(null)
    const { fetchMentionUsers } = useMentionDataSource({ mentionsDisabled })
    const {
      attachedFiles,
      fileManagerDispatch,
      dropzoneBag,
      hasPendingUploads,
    } = useFileManager()
    const [addingVideoUrl, setAddingVideoUrl] = useState(false)
    const [anonymous, setAnonymous] = useState(false)
    const [videoUrl, setVideoUrl] = useState<string | null>(null)
    const videoUrlInputRef = useRef<HTMLInputElement>(null)
    const { currentUser } = useCurrentUser()

    useEffect(() => {
      if (!post) return
      setContent(post.content || "")
      if (post.videoUrl) {
        setAddingVideoUrl(true)
        setVideoUrl(post.videoUrl)
      }
    }, [post])

    useEffect(() => {
      if (!post) return
      if (post.attachments && post.attachments.length > 0) {
        fileManagerDispatch({
          type: "addFiles",
          files: post.attachments,
        })
      }
    }, [post, fileManagerDispatch])

    const savedTrackedObject = useMemo(() => {
      return {
        content: post?.content || "",
        attachments: (post?.attachments || []).map(serverFileToManagerFile),
        videoUrl: post?.videoUrl || null,
      }
    }, [post])
    const currentTrackedObject = useMemo(() => {
      return {
        content,
        attachments: attachedFiles,
        videoUrl,
      }
    }, [content, attachedFiles, videoUrl])
    const dirtyTracking = useDirtyTracking({
      savedTrackedObject,
      currentTrackedObject,
    })

    useEffect(() => {
      if (!autoFocus || scrollIntoViewCompleteRef.current) return

      if (containerRef.current) {
        scrollIntoViewCompleteRef.current = true
        setTimeout(() => {
          containerRef.current?.scrollIntoView({ behavior: "smooth" })
        }, 200)
      }
    }, [autoFocus])

    const attachmentIds = attachedFiles
      .filter((file) => !file.isDeleted && !fileIsLocal(file))
      .map((file) => file.id)

    const resetComposer = () => {
      setContent("")
      setAddingVideoUrl(false)
      setVideoUrl(null)
      fileManagerDispatch({ type: "removeAll" })
    }

    const videoUrlIsValid = useMemo(() => {
      if (!videoUrl || videoUrl.length === 0) return true

      if (VIDEO_REGEX.test(videoUrl)) {
        return true
      } else {
        return false
      }
    }, [videoUrl])

    useImperativeHandle(
      ref,
      () => {
        return {
          prefillContent: (text: string) => {
            contentInputRef.current?.focus()
            setContent(text)
          },
          focus: () => {
            contentInputRef.current?.focus()
          },
        }
      },
      []
    )

    const onSave = async () => {
      const success = await _onSave({
        content,
        attachmentIds,
        videoUrl: videoUrl && videoUrl.length > 0 ? videoUrl : null,
        anonymous: isEditing ? undefined : anonymous,
      })

      if (success) {
        dirtyTracking.onSave(undefined)
        resetComposer()
      }
    }

    const handleKeyPress = (event: React.KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "Enter") {
        event.preventDefault()
        if (postComplete && !isSaving && !hasPendingUploads) {
          onSave()
        }
      }
    }

    const postComplete =
      attachmentIds.length > 0 ||
      content.length > 0 ||
      (videoUrl && videoUrl.length > 0)

    return (
      <Card ref={containerRef}>
        {title && (
          <div className="text-sm font-semibold flex items-center justify-between px-6 py-4 tracking-wide border-b border-mercury">
            {title}
          </div>
        )}
        {anonymous && <AnonymousPostInfoSection />}
        <div
          {...dropzoneBag.getRootProps()}
          className={clsx("px-6 pt-4 pb-2", {
            "ring-2": dropzoneBag.isDragActive,
          })}
        >
          <input {...dropzoneBag.getInputProps()} />
          <div className={"flex gap-2 items-start"}>
            <AvatarWithFallback user={author ?? currentUser} />

            <div className="mt-1 ml-1 flex-1">
              <MentionsInput
                value={content}
                onChange={(_e, value) => setContent(value)}
                className="post-composer-content-area"
                placeholder={mentionsInputPlaceholder}
                allowSpaceInQuery
                style={MENTION_STYLES}
                inputRef={contentInputRef}
                autoFocus={autoFocus}
                onKeyDown={handleKeyPress}
              >
                <Mention
                  trigger="@"
                  data={fetchMentionUsers}
                  appendSpaceOnAdd
                  style={{
                    backgroundColor: "#e5e7eb",
                  }}
                  displayTransform={(_, display) => `@${display}`}
                  // @ts-ignore
                  renderSuggestion={(
                    entry: CustomDataItem,
                    _search,
                    highlightedDisplay
                  ) => (
                    <div className="flex items-center gap-2">
                      <AvatarWithFallback
                        user={entry}
                        size="post-autocomplete"
                      />{" "}
                      {highlightedDisplay}{" "}
                    </div>
                  )}
                />
              </MentionsInput>
            </div>
          </div>

          <Attachments
            attachedFiles={attachedFiles}
            removeFile={(id) => fileManagerDispatch({ type: "removeFile", id })}
          />
        </div>

        {addingVideoUrl && (
          <div className="border-t border-mercury">
            <Input
              ref={videoUrlInputRef}
              placeholder="Add YouTube or Vimeo URL"
              onChange={(e) => setVideoUrl(e.target.value)}
              value={videoUrl || ""}
              postfix={
                <X
                  size={16}
                  onClick={() => {
                    setVideoUrl("")
                    setAddingVideoUrl(false)
                  }}
                />
              }
              className="border-0 h-12 z-10 rounded-none px-6 py-4 font-medium text-xs"
            />
            {!videoUrlIsValid && (
              <div className="px-6 pb-4 pt-0 text-red-600 text-xs">
                Please enter a valid YouTube or Vimeo URL.
              </div>
            )}
          </div>
        )}

        <div
          className={clsx("flex items-center py-2 px-6", {
            "border-t border-mercury": actionsDivider || isReply,
          })}
        >
          <div
            className="cursor-pointer p-2 pl-0 text-primary"
            onClick={() => {
              setAddingVideoUrl(true)
              setTimeout(() => {
                videoUrlInputRef.current?.focus()
              }, 150)
            }}
          >
            <AddVideo />
          </div>
          <div
            className="cursor-pointer p-2 text-primary"
            onClick={dropzoneBag.open}
          >
            <AddPhoto />
          </div>
          <div
            className="cursor-pointer p-2 mr-auto text-primary"
            onClick={dropzoneBag.open}
          >
            <Attachment />
          </div>
          {allowAnonymous && !isEditing && (
            <label className="flex items-center mr-3">
              <span className="text-2xs mr-2">Post Anonymously</span>
              <Toggle
                defaultChecked={anonymous}
                icons={false}
                onChange={(e) => setAnonymous(e.target.checked)}
              />
            </label>
          )}
          {cancelPath && (
            <Button
              size="sm"
              className="px-4"
              variant="outline"
              onClick={() => navigate(cancelPath)}
            >
              Cancel
            </Button>
          )}
          <Button
            size="sm"
            className="ml-2 px-4"
            disabled={!postComplete || isSaving || hasPendingUploads}
            onClick={onSave}
          >
            {!!buttonCopy
              ? buttonCopy
              : isEditing
              ? "Update"
              : isReply
              ? "Reply"
              : "Post"}
          </Button>
        </div>
      </Card>
    )
  }
)

const MENTION_STYLES = {
  suggestions: {
    zIndex: 30,
    list: {
      backgroundColor: "white",
      border: "1px solid #E5E5E5",
      borderBottom: 0,
      fontSize: 14,
    },
    item: {
      padding: "5px 10px",
      borderBottom: "1px solid #E5E5E5",
      "&focused": {
        backgroundColor: "#f1f5f9",
      },
    },
  },
}
