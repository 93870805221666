import { UseFormReturn } from "react-hook-form"
import { z } from "zod"
import { metricsFormSchema } from "~/campaigns/campaign-metrics-screen"
import { formatPercentage } from "~/common/percentage-formattingt"
import { calculatePercent } from "~/ui/percent"
import { CampaignTotals } from "../campaign-totals"

export const CampaignNewsletterTotals: React.FC<{
  form: UseFormReturn<z.infer<typeof metricsFormSchema>>
}> = ({ form }) => {
  const { watch } = form
  const campaignDeliverables = watch("deliverables")
  const nonPendingDeliverables = campaignDeliverables.filter(
    (deliverable) => !deliverable.metricsPending
  )
  const totalEmailRecipients = nonPendingDeliverables.reduce(
    (sum, deliverable) => sum + parseInt(deliverable.recipientsMetric || "0"),
    0
  )
  const totalOpens = nonPendingDeliverables.reduce(
    (sum, deliverable) => sum + parseInt(deliverable.opensMetric || "0"),
    0
  )
  const totalClicks = nonPendingDeliverables.reduce(
    (sum, deliverable) => sum + parseInt(deliverable.totalClicksMetric || "0"),
    0
  )
  const averageOpenRate = calculatePercent(totalOpens, totalEmailRecipients)
  const averageClickThroughRate = calculatePercent(totalClicks, totalOpens)

  return (
    <CampaignTotals
      totals={[
        { value: totalEmailRecipients, label: "Total Email Recipients" },
        { value: totalOpens, label: "Total Opens" },
        {
          value: formatPercentage(averageOpenRate),
          label: "Average Open Rate",
        },
        { value: totalClicks, label: "Total Clicks" },
        {
          value: formatPercentage(averageClickThroughRate),
          label: "Average Click Through Rate",
        },
      ]}
    />
  )
}
