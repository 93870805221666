import { useQuery } from "@apollo/client"
import { Link } from "react-router-dom"
import { gql } from "~/__generated__/gql"
import {
  ActionItemAction,
  ActionItemFragmentFragment,
} from "~/__generated__/graphql"
import {
  campaignDeliverablePath,
  campaignDetailPath,
  companyProfileEditPath,
} from "~/common/paths"
import Text from "~/ui/typography"

gql(/* GraphQL */ `
  fragment ActionItemFragment on ActionItem {
    id
    action
    completedAt
    source {
      ... on CampaignDeliverable {
        id
        deliverableName

        campaign {
          id
        }
      }
      ... on Campaign {
        id
        campaignName
      }
      ... on Company {
        id
        slug
      }
    }
  }
`)

const actionItemsQuery = gql(/* GraphQL */ `
  query ActionItems {
    actionItems {
      edges {
        node {
          ...ActionItemFragment
        }
      }
    }
  }
`)

export const ActionItems = () => {
  const actionItemResults = useQuery(actionItemsQuery)

  const actionItems =
    actionItemResults.data?.actionItems.edges.map((e) => e.node) ?? []

  const renderActionItem = (actionItem: ActionItemFragmentFragment) => {
    if (actionItem.source?.__typename === "CampaignDeliverable") {
      const to = campaignDeliverablePath({
        campaignId: actionItem.source.campaign.id,
        deliverableId: actionItem.source.id,
      })

      switch (actionItem.action) {
        case ActionItemAction.SubmitDeliverableBrief:
          return (
            <ActionItemWrapper to={to}>
              Submit deliverable brief for {actionItem.source.deliverableName}
            </ActionItemWrapper>
          )
        case ActionItemAction.ReadyToBeScaffolded:
          return (
            <ActionItemWrapper to={to}>
              {actionItem.source.deliverableName} is ready to be scaffolded
            </ActionItemWrapper>
          )
        case ActionItemAction.SubmitDeliverables:
          return (
            <ActionItemWrapper to={to}>
              Submit deliverables for {actionItem.source.deliverableName}
            </ActionItemWrapper>
          )
        case ActionItemAction.ReviewDeliverables:
          return (
            <ActionItemWrapper to={to}>
              {actionItem.source.deliverableName} is ready for review
            </ActionItemWrapper>
          )
        case ActionItemAction.ApproveDeliverables:
          return (
            <ActionItemWrapper to={to}>
              {actionItem.source.deliverableName} is ready for review
            </ActionItemWrapper>
          )
        default:
          return <div>Unknown action: {actionItem.action}</div>
      }
    }

    if (actionItem.source?.__typename === "Campaign") {
      const to = campaignDetailPath({
        campaignId: actionItem.source.id,
      })

      switch (actionItem.action) {
        case ActionItemAction.AddCampaignGoals:
          return (
            <ActionItemWrapper to={to}>
              Add campaign goals for {actionItem.source.campaignName}
            </ActionItemWrapper>
          )
        default:
          return <div>Unknown action: {actionItem.action}</div>
      }
    }

    if (actionItem.source?.__typename === "Company") {
      const to = companyProfileEditPath({
        slug: actionItem.source.slug,
      })
      switch (actionItem.action) {
        case ActionItemAction.CompleteCompanyProfile:
          return (
            <ActionItemWrapper to={to}>
              Complete your company profile
            </ActionItemWrapper>
          )
        default:
          return <div>Unknown action: {actionItem.action}</div>
      }
    }
  }

  return (
    <div>
      <div className="flex h-[60px] p-6">
        <Text variant="mini-caps">Action Items</Text>
      </div>
      <div className="border-t divide-y">
        {actionItems.map((actionItem) => (
          <div key={actionItem.id}>
            <div>{renderActionItem(actionItem)}</div>
          </div>
        ))}

        <div className="only:block hidden text-muted-foreground py-4 px-6">
          <Text variant="body-12">No action items</Text>
        </div>
      </div>
    </div>
  )
}

const ActionItemWrapper = ({
  to,
  children,
}: {
  to: string
  children: React.ReactNode
}) => {
  return (
    <div className="flex flex-col">
      <Link to={to} className="p-4 hover:bg-gray-f9 group">
        <div className="text-xs-plus font-medium leading-4">{children}</div>
        <Text
          variant="body-12"
          className="text-gray-400 group-hover:text-gray-500"
        >
          View details
        </Text>
      </Link>
    </div>
  )
}
