import { TableHead } from "~/ui/table"

export const BrandedArticlesColumns = () => (
  <>
    <TableHead className="w-[150px]">Time on Site</TableHead>
    <TableHead className="w-[150px]">Pageviews</TableHead>
    <TableHead className="w-24">Clicks</TableHead>
    <TableHead className="w-[150px]" colSpan={2}>
      CTR
    </TableHead>
  </>
)
