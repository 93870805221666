import { RouteObject, createBrowserRouter } from "react-router-dom"
import { NoCompaniesScreen } from "~/access/no-companies-screen"
import { CampaignDeliverableDetailScreen } from "~/campaigns/campaign-deliverable-detail-screen"
import { CampaignDeliverableEditMetricsPane } from "~/campaigns/campaign-deliverable-edit-metrics-pane"
import { CampaignDeliverableFeedbackPane } from "~/campaigns/campaign-deliverable-feedback-pane"
import { CampaignDeliverableHistoryPane } from "~/campaigns/campaign-deliverable-history-pane"
import { CampaignDetailScreen } from "~/campaigns/campaign-detail-screen"
import { CampaignGoalsPane } from "~/campaigns/campaign-goals-pane"
import { CampaignMetricsScreen } from "~/campaigns/campaign-metrics-screen"
import {
  CampaignsScreen,
  CompanyCampaignsScreen,
} from "~/campaigns/campaigns-screen"
import { ProductsScreen } from "~/campaigns/products-screen"
import { ReportsScreen } from "~/campaigns/reports-screen"
import { NotFoundScreen } from "~/common/not-found-screen"
import * as paths from "~/common/paths"
import { CompaniesScreen } from "~/companies/companies-screen"
import { CompanyDetailScreen } from "~/companies/company-detail-screen"
import { CompanyEditScreen } from "~/companies/company-edit-screen"
import { CompanyUsersScreen } from "~/companies/company-users-screen"
import { ContentLibraryDetailScreen } from "~/content-library/content-library-detail-screen"
import { ContentLibraryScreen } from "~/content-library/content-library-screen"
import { ContentLibraryUploadPane } from "~/content-library/content-library-upload-pane"
import { ContentLibraryWorkweekUploadPane } from "~/content-library/content-library-workweek-upload-pane"
import { CreatorBrandEditPane } from "~/creator-brands/creator-brand-edit-pane"
import { CreatorBrandsScreen } from "~/creator-brands/creator-brands-screen"
import { DeliverablesDashboardScreen } from "~/deliverables/deliverables-dashboard-screen"
import AccountProfileIcon from "~/images/icons/account-profile.svg?react"
import CampaignsIcon from "~/images/icons/campaigns.svg?react"
import ClientsIcon from "~/images/icons/clients.svg?react"
import CompaniesIcon from "~/images/icons/companies.svg?react"
import CompanyUsersIcon from "~/images/icons/company-users.svg?react"
import ContentLibraryIcon from "~/images/icons/content-library.svg?react"
import CreativesIcon from "~/images/icons/creatives.svg?react"
import CreatorBrandsIcon from "~/images/icons/creator-brands.svg?react"
import DashboardIcon from "~/images/icons/dashboard.svg?react"
import MessagesIcon from "~/images/icons/messages.svg?react"
import ProductsIcon from "~/images/icons/products.svg?react"
import ReportsIcon from "~/images/icons/reports.svg?react"
import UsersIcon from "~/images/icons/users.svg?react"
import { MessagesScreen } from "~/messages/messages-screen"
import { ContentReferencesPane } from "~/products/content-references-pane"
import { ProductDetailScreen } from "~/products/product-detail-screen"
import { ProductEditScreen } from "~/products/product-edit-screen"
import { ProductNewScreen } from "~/products/product-new-screen"
import { ProductSnapshotDetailScreen } from "~/products/product-snapshot-screen"
import { ProfileScreen } from "~/profile/profile-screen"
import { ScaffoldAdScreen } from "~/scaffold/scaffold-ad-screen"
import { TiptapScreen } from "~/tiptap/components/tiptap-screen"
import { EditCompanyUserPane } from "~/users/edit-company-user-pane"
import { EditUserPane } from "~/users/edit-user-pane"
import { InviteCompanyUserPane } from "~/users/invite-company-user-pane"
import { InviteUserPane } from "~/users/invite-user-pane"
import { UserDetailPane } from "~/users/user-detail-pane"
import { UsersScreen } from "~/users/users-screen"
import { LoginLayout } from "../access/access-layout"
import { SignInScreen } from "../access/sign-in-screen"
import { SignInTokenScreen } from "../access/sign-in-token-screen"
import { RequireUserSignedIn, RequireUserSignedOut } from "../auth/auth-layouts"
import { RequireCompany } from "../companies/require-company"
import { SidebarLayout } from "../layouts/sidebar-layout"
import { TopBarLayout } from "../layouts/topbar-layout"
import { ErrorBoundary } from "../ui/error-boundary"
import { RootLayout } from "./root-layout"
import { RootScreen } from "./root-screen"

const usersHandle = {
  title: "Users",
  icon: UsersIcon,
}

const campaignsHandle = {
  title: "Campaigns",
  icon: CampaignsIcon,
}

const companyUsersHandle = {
  title: "Company Users",
  icon: CompanyUsersIcon,
}

const contentLibraryHandle = {
  title: "Content Library",
  icon: ContentLibraryIcon,
}

const messagesHandle = {
  title: "Messages",
  icon: MessagesIcon,
}

const companyRoutes: Array<RouteObject> = [
  {
    path: paths.companyDashboardPath.pattern,
    element: <DeliverablesDashboardScreen />,
    handle: {
      title: "Dashboard",
      icon: DashboardIcon,
    },
  },
  {
    path: paths.companyProfilePath.pattern,
    element: <CompanyDetailScreen />,
    handle: {
      title: "Companies",
      icon: CompaniesIcon,
    },
    children: [
      {
        path: paths.companyProfileUserPath.pattern,
        element: <UserDetailPane />,
        handle: {
          title: "Companies",
          icon: CompaniesIcon,
        },
      },
    ],
  },
  {
    path: paths.companyProfileEditPath.pattern,
    element: <CompanyEditScreen />,
    handle: {
      title: "Companies",
      icon: CompaniesIcon,
    },
  },
  {
    path: paths.companyCampaignsPath.pattern,
    element: <CompanyCampaignsScreen />,
    handle: campaignsHandle,
  },
  {
    path: paths.companyProductsPath.pattern,
    element: <ProductsScreen />,
    handle: {
      title: "Products",
      icon: ProductsIcon,
    },
  },
  {
    path: paths.companyProductSnapshotPath.pattern,
    element: <ProductSnapshotDetailScreen />,
    handle: {
      title: "Products",
      icon: ProductsIcon,
    },
  },
  {
    path: paths.companyProductsDetailPath.pattern,
    element: <ProductDetailScreen />,
    handle: {
      title: "Products",
      icon: ProductsIcon,
    },
    children: [
      {
        path: paths.companyProductsDetailUploadPath.pattern,
        element: <ContentReferencesPane />,
        handle: campaignsHandle,
      },
    ],
  },
  {
    path: paths.companyProductsEditPath.pattern,
    element: <ProductEditScreen />,
    handle: {
      title: "Products",
      icon: ProductsIcon,
    },
    children: [
      {
        path: paths.companyProductsEditUploadPath.pattern,
        element: <ContentReferencesPane />,
        handle: campaignsHandle,
      },
    ],
  },
  {
    path: paths.newCompanyProductsPath.pattern,
    element: <ProductNewScreen />,
    handle: {
      title: "Products",
      icon: ProductsIcon,
    },
  },
  {
    path: paths.companyReportsPath.pattern,
    element: <ReportsScreen />,
    handle: {
      title: "Reports",
      icon: ReportsIcon,
    },
  },
  {
    path: paths.companyContentLibraryPath.pattern,
    element: <ContentLibraryScreen />,
    handle: contentLibraryHandle,
    children: [
      {
        path: paths.companyContentLibraryUploadPath.pattern,
        element: <ContentLibraryUploadPane />,
        handle: contentLibraryHandle,
      },
      {
        path: paths.companyContentLibraryWorkweekUploadPath.pattern,
        element: <ContentLibraryWorkweekUploadPane />,
        handle: contentLibraryHandle,
      },
    ],
  },
  {
    path: paths.companyContentReferenceDetailPath.pattern,
    element: <ContentLibraryDetailScreen />,
    handle: contentLibraryHandle,
    children: [
      {
        path: paths.companyContentReferenceEditMetricsPath.pattern,
        element: <CampaignDeliverableEditMetricsPane />,
        handle: contentLibraryHandle,
      },
    ],
  },
  {
    path: paths.companyClientsPath.pattern,
    element: <div>Clients</div>,
    handle: {
      title: "Clients",
      icon: ClientsIcon,
    },
  },
  {
    path: paths.companyCreativesPath.pattern,
    element: <div>Creatives</div>,
    handle: {
      title: "Creatives",
      icon: CreativesIcon,
    },
  },
  {
    path: paths.companyUsersPath.pattern,
    element: <CompanyUsersScreen />,
    handle: companyUsersHandle,
    children: [
      {
        path: paths.companyUsersDetailPath.pattern,
        element: <UserDetailPane />,
        handle: companyUsersHandle,
      },
      {
        path: paths.companyUsersEditPath.pattern,
        element: <EditCompanyUserPane />,
        handle: companyUsersHandle,
      },
      {
        path: paths.companyUsersInvitePath.pattern,
        element: <InviteCompanyUserPane />,
        handle: companyUsersHandle,
      },
    ],
  },
  {
    path: paths.companyMessagesPath.pattern,
    element: <MessagesScreen />,
    handle: messagesHandle,
    children: [
      {
        path: paths.companyDmPath.pattern,
        element: null,
        handle: messagesHandle,
      },
      {
        path: paths.companyRoomPath.pattern,
        element: null,
        handle: messagesHandle,
      },
    ],
  },
]

const authenticatedRoutes: Array<RouteObject> = [
  {
    path: paths.noCompaniesPath.pattern,
    element: <NoCompaniesScreen />,
  },
  {
    path: paths.profilePath.pattern,
    element: <ProfileScreen />,
    handle: {
      title: "Account Profile",
      icon: AccountProfileIcon,
    },
  },
  {
    path: paths.campaignsPath.pattern,
    element: <CampaignsScreen />,
    handle: campaignsHandle,
  },
  {
    path: paths.campaignDetailPath.pattern,
    element: <CampaignDetailScreen />,
    handle: campaignsHandle,
    children: [
      {
        path: paths.campaignDetailGoalsPath.pattern,
        element: <CampaignGoalsPane />,
        handle: campaignsHandle,
      },
      {
        path: paths.campaignDetailUserPath.pattern,
        element: <UserDetailPane />,
        handle: campaignsHandle,
      },
    ],
  },
  {
    path: paths.campaignDetailMetricsPath.pattern,
    element: <CampaignMetricsScreen />,
    handle: campaignsHandle,
  },
  {
    path: paths.campaignDeliverablePath.pattern,
    element: <CampaignDeliverableDetailScreen />,
    handle: campaignsHandle,
    children: [
      {
        path: paths.campaignDeliverableUserPath.pattern,
        element: <UserDetailPane />,
        handle: campaignsHandle,
      },
      {
        path: paths.campaignDeliverableEditMetricsPath.pattern,
        element: <CampaignDeliverableEditMetricsPane />,
        handle: campaignsHandle,
      },
      {
        path: paths.campaignDeliverableFeedbackPath.pattern,
        element: <CampaignDeliverableFeedbackPane />,
        handle: campaignsHandle,
      },
      {
        path: paths.campaignDeliverableHistoryPath.pattern,
        element: <CampaignDeliverableHistoryPane />,
        handle: campaignsHandle,
      },
    ],
  },
  {
    path: paths.campaignDeliverableScaffoldAdPath.pattern,
    element: <ScaffoldAdScreen />,
    handle: campaignsHandle,
  },
  {
    path: paths.usersPath.pattern,
    element: <UsersScreen />,
    handle: usersHandle,
    children: [
      {
        path: paths.usersDetailPath.pattern,
        element: <UserDetailPane />,
        handle: usersHandle,
      },
      {
        path: paths.usersEditPath.pattern,
        element: <EditUserPane />,
        handle: usersHandle,
      },
      {
        path: paths.usersInvitePath.pattern,
        element: <InviteUserPane />,
        handle: usersHandle,
      },
    ],
  },
  {
    path: paths.creatorBrandsPath.pattern,
    element: <CreatorBrandsScreen />,
    handle: {
      title: "Creator Brands",
      icon: CreatorBrandsIcon,
    },
    children: [
      {
        path: paths.creatorBrandsEditPath.pattern,
        element: <CreatorBrandEditPane />,
        handle: {
          title: "Creator Brands",
          icon: CreatorBrandsIcon,
        },
      },
    ],
  },
  {
    path: paths.companiesPath.pattern,
    element: <CompaniesScreen />,
    handle: {
      title: "Companies",
      icon: CompaniesIcon,
    },
  },

  {
    path: paths.dashboardPath.pattern,
    element: <DeliverablesDashboardScreen />,
    handle: {
      title: "Dashboard",
      icon: DashboardIcon,
    },
  },
  {
    path: paths.messagesPath.pattern,
    element: <MessagesScreen />,
    handle: messagesHandle,
    children: [
      {
        path: paths.dmPath.pattern,
        element: null,
        handle: messagesHandle,
      },
      {
        path: paths.roomPath.pattern,
        element: null,
        handle: messagesHandle,
      },
    ],
  },
  {
    path: "/tiptap",
    element: <TiptapScreen />,
  },
]

export const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        element: <RequireUserSignedIn />,
        children: [
          {
            element: <SidebarLayout />,
            children: [
              {
                element: <TopBarLayout />,
                children: [
                  ...authenticatedRoutes,
                  {
                    path: "/:slug",
                    element: <NotFoundScreen />,
                  },
                  {
                    path: paths.companyPath.pattern,
                    element: <RequireCompany />,
                    children: companyRoutes,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        element: <RequireUserSignedOut />,
        children: [
          {
            element: <LoginLayout />,
            children: [
              { path: paths.signInPath.pattern, element: <SignInScreen /> },
              {
                path: paths.signInTokenPath.pattern,
                element: <SignInTokenScreen />,
              },
            ],
          },
        ],
      },
      {
        path: paths.rootPath.pattern,
        element: <RootScreen />,
      },
    ],
  },
])
