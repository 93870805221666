import { Button } from "~/ui/button"
import crossCircleIcon from "~/images/icons/cross-circle"

export const ActiveFilters = ({
  label,
  values,
  onClear,
}: {
  label: string
  values: string
  onClear: () => void
}) => {
  return (
    <div className="text-sm flex items-center gap-1">
      <span className="text-xs-plus text-gray-99">{label}:</span>
      <span>{values}</span>
      <Button variant="ghost" className="p-1 h-6" onClick={onClear}>
        <img {...crossCircleIcon} alt="Clear filter icon" className="w-3 h-3" />
      </Button>
    </div>
  )
}
