import { useQuery } from "@apollo/client"
import { parseISO } from "date-fns"
import { orderBy, uniqBy } from "lodash"
import { gql } from "~/__generated__"
import { ChatMessagesQuery, RoomQueryQuery } from "~/__generated__/graphql"
import { PostCard } from "~/feed/PostCard"
import { Error } from "~/ui/Error"
import { GraphqlError } from "~/ui/errors"
import { InfiniteLoadMore } from "~/ui/InfiniteLoadMore"
import { LoadingIndicator } from "~/ui/LoadingIndicator"
import { useMarkMessagesRead } from "./useMarkMessagesRead"

const PER_PAGE = 10

export const MessagesSectionInner = ({
  roomData,
}: {
  roomData: RoomQueryQuery
}) => {
  const { data, loading, error, fetchMore } = useQuery(
    MESSAGES_QUERY_DOCUMENT,
    {
      variables: {
        roomId: roomData.room.id,
        first: PER_PAGE,
      },
      notifyOnNetworkStatusChange: true,
    }
  )
  useMarkMessagesRead({
    roomId: roomData.room.id,
    hasUnreads: roomData.room.hasUnreadPosts,
  })

  if (!data && loading) {
    return (
      <div className="my-8 flex justify-center">
        <LoadingIndicator />
      </div>
    )
  }

  if (error) return <GraphqlError error={error} />
  if (!data) return <Error message="Error loading messages." />

  const orderedPosts = uniqBy(
    orderBy(
      data.posts.edges.map((e) => e.node),
      [(p) => parseISO(p.createdAt)],
      ["desc"]
    ),
    (n) => n.id
  )

  const mapPostToCard = (
    post: ChatMessagesQuery["posts"]["edges"][0]["node"]
  ) => (
    <PostCard
      key={post.id}
      post={post}
      hidePostActions
      hidePostMenu
      variant="ghost"
    />
  )

  return (
    <>
      {orderedPosts.map(mapPostToCard)}
      <div className="px-4 py-10">
        <InfiniteLoadMore
          onEndReached={() =>
            fetchMore({
              variables: { postsCursor: data.posts.pageInfo.endCursor },
            })
          }
          canLoadMore={!loading && data.posts.pageInfo.hasNextPage}
          loadingText="Loading posts..."
          loading={loading && data.posts.edges.length > 0}
        />
      </div>
    </>
  )
}

export const MESSAGES_QUERY_DOCUMENT = gql(`
  query ChatMessages($roomId: ID, $first: Int, $postsCursor: String) {
    posts(first: $first, after: $postsCursor, roomId: $roomId) {
      edges {
        node {
          ...Post_Card
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`)
