import { CompanyRole } from "~/__generated__/graphql"

const roleLabels: Record<CompanyRole, string> = {
  [CompanyRole.Client]: "Client",
  [CompanyRole.Admin]: "Admin",
}

export function companyRoleLabel(role: CompanyRole): string {
  return roleLabels[role]
}

export const CompanyUserRole: React.FC<{ role: CompanyRole }> = ({ role }) => {
  return roleLabels[role]
}
