import { ApolloError } from "@apollo/client"
import { UnauthorizedScreen } from "~/common/unauthorized-screen"

export const GraphqlError = ({ error }: { error: ApolloError }) => (
  <>
    {error["graphQLErrors"][0]["extensions"]?.code === "unauthorized" ? (
      <div className="mt-16">
        <UnauthorizedScreen />
      </div>
    ) : (
      <ErrorBox>
        <div>GraphQL Error</div>
        {error.graphQLErrors.map((e, i) => (
          <pre key={i}>{JSON.stringify(e, null, 2)}</pre>
        ))}
        {error.graphQLErrors.length === 0 ? (
          <pre>{JSON.stringify(error, null, 2)}</pre>
        ) : null}
      </ErrorBox>
    )}
  </>
)

export const ErrorBox = ({ children }: { children: React.ReactNode }) => (
  <div className="overflow-auto flex-shrink-0">
    <div className="border-red-500 border rounded p-3 text-red-600 overflow-auto flex-shrink-0">
      {children}
    </div>
  </div>
)
