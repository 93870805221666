import { useQuery } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import { arrayMoveImmutable } from "array-move"
import SortableList, { SortableItem } from "react-easy-sort"
import { useForm } from "react-hook-form"
import { useParams } from "react-router-dom"
import { Params } from "static-path"
import { z } from "zod"
import { gql } from "~/__generated__"
import { CampaignGoalsFragment } from "~/__generated__/graphql"
import { gqlMatchOptional } from "~/common/gql-match"
import { campaignDetailGoalsPath, campaignDetailPath } from "~/common/paths"
import { useSafeMutation } from "~/common/use-safe-mutation"
import closeXSmall from "~/images/icons/close-x-small"
import sortHandles from "~/images/icons/sort-handles"
import { Button } from "~/ui/button"
import { GraphqlError } from "~/ui/errors"
import { Form } from "~/ui/form"
import { ButtonLink, Link } from "~/ui/link"
import { LoadingIndicatorCentered } from "~/ui/loading-indicator"
import { Pane, PaneBody } from "~/ui/pane"
import { Textarea } from "~/ui/textarea"
import { useToast } from "~/ui/use-toast"

const query = gql(/* GraphQL */ `
  query CampaignGoalsPaneQuery($id: ID!) {
    node(id: $id) {
      __typename
      ... on Campaign {
        ...CampaignGoals
      }
    }
  }
`)

export const CampaignGoalsPane: React.FC = () => {
  const params = useParams() as Params<typeof campaignDetailGoalsPath.pattern>

  const result = useQuery(query, {
    variables: {
      id: params.campaignId,
    },
  })

  const campaign = gqlMatchOptional(result.data?.node, "Campaign")

  return (
    <Pane>
      <PaneBody className="pt-4 flex flex-col border-b">
        <Link
          to={campaignDetailPath({ campaignId: params.campaignId })}
          variant="close-pane"
          className="mb-2"
        >
          Close
        </Link>

        <h2 className="font-medium text-lg">Campaign Goals</h2>
      </PaneBody>
      <PaneBody className="pt-4 flex flex-col">
        {result.error ? (
          <GraphqlError error={result.error} />
        ) : result.loading ? (
          <LoadingIndicatorCentered />
        ) : campaign ? (
          <CampaignGoals campaign={campaign} />
        ) : null}
      </PaneBody>
    </Pane>
  )
}

gql(/* GraphQL */ `
  fragment CampaignGoals on Campaign {
    id
    adsGoals
    canUpdate {
      value
    }
  }
`)

const formSchema = z.object({
  goals: z.array(z.string()),
})

const mutation = gql(/* GraphQL */ `
  mutation UpdateCampaignGoals($input: CampaignUpdateInput!) {
    campaignUpdate(input: $input) {
      campaign {
        ...CampaignGoals
      }
    }
  }
`)

const MAX_GOALS = 5

const CampaignGoals: React.FC<{ campaign: CampaignGoalsFragment }> = ({
  campaign,
}) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    values: {
      goals: campaign.adsGoals.length === 0 ? [""] : campaign.adsGoals,
    },
  })

  const [exec, result] = useSafeMutation(mutation)
  const { toast } = useToast()

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    let adsGoals = values.goals.filter((goal) => goal.trim() !== "")

    const result = await exec({
      variables: {
        input: {
          id: campaign.id,
          campaignInput: {
            adsGoals,
          },
        },
      },
    })

    if (result.errors) {
      toast({
        title: "Error",
        description: "There was an error saving the goals",
      })
    } else {
      toast({ title: "Goals saved", description: "The goals have been saved" })
    }
  }
  const goals = form.watch().goals

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    form.setValue("goals", arrayMoveImmutable(goals, oldIndex, newIndex))
  }

  if (!campaign.canUpdate.value) {
    return (
      <div className="text-center text-sm text-danger">
        You do not have permission to update this campaign's goals.
      </div>
    )
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <SortableList onSortEnd={onSortEnd} lockAxis="y">
          {goals.map((goal, index) => (
            <SortableItem key={index}>
              <div className="grid border rounded mb-2">
                <div className="flex border-b ps-3">
                  <img
                    {...sortHandles}
                    alt=""
                    className="mr-2 pointer-events-none"
                  />
                  <Button
                    type="button"
                    onClick={() =>
                      form.setValue(
                        "goals",
                        goals.filter((_, i) => i !== index)
                      )
                    }
                    variant="ghost"
                    size="icon"
                    className="ms-auto"
                  >
                    <span className="sr-only">Remove</span>
                    <img {...closeXSmall} alt="" />
                  </Button>
                </div>
                <Textarea
                  {...form.register(`goals.${index}` as const)}
                  defaultValue={goal}
                  className="border-0"
                  placeholder="Type your goal here…"
                />
              </div>
            </SortableItem>
          ))}
        </SortableList>

        {goals.length < MAX_GOALS && (
          <ButtonLink
            type="button"
            onClick={() => form.setValue("goals", [...goals, ""])}
            variant="add"
          >
            Add Another
          </ButtonLink>
        )}
        <div className="flex justify-between items-center mt-6">
          <Button
            type="submit"
            disabled={result.loading}
            className="font-light"
          >
            Save Goals
          </Button>
          <Link
            to={campaignDetailPath({ campaignId: campaign.id })}
            className="text-xs-plus text-gray-99"
            variant="unstyled"
          >
            Cancel
          </Link>
        </div>
      </form>
    </Form>
  )
}
