import {
  FontBoldIcon,
  FontItalicIcon,
  ListBulletIcon,
  StrikethroughIcon,
  UnderlineIcon,
} from "@radix-ui/react-icons"
import type { Editor } from "@tiptap/core"
import { useEffect, useState } from "react"
import { cn } from "~/common/cn"
import { Select } from "~/ui/select"
import { activeItemClass, DropdownMenuItemClass } from "../utils"
import { LinkEditPopover } from "./link/link-edit-popover"
import { ToolbarButton } from "./toolbar-button"
import { Node as ProsemirrorNode } from "prosemirror-model"

export type ToolbarButtonConfig = {
  bold?: boolean
  italic?: boolean
  underline?: boolean
  strike?: boolean
  link?: boolean
  orderedList?: boolean
  bulletList?: boolean
  textSize?: boolean
}

export default function SectionTwo({
  editor,
  config = {
    bold: true,
    italic: true,
    underline: true,
    strike: true,
    link: true,
    orderedList: true,
    bulletList: true,
    textSize: true,
  },
  size = "default",
}: {
  editor: Editor
  config: ToolbarButtonConfig
  size: "sm" | "default"
}) {
  const fontSizes = [
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
  ]

  const [currentFontSize, setCurrentFontSize] = useState<string>("15")

  useEffect(() => {
    if (!editor) return

    const updateFontSize = () => {
      const selection = editor.state.selection
      // Find the closest parent paragraph node
      let paragraph: ProsemirrorNode | null = null
      editor.state.doc.nodesBetween(
        selection.from,
        selection.to,
        (node: ProsemirrorNode, pos) => {
          if (node.type.name === "paragraph" && !paragraph) {
            paragraph = node
            return false // Stop traversing once we find a paragraph
          }
        }
      )

      if (paragraph) {
        const fontSize = (paragraph && paragraph["attrs"]["fontSize"]) || "15"
        setCurrentFontSize(fontSize)
      }
    }

    editor.on("selectionUpdate", updateFontSize)
    editor.on("update", updateFontSize)

    return () => {
      editor.off("selectionUpdate", updateFontSize)
      editor.off("update", updateFontSize)
    }
  }, [editor])

  return (
    <div className="flex items-center gap-2">
      {/* BOLD */}
      {config.bold && (
        <ToolbarButton
          buttonSize={size}
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={
            !editor.can().chain().focus().toggleBold().run() ||
            editor.isActive("codeBlock")
          }
          isActive={editor.isActive("bold")}
          tooltip="Bold"
          aria-label="Bold"
        >
          <FontBoldIcon className="size-5" />
        </ToolbarButton>
      )}

      {/* ITALIC */}
      {config.italic && (
        <ToolbarButton
          buttonSize={size}
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={
            !editor.can().chain().focus().toggleItalic().run() ||
            editor.isActive("codeBlock")
          }
          isActive={editor.isActive("italic")}
          tooltip="Italic"
          aria-label="Italic"
        >
          <FontItalicIcon className="size-5" />
        </ToolbarButton>
      )}

      {/* UNDERLINE */}
      {config.underline && (
        <ToolbarButton
          buttonSize={size}
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          disabled={
            !editor.can().chain().focus().toggleUnderline().run() ||
            editor.isActive("codeBlock")
          }
          isActive={editor.isActive("underline")}
          tooltip="Underline"
          aria-label="Underline"
        >
          <UnderlineIcon className="size-5" />
        </ToolbarButton>
      )}

      {/* STRIKE */}
      {config.strike && (
        <ToolbarButton
          buttonSize={size}
          onClick={() => editor.chain().focus().toggleStrike().run()}
          disabled={
            !editor.can().chain().focus().toggleStrike().run() ||
            editor.isActive("codeBlock")
          }
          isActive={editor.isActive("strike")}
          aria-label="Strikethrough"
          tooltip="Strikethrough"
        >
          <StrikethroughIcon className="size-5" />
        </ToolbarButton>
      )}

      {/* LINK */}
      {config.link && <LinkEditPopover editor={editor} />}

      {config.orderedList && (
        <ToolbarButton
          buttonSize={size}
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={cn(DropdownMenuItemClass, {
            [activeItemClass]: editor.isActive("orderedList"),
          })}
          aria-label="Numbered list"
          tooltip="Numbered list"
        >
          <ListBulletIcon className="size-5" />
        </ToolbarButton>
      )}

      {config.bulletList && (
        <ToolbarButton
          buttonSize={size}
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={cn(DropdownMenuItemClass, {
            [activeItemClass]: editor.isActive("bulletList"),
          })}
          aria-label="Bullet list"
          tooltip="Bullet list"
        >
          <ListBulletIcon className="size-5" />
        </ToolbarButton>
      )}

      {config.textSize && (
        <Select
          typeAhead
          text={
            <div className="px-1">
              {currentFontSize ? currentFontSize?.replace("px", "") : "15"}
            </div>
          }
          options={fontSizes.map((fontSize) => ({
            label: fontSize,
            value: `${fontSize}px`,
          }))}
          value={editor.getAttributes("fontSize").fontSize}
          onChange={(value) => {
            editor.chain().focus().setFontSize(value).run()
          }}
        />
      )}
    </div>
  )
}
