import { useQuery } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import { ControllerFieldState, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { z } from "zod"
import { gql } from "~/__generated__"
import { ClientVisibility, Role } from "~/__generated__/graphql"
import { Section } from "~/campaigns/section"
import { useSafeMutation } from "~/common/use-safe-mutation"
import { useValidationErrors } from "~/common/use-validation-errors"
import { ImageField } from "~/fields/image-field"
import { TextField } from "~/fields/text-field"
import { TextareaField } from "~/fields/textarea-field"
import { TablePageLayout } from "~/layouts/table-page-layout"
import { Button } from "~/ui/button"
import { Checkbox } from "~/ui/checkbox"
import { Form, FormControl, FormField, FormItem, FormLabel } from "~/ui/form"
import Text from "~/ui/typography"
import { useToast } from "~/ui/use-toast"

const query = gql(/* GraphQL */ `
  query GetViewer {
    viewer {
      id
      firstName
      lastName
      title
      favoriteAdOfAllTime
      briefSummaryOfExperience
      calendlyUrl
      role
      clientVisibility
      image {
        id
      }
      companies {
        edges {
          role
        }
      }
    }
  }
`)

const mutation = gql(/* GraphQL */ `
  mutation UpdateProfile($input: ProfileUpdateInput!) {
    profileUpdate(input: $input) {
      user {
        id
        firstName
        lastName
        title
        email
        role
        favoriteAdOfAllTime
        briefSummaryOfExperience
        calendlyUrl
        clientVisibility
        image {
          id
        }
      }
    }
  }
`)

export const ProfileScreen = () => {
  const { toast } = useToast()
  const navigate = useNavigate()

  const result = useQuery(query)
  const viewerData = result.data?.viewer

  const isWorkweekUser =
    viewerData?.role === Role.WorkweekTeam ||
    viewerData?.role === Role.WorkweekAdmin

  const isCreative = viewerData?.role === "CREATIVE"

  const formSchema = z.object({
    firstName: z.string(),
    lastName: z.string(),
    title: z.string(),
    favoriteAdOfAllTime: z.string(),
    briefSummaryOfExperience: z.string(),
    calendlyUrl: z.string().url("Invalid URL").optional().or(z.literal("")),
    imageId: z.string().nullable(),
    clientVisibility: z.nativeEnum(ClientVisibility),
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    values: {
      firstName: viewerData?.firstName ?? "",
      lastName: viewerData?.lastName ?? "",
      title: viewerData?.title ?? "",
      favoriteAdOfAllTime: viewerData?.favoriteAdOfAllTime ?? "",
      briefSummaryOfExperience: viewerData?.briefSummaryOfExperience ?? "",
      calendlyUrl: viewerData?.calendlyUrl ?? "",
      imageId: viewerData?.image?.id ?? null,
      clientVisibility:
        viewerData?.clientVisibility ?? ClientVisibility.Visible,
    },
  })

  const [exec, mutationResult] = useSafeMutation(mutation)
  useValidationErrors(form.setError, mutationResult)

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const result = await exec({
      variables: {
        input: {
          profileInput: {
            ...values,
          },
        },
      },
    })

    if (!result.errors) {
      toast({
        title: "Profile Updated",
        description: "Your profile has been successfully updated.",
      })
      navigate(-1)
    }
  }

  if (!viewerData) return null

  return (
    <TablePageLayout>
      <div className="flex flex-1 flex-col">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="grid grid-cols-[330px,650px] gap-8">
              <div>
                <Text variant="mini-caps" className="mb-4">
                  Profile picture
                </Text>
                <ImageField
                  label=""
                  control={form.control}
                  name="imageId"
                  isAvatar
                  chooseFileButtonText="Upload avatar"
                />
              </div>
              <div className="space-y-8">
                <Section variant="grayBackground" className="space-y-4">
                  <div>
                    <Text variant="body-12" className="text-foreground">
                      *
                    </Text>{" "}
                    <Text variant="body-12">Required Fields</Text>
                  </div>

                  <TextField
                    control={form.control}
                    name="firstName"
                    label="First Name*"
                    required
                  />
                  <TextField
                    control={form.control}
                    name="lastName"
                    label="Last Name*"
                    required
                  />
                  <TextField
                    control={form.control}
                    name="title"
                    label="Title*"
                    required
                  />

                  {isWorkweekUser && (
                    <>
                      <TextField
                        control={form.control}
                        name="favoriteAdOfAllTime"
                        label="Favorite Ad of All Time"
                      />
                      <TextareaField
                        label="Summary of Past Experience"
                        control={form.control}
                        name="briefSummaryOfExperience"
                      />
                      <TextField
                        control={form.control}
                        name="calendlyUrl"
                        label="Calendly URL"
                      />
                    </>
                  )}
                  {isCreative && (
                    <FormField
                      control={form.control}
                      name="clientVisibility"
                      render={({ field, fieldState }) => (
                        <FormItem>
                          <FormLabel className="font-normal space-x-2 flex items-center mt-6">
                            <Checkbox
                              {...field}
                              checked={field.value === ClientVisibility.Visible}
                              onCheckedChange={(checked: boolean) =>
                                form.setValue(
                                  "clientVisibility",
                                  checked
                                    ? ClientVisibility.Visible
                                    : ClientVisibility.Anonymous
                                )
                              }
                            />
                            <span>Profile visible to clients</span>
                          </FormLabel>
                          <FieldError fieldState={fieldState} />
                          <FormControl></FormControl>
                        </FormItem>
                      )}
                    />
                  )}
                </Section>

                <div className="border-t border-gray-d0 pt-10 space-y-4 mt-4">
                  <Button
                    type="submit"
                    disabled={form.formState.isSubmitting}
                    className="block w-full"
                  >
                    Save Profile
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </Form>
      </div>
    </TablePageLayout>
  )
}

const FieldError = ({ fieldState }: { fieldState: ControllerFieldState }) => {
  if (!fieldState.error) return null
  return <div className="text-red-500 text-xs">{fieldState.error.message}</div>
}
