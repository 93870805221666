import Imgix from "react-imgix"
import { CreatorBrandsTableRowFragment } from "~/__generated__/graphql"

export const CreatorBrandImage: React.FC<
  Pick<CreatorBrandsTableRowFragment, "image" | "ctaColor">
> = ({ ctaColor, image }) => {
  return (
    <div
      className="border rounded-full p-[2px]"
      style={{
        borderColor: ctaColor ?? undefined,
      }}
    >
      {image != null ? (
        <Imgix
          src={image.imgixUrl}
          width={30}
          height={30}
          imgixParams={{
            fit: "crop",
            bg: ctaColor ?? undefined,
          }}
          className="rounded-full"
        />
      ) : (
        <div className="w-[30px] h-[30px] bg-gray-200 rounded-full" />
      )}
    </div>
  )
}
