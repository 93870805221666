import { useEffect, useState } from "react"

interface ExtendedDocument extends Document {
  msHidden?: boolean
  webkitHidden?: boolean
}

const browserVisibilityAttribute = () => {
  const extendedDocument = document as ExtendedDocument

  if (typeof extendedDocument.hidden !== "undefined") {
    // Opera 12.10 and Firefox 18 and later support
    return "visibilitychange"
  } else if (typeof extendedDocument.msHidden !== "undefined") {
    return "msvisibilitychange"
  } else if (typeof extendedDocument.webkitHidden !== "undefined") {
    return "webkitvisibilitychange"
  } else {
    return null
  }
}

const browserDocumentHiddenAttribute = () => {
  const extendedDocument = document as ExtendedDocument

  if (typeof extendedDocument.hidden !== "undefined") {
    return "hidden"
  } else if (typeof extendedDocument.msHidden !== "undefined") {
    return "msHidden"
  } else if (typeof extendedDocument.webkitHidden !== "undefined") {
    return "webkitHidden"
  } else {
    return null
  }
}

const isDocumentHidden = () => {
  const extendedDocument = document as ExtendedDocument

  const hiddenAttribute = browserDocumentHiddenAttribute()

  if (!hiddenAttribute) {
    return null
  } else {
    return !extendedDocument[hiddenAttribute]
  }
}

export const usePageVisibility = () => {
  const [isVisible, setIsVisible] = useState(isDocumentHidden())
  const onVisibilityChange = () => setIsVisible(isDocumentHidden())

  useEffect(() => {
    const visibilityChange = browserVisibilityAttribute()

    if (visibilityChange) {
      document.addEventListener(visibilityChange, onVisibilityChange, false)

      return () => {
        document.removeEventListener(visibilityChange, onVisibilityChange)
      }
    }
  }, [])

  return isVisible
}
