import { useQuery } from "@apollo/client"
import { parseISO } from "date-fns"
import { orderBy } from "lodash"
import { useEffect, useMemo, useRef, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { gql } from "~/__generated__"
import { CompanyRole, Role } from "~/__generated__/graphql"
import { useCurrentUser, useViewer } from "~/auth/viewer-context"
import { MessagesPane } from "~/chat/MessagesPane"
import { RoomsPane } from "~/chat/RoomsPane"
import { StartDmModal } from "~/chat/StartDmModal"
import { StartRoomModal } from "~/chat/StartRoomModal"
import { useMessagesSubscription } from "~/chat/useMessagesSubscription"
import { cn } from "~/common/cn"
import {
  companyDmPath,
  companyRoomPath,
  dmPath,
  messagesPath,
  roomPath,
} from "~/common/paths"
import { UnauthorizedScreen } from "~/common/unauthorized-screen"
import { useAuthorizedAccess } from "~/hooks/use-authorized-access"
import { Button } from "~/ui/button"
import SmsNotification from "../images/icons/sms-notification.svg?react"

const ROOMS_PER_PAGE = 20

export const MessagesScreen = () => {
  const { viewer } = useViewer()
  const params = useParams()
  const isClient = viewer?.role === Role.Client
  const [startDmModalOpen, setStartDmModalOpen] = useState(false)
  const [startRoomModalOpen, setStartRoomModalOpen] = useState(false)
  const { otherUserId, roomId } = useParams()
  const { currentUser } = useCurrentUser()
  const messagesScrollViewRef = useRef<HTMLDivElement>(null)
  const location = useLocation()
  const roomsResult = useQuery(MY_ROOMS_QUERY_DOCUMENT, {
    variables: {
      first: ROOMS_PER_PAGE,
    },
  })
  const rooms = useMemo(
    () => roomsResult.data?.myRooms.edges.map((e) => e.node) || [],
    [roomsResult.data]
  )
  const orderedRooms = useMemo(
    () =>
      orderBy(
        rooms,
        (room) => parseISO(room.latestPost?.createdAt || room.createdAt),
        ["desc"]
      ),
    [rooms]
  )

  const navigate = useNavigate()

  useMessagesSubscription({
    scrollviewRef: messagesScrollViewRef,
    visibleRoomIds: roomsResult.data?.myRooms.edges.map((e) => e.node.id) || [],
    refetchRooms: roomsResult.refetch,
  })

  useEffect(() => {
    if (otherUserId && otherUserId === currentUser.id) {
      navigate(messagesPath.pattern)
    }
  }, [otherUserId, currentUser.id, navigate])

  useEffect(() => {
    if (location.pathname !== messagesPath.pattern) return

    const firstRoom = orderedRooms[0]

    if (firstRoom?.dmOtherUser?.id) {
      if (isClient && params.slug) {
        navigate(
          companyDmPath({
            slug: params.slug,
            otherUserId: firstRoom.dmOtherUser.id,
          })
        )
      } else {
        navigate(dmPath({ otherUserId: firstRoom.dmOtherUser.id }))
      }
    } else if (firstRoom?.id) {
      if (isClient && params.slug) {
        navigate(companyRoomPath({ slug: params.slug, roomId: firstRoom.id }))
      } else {
        navigate(roomPath({ roomId: firstRoom.id }))
      }
    }
  }, [orderedRooms, navigate, location, isClient, params.slug])

  const { hasAccess } = useAuthorizedAccess([
    Role.WorkweekTeam,
    Role.WorkweekAdmin,
    Role.Client,
    CompanyRole.Admin,
  ])

  if (!hasAccess) {
    return <UnauthorizedScreen />
  }

  return (
    <>
      <div className="grid overflow-hidden grid-cols-[300px,1fr] h-[calc(100vh-77px)]">
        <div className={cn("border-r border-mercury flex flex-col bg-white")}>
          <RoomsPane
            openComposeModal={() => setStartDmModalOpen(true)}
            openNewRoomModal={() => setStartRoomModalOpen(true)}
            roomsResult={roomsResult}
            orderedRooms={orderedRooms}
          />
        </div>
        <div className="flex flex-col flex-1 overflow-y-auto">
          {roomId || otherUserId ? (
            <MessagesPane
              dmOtherUserId={otherUserId}
              roomId={roomId}
              scrollviewRef={messagesScrollViewRef}
            />
          ) : (
            <div className="flex flex-col items-center pt-12 relative">
              <div className="text-primary rounded-full border-2 border-primary flex items-center justify-center p-4 mb-4">
                <SmsNotification />
              </div>
              <div className="text-lg font-semibold mb-2">Your Messages</div>
              <div className="text-sm mb-6 tracking-wide">
                Start a private conversation with a Workweek Partner Platform
                member!
              </div>
              <Button onClick={() => setStartDmModalOpen(true)}>
                Compose Message
              </Button>
            </div>
          )}
        </div>
      </div>
      <StartDmModal isOpen={startDmModalOpen} setIsOpen={setStartDmModalOpen} />
      <StartRoomModal
        isOpen={startRoomModalOpen}
        setIsOpen={setStartRoomModalOpen}
        onSuccess={() => roomsResult.refetch()}
      />
    </>
  )
}

export const MY_ROOMS_QUERY_DOCUMENT = gql(`
  query MyRooms($first: Int, $roomsCursor: String) {
    myRooms(first: $first, after: $roomsCursor) {
      edges {
        node {
          ...Room_MessageList
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`)

gql(`
  fragment Room_MessageList on Room {
    id
    createdAt
    name
    defaultName
    memberCount
    latestPost {
      id
      createdAt
      user {
        id
      }
    }
    hasUnreadPosts
    dmOtherUser {
      id
      name
      ...User_Avatar
    }
  }
`)
