import { TableHead } from "~/ui/table"

export const EventColumns = () => (
  <>
    <TableHead className="w-[150px]">Registrants</TableHead>
    <TableHead className="w-[150px]">Live attendees</TableHead>
    <TableHead className="w-24">Opens</TableHead>
    <TableHead className="w-[150px]">Clicks</TableHead>
    <TableHead className="w-24">CTR</TableHead>
  </>
)
