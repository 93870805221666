import { useQuery } from "@apollo/client"
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useLocation } from "react-router-dom"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import { gqlMatchOptional } from "~/common/gql-match"
import { UserDialog } from "./UserDialog"

interface UserDialogContextType {
  openUserDialog: (userId: string) => void
  userDialogOpen: boolean
}

const UserDialogContext = createContext<UserDialogContextType | null>(null)

export const UserDialogContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [userId, setUserId] = useState<string | null>(null)
  const [userDialogOpen, setUserDialogOpen] = useState<boolean>(false)
  const location = useLocation()

  const { data, error, loading } = useQuery(USER_DIALOG_QUERY_DOCUMENT, {
    variables: { userId: userId || "" },
    skip: !userId,
    notifyOnNetworkStatusChange: true,
  })

  const openUserDialog = useCallback(
    (id: string) => {
      setUserId(id)
      setUserDialogOpen(true)
    },
    [setUserId, setUserDialogOpen]
  )

  // close dialog when route changes, for example when you click on a pinned post.
  useEffect(() => {
    setUserDialogOpen(false)
  }, [location.pathname])

  const value = useMemo(
    () => ({
      openUserDialog,
      userDialogOpen,
    }),
    [openUserDialog, userDialogOpen]
  )

  const user = gqlMatchOptional(data?.user, "User")

  return (
    <UserDialogContext.Provider value={value}>
      <UserDialog
        isOpen={userDialogOpen}
        onClose={() => {
          setUserDialogOpen(false)
        }}
        user={user}
        isLoading={loading}
        isError={!!error}
      />
      {children}
    </UserDialogContext.Provider>
  )
}

export const useUserDialogContext = () => {
  const contextValue = useContext(UserDialogContext)
  invariant(contextValue)
  return contextValue
}

gql(`
  fragment User_Card on User {
    id
    name
    companyName
    jobTitle
    admin
    createdAt
    ...User_Avatar
  }
`)

export const USER_DIALOG_QUERY_DOCUMENT = gql(`
  query UserDialog($userId: ID!) {
    user: node(id: $userId) {
      __typename
      ... on User {
        ...User_Card
      }
    }
  }
`)
