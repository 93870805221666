import { campaignDeliverableScaffoldAdPath } from "~/common/paths"
import { LinkButton } from "~/ui/link-button"

export const ScaffoldAdButton = ({
  campaignDeliverable,
}: {
  campaignDeliverable: any
}) => {
  return (
    <LinkButton
      to={campaignDeliverableScaffoldAdPath({
        campaignId: campaignDeliverable.campaign.id,
        deliverableId: campaignDeliverable.id,
      })}
    >
      Scaffold Ad
    </LinkButton>
  )
}
